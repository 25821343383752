import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

// import HomeSmile from 'components/assets/icons/HomeSmile'
import User from 'components/assets/icons/User'
import Notes from 'components/assets/icons/Notes'
import Thundercloud from 'components/assets/icons/Thundercloud'
import { getNavMenuIconColor } from 'helpers/styles'
import Link from 'components/common/Link/Link'
import style from './NavMenuFooter.module.scss'
import ThreeDots from 'components/assets/icons/ThreeDots'

const NavMenuFooter = ({ onNavItemClick, onToggleMenu }) => {
  const { pathname } = useLocation()

  const navItems = useMemo(
    () => [
      // not for MVP1
      // {
      //   icon: <HomeSmile styles={{ fill: getNavMenuIconColor(pathname, '/', true) }} />,
      //   title: 'Accueil',
      //   path: '/',
      // },
      {
        icon: <User styles={{ fill: getNavMenuIconColor(pathname, '/contacts', true) }} />,
        title: 'Coordonnées',
        path: '/contacts',
      },
      {
        icon: <Notes styles={{ fill: getNavMenuIconColor(pathname, '/contracts', true) }} />,
        title: 'Contrats',
        path: '/contracts',
      },
      {
        icon: <Thundercloud styles={{ fill: getNavMenuIconColor(pathname, '/claims', true) }} />,
        title: 'Sinistres',
        path: '/claims',
      },
    ],
    [pathname],
  )

  return (
    <div className={style.footer}>
      {navItems.map((item, index) => {
        return (
          <div key={`footer-nav-item-${index}`} className={style['footer-item']}>
            {item.icon}
            <Link className={style['footer-item-title']} label={item.title} onClick={() => onNavItemClick(item.path)} />
          </div>
        )
      })}
      <div className={style['footer-item']} onClick={onToggleMenu}>
        <ThreeDots className={style['footer-dots']} />
        <span className={style['footer-item-title']}>Menu</span>
      </div>
    </div>
  )
}

export default NavMenuFooter
