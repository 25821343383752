import dayjs from 'dayjs'

import { getFileUrl } from 'helpers/files'

export const getContractDocuments = async contract => {
  // Extraire les documents des deux types de contrats
  const rateTypeDocuments = contract.rateType?.legalDocuments || {};
  const contractTypeDocuments = contract.contractType?.legalDocuments || {};

  const documents = [];

  // Essayer d'ajouter le document 'ba' uniquement si le fichier existe
  try {
    const baUrl = await getFileUrl({ contractId: contract._id, type: 'ba' });
    if (baUrl) {
      documents.push({
        type: 'ba',
        url: baUrl,
      });
    } else {
      console.log("BA document not found, checking for other documents (IPID, NI, FIP).");
    }
  } catch (error) {
    console.error("Error fetching BA document:", error.message);
    console.log("Continuing to check for IPID, NI, FIP.");
  }

  // Traiter chaque document séparément en respectant la règle de priorité

  // IPID : Priorité à rateType, sinon contractType, sinon rien
  console.log("IPID RT : ", rateTypeDocuments.ipid);
  if (rateTypeDocuments.ipid && rateTypeDocuments.ipid !== '-') {
    documents.push({
      type: 'ipid',
      url: rateTypeDocuments.ipid,
    });
  } else if (contractTypeDocuments.ipid && contractTypeDocuments.ipid !== '-') {
    documents.push({
      type: 'ipid',
      url: contractTypeDocuments.ipid,
    });
  }

  // NI : Priorité à rateType, sinon contractType, sinon rien
  console.log("NI RT : ", rateTypeDocuments.ni);
  if (rateTypeDocuments.ni && rateTypeDocuments.ni !== '-') {
    documents.push({
      type: 'ni',
      url: rateTypeDocuments.ni,
    });
  } else if (contractTypeDocuments.ni && contractTypeDocuments.ni !== '-') {
    documents.push({
      type: 'ni',
      url: contractTypeDocuments.ni,
    });
  }

  // FIP : Priorité à rateType, sinon contractType, sinon rien
  if (rateTypeDocuments.fip && rateTypeDocuments.fip !== '-') {
    documents.push({
      type: 'fip',
      url: rateTypeDocuments.fip,
    });
  } else if (contractTypeDocuments.fip && contractTypeDocuments.fip !== '-') {
    documents.push({
      type: 'fip',
      url: contractTypeDocuments.fip,
    });
  }

  return documents;
};



export const getCancelMessage = transactionHorodate => {
  const contractStartDate = dayjs(transactionHorodate, 'DD MM YYYY hh:mm:ss')
  const cancelMessageStartDate = contractStartDate.add(11, 'M')
  const cancelMessageFinishDate = contractStartDate.add(12, 'M')
  const daysToCancel = contractStartDate.add(1, 'years').diff(dayjs(), 'days')
  const isCancelMessageShown = dayjs() > cancelMessageStartDate && dayjs() < cancelMessageFinishDate && daysToCancel > 0

  return isCancelMessageShown && daysToCancel
}

export const sortContractsByStatus = contracts => {
  return [...contracts].sort(
    (a, b) =>
      a.cancelation - b.cancelation ||
      dayjs(b.transactionHorodate, 'DD MM YYYY hh:mm:ss') - dayjs(a.transactionHorodate, 'DD MM YYYY hh:mm:ss'),
  )
}

export const getContractTypeName = ({ productNameTranslation, name }) => {
  const nameFr = productNameTranslation?.length && productNameTranslation.find(tr => tr.name === 'fr')
  return nameFr?.value || name
}

export const isCancelationEnabled = contract => {
  const {
    contractType: { noticePeriodDuringFirstYear, noticePeriodAfterFirstYear },
    createdAt,
    insuranceExpiryDate: insuranceExpiryDateRaw,
    transactionHorodate,
  } = contract

  const currentDate = new Date()
  const isFirstYear = dayjs(createdAt).diff(dayjs(transactionHorodate), 'year') < 1
  const insuranceExpiryDate = dayjs(insuranceExpiryDateRaw)

  return (
    (isFirstYear &&
      noticePeriodDuringFirstYear &&
      insuranceExpiryDate.diff(currentDate, noticePeriodDuringFirstYear.unit) > noticePeriodDuringFirstYear.value) ||
    (!isFirstYear &&
      noticePeriodAfterFirstYear &&
      insuranceExpiryDate.diff(currentDate, noticePeriodAfterFirstYear.unit) > noticePeriodAfterFirstYear.value)
  )
}

export const getYearPrice = rate => (rate * 12).toFixed(2)
