import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useOutletContext } from 'react-router'

import Button from 'components/common/Button/Button'
import Input from 'components/common/Input/Input'
import Eye from 'components/assets/icons/Eye'
import { passwordValidation } from 'constants/inputValidation'
import PrefixedText from 'components/common/PrefixedText/PrefixedText'
import Valid from 'components/assets/icons/Valid'
import ExclamationTriangle from 'components/assets/icons/ExclamationTriangle'
import ValidationIcon from 'components/common/ValidationIcon/ValidationIcon'
import api from 'helpers/api'
import style from './PasswordForm.module.scss'
import PageTitle from 'components/common/PageTitle/PageTitle'
import InfoMessage from 'components/common/InfoMessage/InfoMessage'
import ShieldLogo from 'components/assets/icons/ShieldLogo'
import PageSubtitle from 'components/common/PageSubtitle/PageSubtitle'
import ResponseMessage from 'components/common/ResponseMessage/ResponseMessage'

const PasswordForm = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const client = useOutletContext()

  const [isPasswordShown, setIsPasswordShown] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields, touchedFields, isValid },
    getValues,
  } = useForm({
    criteriaMode: 'all',
    delayError: 500,
    defaultValues: {
      password: '',
      newPassword: '',
      passwordConfirmation: '',
    },
    mode: 'onTouched',
  })

  const togglePasswordVisibility = () => {
    setIsPasswordShown(!isPasswordShown)
  }

  const resetPassword = async () => {
    const lang = (navigator.language || navigator.userLanguage).split('-').shift();
    const res = await api.post({
      url: '/auth/clients/forgot_password',
      data: { email: client.email, lang },
    })

    if (res.success) {
      navigate('/contacts')
    }
  }

  const onSubmit = handleSubmit(async data => {
    try {
      const res = await api.put({
        url: '/clients/password',
        data,
      })

      if (res.success) {
        navigate('/contacts')
      } else {
        setErrorMessage(t('main:errorMessages.general'))
      }
    } catch (error) {
      if (error.response?.data?.status === 401) {
        setErrorMessage(t('main:auth.password.wrongPassword'))
      } else {
        setErrorMessage(t('main:errorMessages.general'))
      }
    }
  })

  const [newPassword, passwordConfirmation] = getValues(['newPassword', 'passwordConfirmation'])

  return (
    <>
      <PageTitle text={t('main:clientContacts.password.form.title')} />
      <PageSubtitle text={t('main:clientContacts.password.form.subtitle')} />
      <InfoMessage
        className={style['info-message']}
        label={t('main:clientContacts.password.infoMessage.text')}
        prefix={<ShieldLogo />}
        suffix={
          <Button
            className={style['reset-button']}
            label={t('main:clientContacts.password.infoMessage.button')}
            onClick={resetPassword}
            type="primary"
          />
        }
      />

      <form className={style.form} onSubmit={onSubmit}>
        {errorMessage && <ResponseMessage className={style['response-message']} label={errorMessage} />}
        <div className={style.inputs}>
          <div className={style.input}>
            <Input
              register={register('password', passwordValidation)}
              label={t('main:clientContacts.password.form.currentPassword.label')}
              placeholder={t('main:clientContacts.password.form.currentPassword.placeholder')}
              type={isPasswordShown ? 'text' : 'password'}
              suffix={<Eye className="clickable" />}
              onSuffixClick={togglePasswordVisibility}
            />
          </div>

          <div className={style.input}>
            <Input
              register={register('newPassword', passwordValidation)}
              label={t('main:clientContacts.password.form.newPassword.label')}
              placeholder={t('main:clientContacts.password.form.newPassword.placeholder')}
              type={isPasswordShown ? 'text' : 'password'}
            />
            {touchedFields.newPassword && (
              <div className={style['input-error']}>
                <p>{t('main:auth.password.required')}</p>
                <PrefixedText
                  className={style['input-error-prefix']}
                  prefix={
                    <ValidationIcon
                      errors={errors}
                      dirtyFields={dirtyFields}
                      field="newPassword"
                      validationType="minLength"
                    />
                  }
                  text={t('main:auth.password.length')}
                />
                <PrefixedText
                  className={style['input-error-prefix']}
                  prefix={
                    <ValidationIcon
                      errors={errors}
                      dirtyFields={dirtyFields}
                      field="newPassword"
                      validationType="pattern"
                    />
                  }
                  text={t('main:auth.password.pattern')}
                />
              </div>
            )}
          </div>

          <div className={style.input}>
            <Input
              register={register('passwordConfirmation', passwordValidation)}
              label={t('main:auth.resetPassword.confirm')}
              placeholder={t('main:auth.resetPassword.confirmPlaceholder')}
              type={isPasswordShown ? 'text' : 'password'}
            />
            {touchedFields.passwordConfirmation && (
              <div className={style['input-error']}>
                <PrefixedText
                  className={style['input-error-prefix']}
                  prefix={newPassword === passwordConfirmation ? <Valid /> : <ExclamationTriangle />}
                  text={t('main:auth.password.notMatching')}
                />
              </div>
            )}
          </div>
        </div>

        <Button
          className={style.button}
          label={t('common:save')}
          onClick={handleSubmit}
          type="primary"
          disabled={!isValid}
        />
      </form>
    </>
  )
}

export default PasswordForm
