const Bill = ({ styles }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5625 17.1597C16.0278 16.7061 15.2222 16.7061 14.6875 17.1597C14.1528 17.6134 13.3472 17.6134 12.8125 17.1597C12.2778 16.7061 11.4722 16.7061 10.9375 17.1597C10.4028 17.6134 9.59716 17.6134 9.0625 17.1597C8.52784 16.7061 7.72216 16.7061 7.1875 17.1597C6.65284 17.6134 5.84716 17.6134 5.3125 17.1597C4.77784 16.7061 3.97216 16.7061 3.4375 17.1597C3.07424 17.468 2.5 17.2218 2.5 16.7578V3.24216C2.5 2.77819 3.07424 2.53202 3.4375 2.84026C3.97216 3.29395 4.77784 3.29395 5.3125 2.84026C5.84716 2.38658 6.65284 2.38658 7.1875 2.84026C7.72216 3.29395 8.52784 3.29395 9.0625 2.84026C9.59716 2.38658 10.4028 2.38658 10.9375 2.84026C11.4722 3.29395 12.2778 3.29395 12.8125 2.84026C13.3472 2.38658 14.1528 2.38658 14.6875 2.84026C15.2222 3.29395 16.0278 3.29395 16.5625 2.84026C16.9258 2.53202 17.5 2.77819 17.5 3.24216V16.7578C17.5 17.2218 16.9258 17.468 16.5625 17.1597ZM5.625 10C5.625 9.65482 5.90482 9.375 6.25 9.375H13.75C14.0952 9.375 14.375 9.65482 14.375 10C14.375 10.3452 14.0952 10.625 13.75 10.625H6.25C5.90482 10.625 5.625 10.3452 5.625 10ZM6.25 6.45833C5.90482 6.45833 5.625 6.73816 5.625 7.08333C5.625 7.42851 5.90482 7.70833 6.25 7.70833H13.75C14.0952 7.70833 14.375 7.42851 14.375 7.08333C14.375 6.73816 14.0952 6.45833 13.75 6.45833H6.25ZM5.625 12.9167C5.625 12.5715 5.90482 12.2917 6.25 12.2917H13.75C14.0952 12.2917 14.375 12.5715 14.375 12.9167C14.375 13.2618 14.0952 13.5417 13.75 13.5417H6.25C5.90482 13.5417 5.625 13.2618 5.625 12.9167Z"
        fill="white"
        {...styles}
      />
    </svg>
  )
}

export default Bill
