import { Outlet, useLocation } from 'react-router-dom'

import ProgressBar from 'components/common/ProgressBar/ProgressBar'

const ClaimApplication = () => {
  const { pathname } = useLocation()

  const step =
    pathname === '/claims/new'
      ? 0
      : pathname.includes('insured-person')
      ? 1
      : pathname.includes('contacts')
      ? 2
      : pathname.includes('circumstances')
      ? 3
      : pathname.includes('documents')
      ? 4
      : 0

  return (
    <>
      <ProgressBar stepNumber={5} step={step} type="dashed" />
      <Outlet />
    </>
  )
}

export default ClaimApplication
