import classNames from 'classnames'
import style from './Input.module.scss'

const Input = ({
  className,
  darkBg,
  disabled,
  error,
  id,
  label,
  placeholder,
  suffix,
  value,
  type,
  onSuffixClick,
  readOnly,
  register,
  maxLength,
  disallowNumbers,
  disallowLetters,
}) => {
  const handleKeyDown = (e) => {
    if (e.key.length === 1 && ((disallowNumbers && /\d/.test(e.key)) || (disallowLetters && !/^[\d]/.test(e.key)))) {
      e.preventDefault();
    }
  };

  return (
    <div className={classNames(style['input-wrapper'], className, darkBg && style['dark-bg'], error && style.error)}>
      <label className={classNames('input-label', darkBg && 'dark-bg')} htmlFor={id}>
        {label}
      </label>
      <div className={style.input}>
        <input
          id={id}
          placeholder={placeholder}
          value={value}
          type={type}
          disabled={disabled}
          readOnly={readOnly}
          maxLength={maxLength}
          {...register}
          onKeyDown={handleKeyDown}
        />
        <div className={style.suffix} onClick={onSuffixClick}>
          {suffix}
        </div>
      </div>
    </div>
  );
};

export default Input;