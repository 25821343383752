// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PageTitle_page-title__wPkyp {\n  margin-bottom: 4px;\n  font-weight: 400;\n  font-size: 28px;\n  line-height: 48px;\n  color: #00007a;\n}", "",{"version":3,"sources":["webpack://./src/components/common/PageTitle/PageTitle.module.scss","webpack://./src/styles/colors.scss"],"names":[],"mappings":"AAGA;EACE,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cCDS;ADDX","sourcesContent":["@use 'styles/colors.scss';\n@use 'styles/sizes.scss';\n\n.page-title {\n  margin-bottom: 4px;\n  font-weight: 400;\n  font-size: 28px;\n  line-height: 48px;\n  color: colors.$primary8;\n}\n","$primary1: #f5f5ff;\n$primary2: #d8d8ff;\n$primary3: #8686ff;\n$primary4: #4343ff;\n$primary5: #1414ff;\n$primary6: #0000c9;\n$primary7: #000093;\n$primary8: #00007a;\n$primary9: #000054;\n$primary10: #00003a;\n$primary11: #000078;\n\n$neutral1: #fcfcfd;\n$neutral2: #f9f9fa;\n$neutral4: #e0e0e3;\n$neutral8: #838390;\n\n$success1: #f4fced;\n$success5: #58b441;\n$success6: #126f0e;\n\n$attention1: #fef9f0;\n$attention3: #fbd298;\n$attention5: #e78d3d;\n$attention7: #b94402;\n\n$danger1: #fdf1ed;\n$danger2: #f9d9cf;\n$danger3: #f4ada1;\n$danger5: #bd454a;\n$danger6: #911727;\n$danger7: #7c1028;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page-title": "PageTitle_page-title__wPkyp"
};
export default ___CSS_LOADER_EXPORT___;
