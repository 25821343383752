const CreditCard = ({ styles }) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.6667 26.6673H13.3334C8.30504 26.6673 5.79088 26.6673 4.22878 25.1052C2.66669 23.5431 2.66669 21.029 2.66669 16.0007C2.66669 15.4114 2.66669 14.8567 2.6692 14.334H29.3308C29.3334 14.8567 29.3334 15.4114 29.3334 16.0007C29.3334 21.029 29.3334 23.5431 27.7713 25.1052C26.2092 26.6673 23.695 26.6673 18.6667 26.6673ZM7.00002 21.334C7.00002 20.7817 7.44774 20.334 8.00002 20.334H13.3334C13.8856 20.334 14.3334 20.7817 14.3334 21.334C14.3334 21.8863 13.8856 22.334 13.3334 22.334H8.00002C7.44774 22.334 7.00002 21.8863 7.00002 21.334ZM15.6667 21.334C15.6667 20.7817 16.1144 20.334 16.6667 20.334H18.6667C19.219 20.334 19.6667 20.7817 19.6667 21.334C19.6667 21.8863 19.219 22.334 18.6667 22.334H16.6667C16.1144 22.334 15.6667 21.8863 15.6667 21.334Z"
        fill="#8686FF"
        {...styles}
      />
      <path
        d="M13.3334 5.33398H18.6667C23.695 5.33398 26.2092 5.33398 27.7713 6.89608C28.896 8.0208 29.2109 9.63908 29.2991 12.334H2.70098C2.78915 9.63908 3.10406 8.0208 4.22878 6.89608C5.79088 5.33398 8.30504 5.33398 13.3334 5.33398Z"
        fill="#8686FF"
        {...styles}
      />
    </svg>
  )
}

export default CreditCard
