const LockKeyhole = ({ styles }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.37496 7.37917V5.66699C3.37496 2.56039 5.89336 0.0419922 8.99996 0.0419922C12.1066 0.0419922 14.625 2.56039 14.625 5.66699V7.37917C15.5539 7.44854 16.1589 7.6237 16.6011 8.06589C17.3333 8.79813 17.3333 9.97664 17.3333 12.3337C17.3333 14.6907 17.3333 15.8692 16.6011 16.6014C15.8688 17.3337 14.6903 17.3337 12.3333 17.3337H5.66663C3.3096 17.3337 2.13109 17.3337 1.39886 16.6014C0.666626 15.8692 0.666626 14.6907 0.666626 12.3337C0.666626 9.97664 0.666626 8.79813 1.39886 8.06589C1.84105 7.6237 2.446 7.44854 3.37496 7.37917ZM4.62496 5.66699C4.62496 3.25075 6.58371 1.29199 8.99996 1.29199C11.4162 1.29199 13.375 3.25075 13.375 5.66699V7.33664C13.0558 7.33366 12.7096 7.33366 12.3333 7.33366H5.66663C5.29036 7.33366 4.94414 7.33366 4.62496 7.33664V5.66699ZM8.99996 10.042C9.34514 10.042 9.62496 10.3218 9.62496 10.667V14.0003C9.62496 14.3455 9.34514 14.6253 8.99996 14.6253C8.65478 14.6253 8.37496 14.3455 8.37496 14.0003V10.667C8.37496 10.3218 8.65478 10.042 8.99996 10.042Z"
        fill="#1414FF"
      />
    </svg>
  )
}

export default LockKeyhole
