const Phone = ({ styles }) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.0748 17.2076L21.4675 17.8114C21.4675 17.8114 20.0241 19.2466 16.0841 15.329C12.1441 11.4114 13.5875 9.97611 13.5875 9.97611L13.9699 9.59586C14.912 8.65914 15.0008 7.15523 14.1789 6.05733L12.4976 3.81146C11.4803 2.45257 9.51453 2.27306 8.34852 3.43245L6.25572 5.51337C5.67756 6.08825 5.29012 6.83347 5.3371 7.66016C5.4573 9.77511 6.41421 14.3256 11.7537 19.6348C17.4161 25.265 22.729 25.4887 24.9016 25.2862C25.5888 25.2221 26.1865 24.8721 26.6681 24.3933L28.5621 22.51C29.8407 21.2387 29.4802 19.0593 27.8444 18.17L25.297 16.7852C24.2229 16.2013 22.9143 16.3729 22.0748 17.2076Z"
        fill="#8686FF"
        {...styles}
      />
    </svg>
  )
}

export default Phone
