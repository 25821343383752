const User = ({ styles }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10.0001" cy="5.00033" r="3.33333" fill="white" {...styles} />
      <path
        d="M16.6666 14.583C16.6666 16.6541 16.6666 18.333 9.99992 18.333C3.33325 18.333 3.33325 16.6541 3.33325 14.583C3.33325 12.5119 6.31802 10.833 9.99992 10.833C13.6818 10.833 16.6666 12.5119 16.6666 14.583Z"
        fill="white"
        {...styles}
      />
    </svg>
  )
}

export default User
