// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Tooltip_tooltip__ulDbL {\n  position: relative;\n}\n.Tooltip_tooltip__ulDbL:hover .Tooltip_text__hB0eK {\n  opacity: 1;\n}\n\n.Tooltip_text__hB0eK {\n  opacity: 0;\n  position: absolute;\n  bottom: 100%;\n  left: 50%;\n  width: max-content;\n  max-width: 200px;\n  margin-left: 5px;\n  padding: 0 5px;\n  border: 1px solid #8686ff;\n  border-radius: 5px;\n  background: #fff;\n  font-weight: 300;\n  font-size: 14px;\n  line-height: 20px;\n}", "",{"version":3,"sources":["webpack://./src/components/common/Tooltip/Tooltip.module.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;AADF;AAII;EACE,UAAA;AAFN;;AAOA;EACE,UAAA;EACA,kBAAA;EACA,YAAA;EACA,SAAA;EACA,kBAAA;EACA,gBAAA;EACA,gBAAA;EACA,cAAA;EACA,yBAAA;EACA,kBAAA;EACA,gBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AAJF","sourcesContent":["@use 'styles/colors.scss';\n\n.tooltip {\n  position: relative;\n\n  &:hover {\n    .text {\n      opacity: 1;\n    }\n  }\n}\n\n.text {\n  opacity: 0;\n  position: absolute;\n  bottom: 100%;\n  left: 50%;\n  width: max-content;\n  max-width: 200px;\n  margin-left: 5px;\n  padding: 0 5px;\n  border: 1px solid colors.$primary3;\n  border-radius: 5px;\n  background: #fff;\n  font-weight: 300;\n  font-size: 14px;\n  line-height: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": "Tooltip_tooltip__ulDbL",
	"text": "Tooltip_text__hB0eK"
};
export default ___CSS_LOADER_EXPORT___;
