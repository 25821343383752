import classNames from 'classnames'

import style from './Card.module.scss'

const Card = ({
  className,
  content,
  disabled,
  inactive,
  titleIcon,
  title,
  cornerContent,
  onClick,
  isCentered,
  type,
}) => {
  return (
    <div
      className={classNames(
        style.card,
        className,
        disabled && style.disabled,
        inactive && style.inactive,
        isCentered && style.centered,
        onClick && style.clickable,
        type && style[type],
      )}
      onClick={onClick}
    >
      {(title || titleIcon || cornerContent) && (
        <div className={style.top}>
          <div className={style.title}>
            {titleIcon}
            <div className={style['title-text']}>{title}</div>
          </div>
          {cornerContent && <div className={classNames(style['corner-content'])}>{cornerContent}</div>}
        </div>
      )}
      {content && <div className={style['content']}>{content}</div>}
    </div>
  )
}

export default Card
