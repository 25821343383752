import classNames from 'classnames'

import style from './Tooltip.module.scss'

const Tooltip = ({ children, className, text }) => {
  return (
    <div className={classNames(className, style.tooltip)}>
      {children}
      <span className={style.text}>{text}</span>
    </div>
  )
}

export default Tooltip
