import { createBrowserRouter } from 'react-router-dom'

import SignUp from 'components/Auth/SignUp'
import SignIn from 'components/Auth/SignIn'
import ResetPassword from 'components/ResetPassword/ResetPassword'
import ResetPasswordRequested from 'components/ResetPassword/ResetPasswordRequested'
import PageWrapper from 'components/PageWrapper/PageWrapper'
import Contracts from 'components/Contracts/Contracts'
import ContractList from 'components/Contracts/ContractList/ContractList'
import ContractItem from 'components/Contracts/ContractItem/ContractItem'
import ContractDocuments from 'components/Contracts/ContractDocuments/ContractDocuments'
import ContractCancel from 'components/Contracts/ContractCancel/ContractCancel'
// import ContractGuarantees from 'components/Contracts/ContractGuarantees/ContractGuarantees'
import Claims from 'components/Claims/Claims'
import ClaimList from 'components/Claims/ClaimList/ClaimList'
import ClaimItem from 'components/Claims/ClaimItem/ClaimItem'
import ClaimApplication from 'components/Claims/ClaimApplication/ClaimApplication'
import CircumstancesForm from 'components/Claims/ClaimApplication/CircumstancesForm/CircumstancesForm'
import ContactForm from 'components/Claims/ClaimApplication/ContactForm/ContactForm'
import InsuredPersonSelection from 'components/Claims/ClaimApplication/InsuredPersonSelection/InsuredPersonSelection'
import ContractSelection from 'components/Claims/ClaimApplication/ContractSelection/ContractSelection'
import Contacts from 'components/Contacts/Contacts'
import ContactSummary from 'components/Contacts/ContactSummary/ContactSummary'
import ContactMainForm from 'components/Contacts/ContactMainForm/ContactMainForm'
import PasswordForm from 'components/Contacts/PasswordForm/PasswordForm'
import PaymentMethodForm from 'components/Contacts/PaymentMethodForm/PaymentMethodForm'
import ContactUs from 'components/ContactUs/ContactUs'
import NotFoundPage from 'components/NotFoundPage/NotFoundPage'
import DocumentForm from 'components/Claims/ClaimApplication/DocumentForm/DocumentForm'
import Subscription from 'components/Subscription/Subscription'
import SubscriptionMain from 'components/Subscription/SubscriptionMain/SubscriptionMain'
import MainForm from 'components/Subscription/MainForm/MainForm'
import ContactSubscriptionForm from 'components/Subscription/ContactForm/ContactForm'
import AddressForm from 'components/Subscription/AddressForm/AddressForm'
import Recap from 'components/Subscription/Recap/Recap'
import CreditCardForm from 'components/Subscription/CreditCardForm/CreditCardForm'
import SuccessPage from 'components/Subscription/SuccessPage/SuccessPage'
import Signature from 'components/Subscription/Signature/Signature'
import UnsuccessPage from 'components/Subscription/UnsuccessPage/UnsuccessPage'
import Expenses from 'components/Subscription/Expenses/Expenses'

const router = createBrowserRouter([
  {
    path: '/',
    element: <PageWrapper />,
    children: [
      {
        path: 'contracts',
        element: <Contracts />,
        children: [
          {
            path: '',
            element: <ContractList />,
          },
          {
            path: ':id',
            element: <ContractItem />,
          },
          {
            path: ':id/documents',
            element: <ContractDocuments />,
          },
          // not in mvp1
          // {
          //   path: ':id/guarantees',
          //   element: <ContractGuarantees />,
          // },
          {
            path: ':id/cancel',
            element: <ContractCancel />,
          },
        ],
      },
      {
        path: 'claims',
        element: <Claims />,
        children: [
          {
            path: '',
            element: <ClaimList />,
          },
          {
            path: ':id',
            element: <ClaimItem />,
          },
          {
            path: 'new',
            element: <ClaimApplication />,
            children: [
              {
                path: '',
                element: <ContractSelection />,
              },
              {
                path: 'insured-person',
                element: <InsuredPersonSelection />,
              },
              {
                path: 'contacts',
                element: <ContactForm />,
              },
              {
                path: 'circumstances',
                element: <CircumstancesForm />,
              },
              {
                path: 'documents',
                element: <DocumentForm />,
              },
            ],
          },
        ],
      },
      {
        path: 'contacts',
        element: <Contacts />,
        children: [
          {
            path: '',
            element: <ContactSummary />,
          },
          {
            path: 'edit',
            element: <ContactMainForm />,
          },
          {
            path: 'edit/password',
            element: <PasswordForm />,
          },
          {
            path: 'edit/payment-method',
            element: <PaymentMethodForm />,
          },
        ],
      },
      {
        path: 'contact-us',
        element: <ContactUs />,
      },
    ],
  },
  {
    path: 'sign-up',
    element: <SignUp />,
  },
  {
    path: 'sign-in',
    element: <SignIn />,
  },
  {
    path: 'reset-password',
    element: <ResetPassword />,
  },
  {
    path: 'reset-password/requested',
    element: <ResetPasswordRequested />,
  },
  {
    path: 'subscription/:id',
    element: <Subscription />,
    children: [
      {
        path: '',
        element: <SubscriptionMain />,
      },
      {
        path: 'main',
        element: <MainForm />,
      },
      {
        path: 'contacts',
        element: <ContactSubscriptionForm />,
      },
      {
        path: 'address',
        element: <AddressForm />,
      },
      {
        path: 'expenses',
        element: <Expenses />,
      },
      {
        path: 'recap',
        element: <Recap />,
      },
      {
        path: 'credit-card',
        element: <CreditCardForm />,
      },
      {
        path: 'signature',
        element: <Signature />,
      },
      {
        path: 'success',
        element: <SuccessPage />,
      },
      {
        path: 'cannot_continue_the_subscription',
        element: <UnsuccessPage />,
      },
    ],
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
])

export default router
