const ShieldLogo = ({ styles }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.62659 20C6.61129 19.128 0.61528 14.7783 0.478123 4.33545C0.475411 4.12899 0.63234 3.95222 0.838887 3.91279C2.25694 3.64207 4.50198 2.66631 4.74524 0.408477C4.76939 0.184304 4.95304 0 5.18276 0L14.8172 0C15.047 0 15.2306 0.184304 15.2548 0.408477C15.498 2.66631 17.7431 3.2751 19.1611 3.54582C19.3677 3.58525 19.5246 3.76201 19.5219 3.96848C19.3847 14.4113 12.6419 19.128 9.62659 20Z"
        fill="#B2B2BA"
      />
      <path
        d="M9.62659 20C6.61129 19.128 0.61528 14.7783 0.478123 4.33545C0.475411 4.12899 0.63234 3.95222 0.838887 3.91279C2.25694 3.64207 4.50198 2.66631 4.74524 0.408477C4.76939 0.184304 4.95304 0 5.18276 0L14.0704 0C14.3002 0 14.4838 0.184304 14.508 0.408477C14.7512 2.66631 16.9963 3.64207 18.4143 3.91279C18.6209 3.95222 18.7778 4.12899 18.7751 4.33545C18.6379 14.7783 12.6419 19.128 9.62659 20Z"
        fill="#E0E0E3"
      />
      <path
        d="M17.8411 4.6879C17.7647 14.1679 12.3459 18.1102 9.6269 18.8977V0.916016H13.7643C13.8888 0.916016 13.9888 1.01712 13.9968 1.14131C14.1389 3.34425 16.3557 4.24496 17.6446 4.45684C17.7573 4.47536 17.842 4.57373 17.8411 4.6879Z"
        fill="#000093"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0046 17.7093C14.689 15.8361 17.7829 11.8366 17.8405 4.68985C17.8414 4.57568 17.7566 4.47732 17.644 4.4588C16.3551 4.24691 14.1383 3.34621 13.9962 1.14327C13.9882 1.01907 13.8882 0.917969 13.7637 0.917969H13.0176C13.1421 0.917969 13.2421 1.01907 13.2503 1.14326C13.3967 3.37287 15.6949 4.66392 16.9918 4.91456C17.1039 4.93623 17.1886 5.03463 17.1878 5.1488C17.1377 12.1982 14.4812 15.9344 12.0046 17.7093ZM9.627 18.8994C9.62677 18.8995 9.62653 18.8996 9.62629 18.8996V0.917969H9.627V18.8994Z"
        fill="#000054"
      />
      <path
        d="M1.41128 4.68887C1.48764 14.1689 6.90645 18.1112 9.62545 18.8986V0.916992H5.48803C5.36358 0.916992 5.26352 1.01809 5.25551 1.14229C5.11345 3.34523 2.89666 4.24594 1.60776 4.45782C1.49509 4.47634 1.41036 4.5747 1.41128 4.68887Z"
        fill="#1414FF"
      />
      <g clipPath="url(#clip0_3689_4681)">
        <path
          d="M13.9106 8.03513C13.3397 6.48765 11.8981 5.351 10.238 5.13925C10.1518 5.12641 10.0672 5.19505 10.0612 5.28081L9.98301 6.15008C9.97558 6.23344 10.0357 6.30773 10.1203 6.31903C11.3352 6.48285 12.3572 7.29768 12.7873 8.44546C12.8177 8.52745 12.9109 8.56854 12.9908 8.53875L13.8167 8.23507C13.8981 8.20512 13.9403 8.11542 13.9106 8.03513V8.03513Z"
          fill="#FFA92C"
        />
        <path
          d="M9.6142 6.76074C7.97053 6.76074 6.63806 7.93967 6.63806 9.7566C6.63806 11.5735 7.91711 12.7523 9.6142 12.7523C11.3113 12.7523 12.5903 11.4712 12.5903 9.7566C12.5903 8.04204 11.4216 6.76074 9.6142 6.76074ZM9.6142 11.6098C8.64826 11.6098 7.92039 10.8592 7.92039 9.7566C7.92039 8.65401 8.67869 7.90338 9.6142 7.90338C10.6427 7.90338 11.308 8.7331 11.308 9.7566C11.308 10.7801 10.6038 11.6098 9.6142 11.6098V11.6098Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3689_4681">
          <rect width="7.28147" height="7.61469" fill="white" transform="translate(6.63815 5.1377)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ShieldLogo
