import classNames from 'classnames'
import style from './ProgressBar.module.scss'

const ProgressBar = ({ className, stepNumber, step, type }) => {
  const progressSteps = [...Array(stepNumber).keys()]

  return (
    <div className={classNames(style.progress, style[type], className)}>
      {progressSteps.map(item => {
        return (
          <div
            className={classNames(style.step, item === step && style.current, item <= Math.floor(step) && style.done)}
            key={`progress-step-${item}`}
            style={{ width: `${100 / stepNumber}%` }}
          ></div>
        )
      })}
    </div>
  )
}

export default ProgressBar
