import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import style from './InsuredPersonSelection.module.scss'
import DocumentText from 'components/assets/icons/DocumentText'
import CircleSmile from 'components/assets/icons/CircleSmile'
import SquareSmile from 'components/assets/icons/SquareSmile'
import Card from 'components/common/Card/Card'
import PageTitle from 'components/common/PageTitle/PageTitle'
import PageSubtitle from 'components/common/PageSubtitle/PageSubtitle'
import Arrow from 'components/assets/icons/Arrow'

const InsuredPersonSelection = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { state } = useLocation()

  if (!state?.contract) navigate('/claims/new')

  const { contract } = state

  const onSelectInsuredPerson = isSomeoneElse => {
    navigate('../contacts', { state: { contract, step: 1, isSomeoneElse, fromClaim: true } })
  }

  return (
    <>
      <PageTitle text={t('claim:applySteps.insuredPerson.title')} />
      <PageSubtitle text={t('claim:applySteps.insuredPerson.subtitle')} />

      <div className={style.options}>
        <Card
          className={style.option}
          content={
            <div className={style['option-content']}>
              <CircleSmile />
              <p>{t('claim:applySteps.insuredPerson.you')}</p>
            </div>
          }
          inactive={contract.cancelation}
          isCentered
          onClick={() => onSelectInsuredPerson()}
        />
        <Card
          className={style.option}
          content={
            <div className={style['option-content']}>
              <SquareSmile />
              <p>{t('claim:applySteps.insuredPerson.someoneElse')}</p>
            </div>
          }
          inactive={contract.cancelation}
          isCentered
          onClick={() => onSelectInsuredPerson(true)}
        />
      </div>

      <Card
        inactive={contract.cancelation}
        content={contract.rateType.dashboardPackageDescription || contract.contractType.dashboardDescription}
        onClick={() =>
          navigate(`/contracts/${contract._id}`, {
            state: { fromClaim: true, step: 1 },
          })
        }
        title={
          contract.rateType?.dashboardPackageName 
          ? `${contract.rateType.dashboardPackageName} ${contract.idTransaction}` 
          : `${contract.contractType.dashboardName} ${contract.idTransaction}`
        }
        titleIcon={<DocumentText />}
        cornerContent={<Arrow />}
      />
    </>
  )
}

export default InsuredPersonSelection
