const ShieldStar = ({ styles }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.8146 4.23567C2.5 4.68389 2.5 6.01622 2.5 8.68087V9.99312C2.5 14.6915 6.03247 16.9716 8.2488 17.9397C8.85001 18.2023 9.15062 18.3337 10 18.3337C10.8494 18.3337 11.15 18.2023 11.7512 17.9397C13.9675 16.9716 17.5 14.6915 17.5 9.99312V8.68087C17.5 6.01622 17.5 4.68389 17.1854 4.23567C16.8708 3.78744 15.6181 3.35862 13.1126 2.50099L12.6352 2.33759C11.3292 1.89052 10.6762 1.66699 10 1.66699C9.32384 1.66699 8.67082 1.89052 7.36477 2.33759L6.88743 2.50099C4.38194 3.35862 3.12919 3.78744 2.8146 4.23567ZM9.05105 6.96978L8.94182 7.16574C8.82184 7.38097 8.76185 7.48859 8.66831 7.55959C8.57478 7.6306 8.45828 7.65696 8.2253 7.70968L8.01318 7.75767C7.19329 7.94318 6.78334 8.03593 6.68581 8.34957C6.58827 8.66321 6.86775 8.99002 7.4267 9.64363L7.57131 9.81273C7.73014 9.99847 7.80956 10.0913 7.84529 10.2062C7.88102 10.3211 7.86901 10.445 7.84499 10.6928L7.82313 10.9185C7.73863 11.7905 7.69637 12.2266 7.95172 12.4204C8.20706 12.6142 8.59089 12.4375 9.35856 12.084L9.55716 11.9926C9.77531 11.8922 9.88438 11.8419 10 11.8419C10.1156 11.8419 10.2247 11.8922 10.4428 11.9926L10.6414 12.084C11.4091 12.4375 11.7929 12.6142 12.0483 12.4204C12.3036 12.2266 12.2614 11.7905 12.1769 10.9185L12.155 10.6928C12.131 10.445 12.119 10.3211 12.1547 10.2062C12.1904 10.0913 12.2699 9.99847 12.4287 9.81273L12.5733 9.64363C13.1322 8.99002 13.4117 8.66321 13.3142 8.34957C13.2167 8.03593 12.8067 7.94318 11.9868 7.75767L11.7747 7.70967C11.5417 7.65696 11.4252 7.6306 11.3317 7.55959C11.2381 7.48859 11.1782 7.38097 11.0582 7.16574L10.9489 6.96978C10.5267 6.21237 10.3156 5.83366 10 5.83366C9.68438 5.83366 9.47327 6.21237 9.05105 6.96978Z"
        fill="#1414FF"
      />
    </svg>
  )
}

export default ShieldStar
