import classNames from 'classnames'
import { Link as LinkReact } from 'react-router-dom'
import style from './Link.module.scss'

const Link = ({ className, darkBg, label, onClick, styles, to, type, ...props }) => {
  return (
    <span className={classNames(style.link, className, darkBg && style['dark-bg'], type && style[type])}>
      <LinkReact
        onClick={event => {
          if (onClick) {
            event.preventDefault()
            onClick()
          }
        }}
        to={to}
        styles={styles}
        {...props}
      >
        {label}
      </LinkReact>
    </span>
  )
}

export default Link
