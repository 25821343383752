import classNames from 'classnames'

import PrefixedText from 'components/common/PrefixedText/PrefixedText'
import style from './InfoMessage.module.scss'

const InfoMessage = ({ className, label, prefix, suffix, type }) => {
  return (
    <div className={classNames(style['info-message'], style[type], className)}>
      <PrefixedText prefix={prefix} text={label} />
      {suffix && <div className={style.suffix}>{suffix}</div>}
    </div>
  )
}

export default InfoMessage
