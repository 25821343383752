const Star = ({ className }) => {
  return (
    <svg
      className={className}
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.32758 1.39338C6.59862 0.831728 7.40137 0.831727 7.67242 1.39338V1.39338C8.78486 3.69859 10.588 5.60981 12.827 6.85017V6.85017C13.3375 7.13299 13.3375 7.86701 12.827 8.14983V8.14983C10.588 9.39019 8.78486 11.3014 7.67242 13.6066V13.6066C7.40138 14.1683 6.59863 14.1683 6.32758 13.6066V13.6066C5.21514 11.3014 3.412 9.39019 1.17302 8.14983V8.14983C0.662503 7.86701 0.662502 7.13299 1.17302 6.85017V6.85017C3.412 5.60981 5.21514 3.69859 6.32758 1.39338V1.39338Z"
        fill="#FFA92C"
      />
    </svg>
  )
}

export default Star
