import dayjs from 'dayjs'
import * as customParseFormat from 'dayjs/plugin/customParseFormat'

export const passwordValidation = {
  required: true,
  minLength: 9,
  // at least one capital character and one digit
  pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{9,}$/,
}

export const birthdayValidation = {
  required: true,
  validate: value => {
    dayjs.extend(customParseFormat)

    return dayjs().isAfter(dayjs(value, 'DD/MM/YYYY', true).add(18, 'years')) && dayjs().isBefore(dayjs(value, 'DD/MM/YYYY', true).add(70, 'years'));
  },
}

export const numberValidationCardNumber = {
  required: true,
  validate: (value) => {
    const numericValue = value.replace(/\D/g, '');
    return numericValue.length === 16;
  },
};

export const expirationDateValidation = {
  required: true,
  validate: (value) => {
    const currentDate = dayjs();
    
    if (!dayjs(value, 'MM/YY', true).isValid()) {
      return false;
    }
    const enteredDate = dayjs(value, 'MM/YY', true);
    return enteredDate.isAfter(currentDate);
  },
};

export const numberValidationCVC = {
  required: true,
  validate: (value) => {
    const numericValue = value.replace(/\D/g, '');
    return numericValue.length === 4;
  },
};