import classNames from 'classnames'
import style from './CardLine.module.scss'

const CardLine = ({ className, content, styles, suffix, title }) => {
  return (
    <div className={classNames(style['card-line'], className)} style={{ ...styles }}>
      <div>
        {title && <span className={style.title}>{title}</span>}
        {content && <span className={style.content}>{content}</span>}
      </div>
      {suffix && <div className={style.suffix}>{suffix}</div>}
    </div>
  )
}

export default CardLine
