import { RouterProvider } from 'react-router-dom'
import ReactModal from 'react-modal'

import router from 'router'
import style from './App.module.scss'

ReactModal.setAppElement(document.getElementById('root'))

const App = () => {
  return (
    <div className={style.app}>
      <RouterProvider router={router} />
    </div>
  )
}

export default App
