import classNames from 'classnames'
import style from './Checkbox.module.scss'

const Checkbox = ({ className, checked, disabled, id, label, onChange, register }) => {
  return (
    <div className={classNames(className, style.checkbox)}>
      <div className={style.input}>
        <input
          defaultChecked={checked}
          disabled={disabled}
          id={id || label}
          onChange={onChange}
          type="checkbox"
          {...register}
        />
      </div>
      <label htmlFor={id || label}>{label}</label>
    </div>
  )
}

export default Checkbox
