const CashOut = ({ styles }) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 8H12C9.4858 8 8.22872 8 7.44767 8.78105C6.66663 9.5621 6.66663 10.8192 6.66663 13.3333L6.6667 21.6667H25.3332L25.3333 13.3333C25.3333 10.8192 25.3333 9.5621 24.5522 8.78105C23.7712 8 22.5141 8 20 8H17V14.6301L17.9074 13.5714C18.2668 13.1521 18.8981 13.1035 19.3174 13.463C19.7368 13.8224 19.7853 14.4537 19.4259 14.873L16.7592 17.9841C16.5693 18.2058 16.2919 18.3333 16 18.3333C15.7081 18.3333 15.4307 18.2058 15.2407 17.9841L12.5741 14.873C12.2146 14.4537 12.2632 13.8224 12.6825 13.463C13.1018 13.1035 13.7331 13.1521 14.0926 13.5714L15 14.6301V8Z"
        fill="#8686FF"
        {...styles}
      />
      <path
        d="M6.70743 23.6667H25.2925C25.2241 24.7208 25.0413 25.3965 24.5522 25.8856C23.7712 26.6667 22.5141 26.6667 20 26.6667H12C9.4858 26.6667 8.22872 26.6667 7.44767 25.8856C6.9586 25.3965 6.77577 24.7208 6.70743 23.6667Z"
        fill="#8686FF"
        {...styles}
      />
      <path
        d="M7.85181 4H24.1481C27.0118 4 29.3333 6.39997 29.3333 9.36048C29.3333 11.0796 28.5505 12.6097 27.3333 13.5907L27.3333 13.214C27.3334 12.0599 27.3335 11.0016 27.2178 10.1408C27.0903 9.19285 26.7905 8.19092 25.9664 7.36685C25.1424 6.54279 24.1404 6.24293 23.1925 6.11548C22.3316 5.99975 21.2734 5.99987 20.1193 6.00001H11.8806C10.7264 5.99987 9.6682 5.99975 8.80737 6.11548C7.85943 6.24293 6.85749 6.54279 6.03343 7.36685C5.20937 8.19092 4.90951 9.19285 4.78206 10.1408C4.66632 11.0016 4.66645 12.0598 4.66658 13.214L4.6666 13.5906C3.44941 12.6097 2.66663 11.0796 2.66663 9.36048C2.66663 6.39997 4.98811 4 7.85181 4Z"
        fill="#8686FF"
        {...styles}
      />
    </svg>
  )
}

export default CashOut
