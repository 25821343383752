export const isInputError = (errors, field) => errors.includes(field)

export const requiredClientContactFields = [
  'firstname',
  'lastname',
  'birthdate',
  'placeOfBirth',
  'address',
  'zipcode',
  'city',
  'country',
  'email',
  'phone',
]
