const Thundercloud = ({ styles }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.02181 14.5543L8.97873 13.2587C9.59728 12.4212 9.90655 12.0024 10.1951 12.0909C10.4837 12.1794 10.4837 12.693 10.4837 13.7202V13.817C10.4837 14.1875 10.4837 14.3727 10.6019 14.4889L10.6082 14.4949C10.7291 14.6087 10.9217 14.6087 11.307 14.6087C12.0004 14.6087 12.3471 14.6087 12.4643 14.8191C12.4662 14.8226 12.4681 14.8261 12.47 14.8296C12.5806 15.0433 12.3798 15.3151 11.9783 15.8587L11.0214 17.1543C10.4029 17.9918 10.0936 18.4105 9.80503 18.322C9.51648 18.2335 9.51649 17.7199 9.5165 16.6928L9.5165 16.596C9.51651 16.2255 9.51651 16.0402 9.39822 15.924L9.39196 15.918C9.27111 15.8043 9.07845 15.8043 8.69313 15.8043C7.99973 15.8043 7.65303 15.8043 7.53586 15.5938C7.53392 15.5904 7.53204 15.5869 7.5302 15.5833C7.41959 15.3696 7.62033 15.0978 8.02181 14.5543Z"
        fill="white"
        {...styles}
      />
      <path
        d="M6.31471 15.0097C6.364 14.8371 6.43117 14.6972 6.48065 14.6043C6.61204 14.3574 6.81289 14.0859 6.98162 13.8578L8.0155 12.458C8.28759 12.0894 8.56153 11.7183 8.80993 11.46C8.99145 11.2712 9.6405 10.6124 10.5613 10.8948C11.5097 11.1856 11.6515 12.1276 11.6863 12.3863C11.7237 12.6641 11.7316 13.0072 11.7332 13.3599C11.9206 13.3635 12.1089 13.3727 12.2748 13.396C12.5537 13.435 13.1973 13.5656 13.5562 14.2101C13.5643 14.2247 13.5722 14.2394 13.5799 14.2542C13.7174 14.52 13.7588 14.7768 13.7501 15.0064C16.2973 14.9135 18.3334 12.8422 18.3334 10.3005C18.3334 8.23919 16.9942 6.48722 15.1293 5.84906C14.8643 3.49636 12.8467 1.66699 10.3969 1.66699C7.76698 1.66699 5.635 3.77536 5.635 6.37617C5.635 6.95141 5.7393 7.50257 5.93021 8.01215C5.70633 7.96868 5.47494 7.94589 5.23818 7.94589C3.26573 7.94589 1.66675 9.52717 1.66675 11.4778C1.66675 13.4284 3.26573 15.0097 5.23818 15.0097H6.31471Z"
        fill="white"
        {...styles}
      />
    </svg>
  )
}

export default Thundercloud
