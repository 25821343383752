// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ContactMainForm_info-message__87o06 {\n  margin-top: 8px;\n}\n\n.ContactMainForm_form__kPCKu {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.ContactMainForm_inputs__6N53d {\n  width: 100%;\n  column-count: 2;\n  gap: 20px;\n  margin: 28px 0 32px;\n}\n\n.ContactMainForm_input__RUmKa:not(:last-child) {\n  margin-bottom: 20px;\n}\n.ContactMainForm_input__RUmKa input {\n  width: 100%;\n}\n\n.ContactMainForm_button__w7n14 {\n  width: 200px;\n}\n\n@media screen and (max-width: 600px) {\n  .ContactMainForm_inputs__6N53d {\n    column-count: 1;\n  }\n  .ContactMainForm_button__w7n14 {\n    width: 100%;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/Contacts/ContactMainForm/ContactMainForm.module.scss"],"names":[],"mappings":"AAGA;EACE,eAAA;AAFF;;AAKA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AAFF;;AAKA;EACE,WAAA;EACA,eAAA;EACA,SAAA;EACA,mBAAA;AAFF;;AAME;EACE,mBAAA;AAHJ;AAME;EACE,WAAA;AAJJ;;AAQA;EACE,YAAA;AALF;;AAQA;EACE;IACE,eAAA;EALF;EAQA;IACE,WAAA;EANF;AACF","sourcesContent":["@use 'styles/sizes.scss';\n@use 'styles/colors.scss';\n\n.info-message {\n  margin-top: 8px;\n}\n\n.form {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.inputs {\n  width: 100%;\n  column-count: 2;\n  gap: 20px;\n  margin: 28px 0 32px;\n}\n\n.input {\n  &:not(:last-child) {\n    margin-bottom: 20px;\n  }\n\n  input {\n    width: 100%;\n  }\n}\n\n.button {\n  width: 200px;\n}\n\n@media screen and (max-width: sizes.$breakpoint-sm) {\n  .inputs {\n    column-count: 1;\n  }\n\n  .button {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"info-message": "ContactMainForm_info-message__87o06",
	"form": "ContactMainForm_form__kPCKu",
	"inputs": "ContactMainForm_inputs__6N53d",
	"input": "ContactMainForm_input__RUmKa",
	"button": "ContactMainForm_button__w7n14"
};
export default ___CSS_LOADER_EXPORT___;
