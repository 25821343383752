import classNames from 'classnames'
import style from './Switch.module.scss'

const cn = 'switch'

const Switch = ({ checked = false, className, disabled, name, onChange, option1, option2, styles }) => {
  return (
    <div className={classNames(className, style[cn])}>
      <input checked={checked} disabled={disabled} id={name} name={name} onChange={onChange} type="checkbox" />
      <label className={style[`${cn}-labels`]} htmlFor={name} style={styles}>
        <span className={style[`${cn}-label1`]}>{option1}</span>
        <span className={style[`${cn}-label2`]}>{option2}</span>
      </label>
    </div>
  )
}

export default Switch
