const ArchiveUp = ({ className, styles }) => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.16699 4.16667C2.16699 3.38099 2.16699 2.98816 2.41107 2.74408C2.65515 2.5 3.04798 2.5 3.83366 2.5H17.167C17.9527 2.5 18.3455 2.5 18.5896 2.74408C18.8337 2.98816 18.8337 3.38099 18.8337 4.16667C18.8337 4.95234 18.8337 5.34518 18.5896 5.58926C18.3455 5.83333 17.9527 5.83333 17.167 5.83333H3.83366C3.04798 5.83333 2.65515 5.83333 2.41107 5.58926C2.16699 5.34518 2.16699 4.95234 2.16699 4.16667Z"
        fill="#1414FF"
      />
      <path
        d="M17.2244 7.08338C17.342 7.08342 17.4629 7.08347 17.5837 7.0818V10.8333C17.5837 13.976 17.5837 15.5474 16.6073 16.5237C15.6529 17.4782 14.1296 17.4995 11.1253 17.5L11.1253 11.6287L12.5358 13.1959C12.7667 13.4524 13.1619 13.4732 13.4184 13.2423C13.675 13.0114 13.6958 12.6162 13.4649 12.3597L10.9649 9.58189C10.8464 9.4502 10.6775 9.375 10.5003 9.375C10.3232 9.375 10.1543 9.4502 10.0358 9.58189L7.53577 12.3597C7.30486 12.6162 7.32566 13.0114 7.58223 13.2423C7.8388 13.4732 8.23398 13.4524 8.46489 13.1959L9.87533 11.6287L9.87533 17.5C6.87104 17.4995 5.3478 17.4782 4.3933 16.5237C3.41699 15.5474 3.41699 13.976 3.41699 10.8333V7.0818C3.53773 7.08347 3.65865 7.08342 3.77628 7.08338H17.2244Z"
        fill="#1414FF"
      />
    </svg>
  )
}

export default ArchiveUp
