const SquareSmile = ({ className, styles }) => {
  return (
    <svg width="28" height="27" viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.61913 2.11913C0.666504 4.07175 0.666504 7.21444 0.666504 13.4998C0.666504 19.7852 0.666504 22.9279 2.61913 24.8805C4.57175 26.8332 7.71444 26.8332 13.9998 26.8332C20.2852 26.8332 23.4279 26.8332 25.3805 24.8805C27.3332 22.9279 27.3332 19.7852 27.3332 13.4998C27.3332 7.21444 27.3332 4.07175 25.3805 2.11913C23.4279 0.166504 20.2852 0.166504 13.9998 0.166504C7.71444 0.166504 4.57175 0.166504 2.61913 2.11913ZM17.9998 13.4998C18.7362 13.4998 19.3332 12.6044 19.3332 11.4998C19.3332 10.3953 18.7362 9.49984 17.9998 9.49984C17.2635 9.49984 16.6665 10.3953 16.6665 11.4998C16.6665 12.6044 17.2635 13.4998 17.9998 13.4998ZM11.3332 11.4998C11.3332 12.6044 10.7362 13.4998 9.99984 13.4998C9.26346 13.4998 8.6665 12.6044 8.6665 11.4998C8.6665 10.3953 9.26346 9.49984 9.99984 9.49984C10.7362 9.49984 11.3332 10.3953 11.3332 11.4998ZM9.19647 18.2377C9.52535 17.794 10.1516 17.7009 10.5953 18.0298C11.5664 18.7496 12.7386 19.1665 13.9998 19.1665C15.261 19.1665 16.4332 18.7496 17.4044 18.0298C17.848 17.7009 18.4743 17.794 18.8032 18.2377C19.1321 18.6814 19.039 19.3077 18.5953 19.6365C17.2988 20.5976 15.7131 21.1665 13.9998 21.1665C12.2865 21.1665 10.7009 20.5976 9.40435 19.6365C8.96067 19.3077 8.86759 18.6814 9.19647 18.2377Z"
        fill="#8686FF"
      />
    </svg>
  )
}

export default SquareSmile
