import { useEffect, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import classNames from 'classnames'

import './Datepicker.scss'

const weekdays = ['L', 'M', 'M', 'J', 'V', 'S', 'D']
const months = [
  'janvier',
  'février',
  'mars',
  'avril',
  'mai',
  'juin',
  'juillet',
  'aout',
  'septembre',
  'octobre',
  'novembre',
  'décembre',
]

const locale = {
  localize: {
    day: n => weekdays[n],
    month: n => months[n],
  },
  formatLong: {
    date: () => 'mm/dd/yyyy',
    dateTime: () => 'mm/dd/yyyy hh:mm',
    time: () => 'hh:mm',
  },
  match: {},
}

const Datepicker = ({
  className,
  error,
  isTimepickerShown,
  minDate,
  maxDate,
  onBlur,
  onChange,
  placeholder,
  value,
  ...props
}) => {
  const [selectedDate, setSelectedDate] = useState(new Date())

  const handleChange = date => {
    setSelectedDate(date)
    onChange && onChange(date)
  }

  useEffect(() => {
    if (value !== selectedDate) {
      setSelectedDate(value)
    }
  }, [selectedDate, setSelectedDate, value])

  return (
    <ReactDatePicker
      className={classNames(className, error && 'datepicker-error')}
      dateFormat={isTimepickerShown ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy'}
      locale={locale}
      maxDate={maxDate || new Date()}
      minDate={minDate}
      onBlur={onBlur}
      onChange={date => handleChange(date)}
      placeholderText={placeholder}
      selected={selectedDate}
      showTimeInput={isTimepickerShown}
      showMonthDropdown
      showYearDropdown
      value={value}
      {...props}
    />
  )
}

export default Datepicker
