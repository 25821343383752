const CircleOk = ({ className, styles }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3337 9C17.3337 13.6024 13.6027 17.3333 9.00033 17.3333C4.39795 17.3333 0.666992 13.6024 0.666992 9C0.666992 4.39763 4.39795 0.666672 9.00033 0.666672C13.6027 0.666672 17.3337 4.39763 17.3337 9ZM12.3589 6.47473C12.603 6.71881 12.603 7.11454 12.3589 7.35861L8.19227 11.5253C7.94819 11.7694 7.55246 11.7694 7.30838 11.5253L5.64172 9.85861C5.39764 9.61454 5.39764 9.21881 5.64172 8.97473C5.88579 8.73065 6.28152 8.73065 6.5256 8.97473L7.75033 10.1995L9.61269 8.33709L11.475 6.47473C11.7191 6.23065 12.1149 6.23065 12.3589 6.47473Z"
        fill="#58B441"
      />
    </svg>
  )
}

export default CircleOk
