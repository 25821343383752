import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PageTitle from 'components/common/PageTitle/PageTitle'
import PageSubtitle from 'components/common/PageSubtitle/PageSubtitle'
import style from './ContractList.module.scss'
import InfoMessage from 'components/common/InfoMessage/InfoMessage'
import ShieldLogo from 'components/assets/icons/ShieldLogo'
import DocumentText from 'components/assets/icons/DocumentText'
import DocumentTextMarron from 'components/assets/icons/DocumentTextMarron'
import Arrow from 'components/assets/icons/Arrow'
import Card from 'components/common/Card/Card'
import CardLegalDocuments from 'components/common/CardLegalDocuments/CardLegalDocuments'
import api from 'helpers/api'
import Button from 'components/common/Button/Button'
import { sortContractsByStatus } from 'helpers/contract'
import { useNavigate } from 'react-router-dom'
import ExclamationTriangleOrange from 'components/assets/icons/ExclamationTriangleOrange'

const ContractList = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [contracts, setContracts] = useState([])
  const [contractTypeName, setContractTypeName] = useState('');
  const [daysRemaining, setDaysRemaining] = useState(null);
  const [globalStatus, setGlobalStatus] = useState(true);
  const [globalAdhesionId, setGlobalAdhesionId] = useState('');

  useEffect(() => {
    const getContracts = async () => {
      try {
        const res = await api.get({ url: '/adhesions/client' })
        setContracts(sortContractsByStatus(res.adhesions))
        setContractTypeName(res.contractTypeName);
        setDaysRemaining(res.daysRemaining);
        setGlobalStatus(res.globalStatus);
        setGlobalAdhesionId(res.adhesionId);
        console.log(res)
      } catch (error) {
        console.log('Fetch contracts error')
      }
    }
    getContracts()
  }, [])

  return (
    <>
      <PageTitle text={t('contract:list.title')} />
      <PageSubtitle text={t('contract:list.subtitle')} />
      <InfoMessage
        className={style['info-message']}
        label={t('claim:infoMessage')}
        prefix={<ShieldLogo />}
        suffix={
          <Button
            className={style['claim-button']}
            label={t('claim:ctaInfinitive')}
            onClick={() => navigate('/claims/new')}
            type="primary"
          />
        }
      />
      <br />
      {!globalStatus && daysRemaining > 0 && (
      <InfoMessage
        className={`${style['info-message']} ${style['custom-info-message']}`}
        label={`Il vous reste ${daysRemaining} jours pour télécharger vos pièces justificatives pour le contrat ${contractTypeName} !`}
        prefix={<ExclamationTriangleOrange />}
        suffix={
          <Button
            className={`${style['claim-button']} ${style['custom-claim-button']}`}
            label={"Télécharger mes documents"}
            onClick={() => navigate(`/contracts/${globalAdhesionId}/documents`)}
            type="primary"
          />
        }
      />
    )}
      
      

     

      <div className={style.items}>
        {!!contracts?.length &&
          contracts.map((item, index) => {
            // Déterminer si la condition est remplie pour choisir la deuxième solution
            const isProblematic = globalStatus === false && daysRemaining > 0;

            return isProblematic ? (
              // Utiliser la deuxième solution si la condition est remplie
              <CardLegalDocuments
                className={style.item}
                inactive={item.cancelation}
                content={item.rateType.dashboardPackageDescription || item.contractType.dashboardDescription}
                key={`contract-item-${index}`}
                onClick={() => navigate(item._id)}
                title={
                  item.rateType?.dashboardPackageName
                    ? `${item.rateType.dashboardPackageName} ${item.idTransaction}`
                    : `${item.contractType.dashboardName} ${item.idTransaction}`
                }
                titleIcon={<DocumentTextMarron />} // Icône marron dans la deuxième solution
                cornerContent={<Arrow />}
              />
            ) : (
              // Utiliser la première solution si la condition n'est pas remplie
              <Card
                className={style.item}
                inactive={item.cancelation}
                content={item.rateType.dashboardPackageDescription || item.contractType.dashboardDescription}
                key={`contract-item-${index}`}
                onClick={() => navigate(item._id)}
                title={
                  item.rateType?.dashboardPackageName
                    ? `${item.rateType.dashboardPackageName} ${item.idTransaction}`
                    : `${item.contractType.dashboardName} ${item.idTransaction}`
                }
                titleIcon={<DocumentText />} // Icône normale dans la première solution
                cornerContent={<Arrow />}
              />
            );
          })}
      </div>

    </>
  )
}

export default ContractList
