import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'

import style from './NotFoundPage.module.scss'
import Button from 'components/common/Button/Button'
import Header from 'components/Header/Header'
import NavMenuFooter from 'components/NavMenuFooter/NavMenuFooter'
import { isMobile } from 'helpers/styles'
import NavMenu from 'components/NavMenu/NavMenu'

const NotFoundPage = ({ error, resetError }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [isMenuRendered, setIsMenuRendered] = useState(!isMobile())

  const accessToken = localStorage.getItem('accessToken')

  useEffect(() => {
    if (!accessToken) {
      navigate('/sign-in')
    }
  }, [accessToken, navigate])

  window.onresize = () => {
    setIsMenuRendered(!isMobile())
  }

  const onNavItemClick = path => {
    if (isMenuRendered) setIsMenuRendered(false)
    navigate(path)
  }

  const onToggleMenu = () => {
    setIsMenuRendered(!isMenuRendered)
  }

  const navigateHome = () => {
    navigate('/')
    if (error) resetError()
  }

  return (
    <div className={style['not-found']} style={{ justifyContent: (!isMenuRendered || !isMobile()) && 'center' }}>
      {isMenuRendered && isMobile() && <NavMenu onNavItemClick={onNavItemClick} />}
      <div className={style.content}>
        <Header lightBg styles={{ position: 'absolute', top: 0, left: 0 }} />
        <h2 className={style.title}>{t('main:notFoundPage.title')}</h2>
        <p className={style.subtitle}>{t('main:notFoundPage.subtitle')}</p>
        <Button className={style.button} label={t('common:navigateHome')} onClick={navigateHome} type="primary" />
        {isMobile() && <NavMenuFooter onNavItemClick={onNavItemClick} onToggleMenu={onToggleMenu} />}
      </div>
    </div>
  )
}

export default NotFoundPage
