const Archive = ({ styles }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.91083 2.74408C1.66675 2.98816 1.66675 3.38099 1.66675 4.16667C1.66675 4.95234 1.66675 5.34518 1.91083 5.58926C2.1549 5.83333 2.54774 5.83333 3.33342 5.83333H16.6667C17.4524 5.83333 17.8453 5.83333 18.0893 5.58926C18.3334 5.34518 18.3334 4.95234 18.3334 4.16667C18.3334 3.38099 18.3334 2.98816 18.0893 2.74408C17.8453 2.5 17.4524 2.5 16.6667 2.5H3.33341C2.54774 2.5 2.1549 2.5 1.91083 2.74408Z"
        fill="#1414FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7242 7.0836C16.8418 7.08365 16.9627 7.0837 17.0834 7.08203V10.8336C17.0834 13.9763 17.0834 15.5476 16.1071 16.5239C15.1308 17.5002 13.5594 17.5002 10.4167 17.5002H9.58341C6.44072 17.5002 4.86937 17.5002 3.89306 16.5239C2.91675 15.5476 2.91675 13.9763 2.91675 10.8336V7.08203C3.03748 7.0837 3.1584 7.08365 3.27603 7.0836H16.7242ZM7.50008 10.0002C7.50008 9.61194 7.50008 9.4178 7.56352 9.26466C7.64809 9.06047 7.81032 8.89824 8.01451 8.81366C8.16765 8.75023 8.3618 8.75023 8.75008 8.75023H11.2501C11.6384 8.75023 11.8325 8.75023 11.9857 8.81366C12.1898 8.89824 12.3521 9.06047 12.4366 9.26466C12.5001 9.4178 12.5001 9.61194 12.5001 10.0002C12.5001 10.3885 12.5001 10.5827 12.4366 10.7358C12.3521 10.94 12.1898 11.1022 11.9857 11.1868C11.8325 11.2502 11.6384 11.2502 11.2501 11.2502H8.75008C8.3618 11.2502 8.16765 11.2502 8.01451 11.1868C7.81032 11.1022 7.64809 10.94 7.56352 10.7358C7.50008 10.5827 7.50008 10.3885 7.50008 10.0002Z"
        fill="#1414FF"
      />
    </svg>
  )
}

export default Archive
