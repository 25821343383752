import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, Navigate } from 'react-router-dom'

import Button from 'components/common/Button/Button'
import PageSubtitle from 'components/common/PageSubtitle/PageSubtitle'
import PageTitle from 'components/common/PageTitle/PageTitle'
import Card from 'components/common/Card/Card'
import DocumentText from 'components/assets/icons/DocumentText'
import Arrow from 'components/assets/icons/Arrow'
import style from './DocumentForm.module.scss'
import api from 'helpers/api'
import Close from 'components/assets/icons/Close'
import Collapsible from 'components/common/Collapsible/Collapsible'
import CardLine from 'components/common/CardLine/CardLine'
import dayjs from 'dayjs'
import Thundercloud from 'components/assets/icons/Thundercloud'
import Download from 'components/assets/icons/Download';
import InfoMessage from 'components/common/InfoMessage/InfoMessage'
import ExclamationTriangle from 'components/assets/icons/ExclamationTriangle'

const DocumentForm = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { state } = useLocation()
  const inputRef = useRef([])
  const [fileList, setFileList] = useState([])
  const isVideoSelected = fileList.find( doc => doc?.type.startsWith('video/') )
  const [error, setError] = useState(null)
  const { claim, contract, claimNature } = state || {}


  const displayError = (msg) => {
    window.scrollTo(0, 0)
    setError(msg)
    setTimeout(() => setError(null), 5000)
  }


  const submit = async (e) => {
    e.preventDefault()
    try {
      const formData = new FormData()
      fileList.forEach( file => {
        if (file){
          const mixedName = JSON.stringify({
            name: file.name,
            proofOfClaimsId: file.proofOfClaimsId
          })
          formData.append('files', file, mixedName)
        }
      })

      const finalData = {
        ...claim,
        adhesionId: contract._id,
        nature: claim.nature.value
      }

      for (let key in finalData) {
        formData.append(key, finalData[key])
      }

      const res = await api.post({
        url: '/claims',
        contentType: 'multipart/form-data',
        data: formData,
      })

      navigate(`/claims/${res.response.id}` )
    } catch (error) {
      console.log('Create claim error')
      displayError(t('common:form.genericError'))
    }
  }

  const handleFileChange = (event, index) => {
    if (!event.target.files) {
      return
    }

    const item = event.target.files[0]

    if (item.size > process.env.REACT_APP_FILESIZE_LIMIT){
      displayError(t('claim:item.fileTooLarge'))
    }  else if (item.type.startsWith('video/') && isVideoSelected){
      displayError(t('claim:item.limitVideoQuantity'))
    }  else if (!item.type.match(/image|pdf|video/i)) {
      displayError(t('claim:item.fileFormatForbidden'))
    } else {
      const newFileList = [...fileList]
      item.proofOfClaimsId = claim.nature.proofOfClaims[index]._id
      newFileList[index] = item
      setFileList(newFileList)
    }

  }

  const handleUploadClick = idx => {
    if (inputRef.current[idx]) {
      inputRef.current[idx].click()
    }
  }

  const removeFile = index => {
    const newFileList = [...fileList]
    newFileList.splice(index, 1, null)
    setFileList(newFileList)
  }

  const resizeFileName = fileName => {
    const limit = process.env.REACT_APP_FILENAME_LIMIT
    const [name, ext] = fileName.split(/\.(?=[a-z0-9]+$)/i)
    const shorterName = name.length > limit ? name.slice(0, limit)+'(...)' : name
    return `${shorterName}.${ext}`
  }


  return !contract || !claim
  ? <Navigate to={'/claims/new'} replace />
  : (
    <>
      <PageTitle text={t('claim:applySteps.documents.title')} />
      <PageSubtitle text={t('claim:applySteps.documents.subtitle')} />

      {error &&
        <InfoMessage
          className={style.infoMessage}
          type='error'
          label={error}
          prefix={<ExclamationTriangle />}
        />
      }

      <Card
        className={style.contract}
        inactive={contract.cancelation}
        content={contract.rateType.dashboardPackageDescription || contract.contractType.dashboardDescription}
        onClick={() =>
          navigate(`/contracts/${contract._id}`, {
            state: { fromClaim: true, step: 4 },
          })
        }
        title={
          contract.rateType?.dashboardPackageName 
          ? `${contract.rateType.dashboardPackageName} ${contract.idTransaction}` 
          : `${contract.contractType.dashboardName} ${contract.idTransaction}`
        }
        titleIcon={<DocumentText />}
        cornerContent={<Arrow />}
      />

      <Collapsible
        title={
          <>
            <Thundercloud styles={{ fill: '#1414FF' }} />
            <div className={style['collapsible-title']}>{t('claim:claim')}</div>
          </>
        }
        isOpenedProp={true}
        content={
          <>
            <div className={style['summary-first-line']}>
              <CardLine
                className={style['first-line-element']}
                title={t('claim:item.date')}
                content={dayjs(claim.createdAt).format('DD/MM/YYYY')}
              />
              <CardLine
                className={style['first-line-element']}
                title={t('claim:item.location')}
                content={claim.location}
              />
              <CardLine
                className={style['first-line-element']}
                title={t('claim:item.nature')}
                content={claim.nature.label}
              />
            </div>
            <CardLine
              title={t('claim:item.circumstances')}
              content={claim.circumstance}
            />

            {!!(claimNature.proofOfClaims.length > 0)  &&
	   //{!!(claimNature.proofOfClaims.length > 0) &&
              <CardLine
                className={style['required-documents']}
                title={<>
                  {t('claim:applySteps.documents.requiredDocuments')}
                  <br/>
                  {t('claim:applySteps.documents.contact')}
                </>}
                content={
                  <ul>
                    {claimNature.proofOfClaims.map( (doc, index) => {
                      const file = fileList[index]
                      return (
                        <li key={`document-${index}`}>
                          <legend>
                            {doc.name}
                          </legend>
                          {!file
                            ? <div onClick={() => handleUploadClick(index)}>
                              <Download />
                              <span>{t('common:upload')}</span>
                              <input
                                type="file"
                                accept={'image/*, .pdf' + (!isVideoSelected ? ', video/*' : '')}
                                ref={el => (inputRef.current[index] = el)}
                                onChange={e => handleFileChange(e, index)}
                                hidden
                              />
                            </div>
                            : <div onClick={() => removeFile(index)}>
                              <Close />
                              <span>{resizeFileName(file.name)}</span>
                            </div>
                          }
                        </li>
                      )
                    })}
                  </ul>
                }
              />
            }
          </>
        }
      />

      <form className={style.form}>
        <Button
          className={style.button}
          label={t('common:submit')}
          onClick={(e)=>submit(e)}
          type="primary"
        />
      </form>
    </>
  )
}

export default DocumentForm
