import classNames from 'classnames'
import ReactSelect from 'react-select'
import AsyncSelect from 'react-select/async'
import style from './Select.module.scss'
import { useLocation } from 'react-router-dom'

const Select = ({ async, className, error, label, styles = {}, value, ...props }) => {
  const { state } = useLocation()
  const selectStyles = {
    control: baseStyles => ({
      ...baseStyles,
      background: 'transparent',
      minHeight: 40,
      border: error ? '1px solid #BD454A' : styles.darkBg ? '1px solid #fff' : '1px solid #00007A',
      borderRadius: 8,
      outline: 'none',
      '&:hover': {
        border: styles.darkBg ? '1px solid #fff' : '1px solid #00007A',
      },
      '&:active': {
        outline: 'none',
      },
      ...styles.control,
    }),
    dropdownIndicator: baseStyles => ({
      ...baseStyles,
      padding: '8px 12px 8px 8px',
      ...styles.dropdownIndicator,
    }),
    indicatorSeparator: baseStyles => ({ ...baseStyles, display: 'none' }),
    menu: baseStyles => ({
      ...baseStyles,
      boxShadow: '8px 12px 36px rgba(0, 0, 147, 0.05)',
      marginTop: 4,
      ...styles.menu,
    }),
    menuList: baseStyles => ({
      ...baseStyles,
      padding: 8,
      borderRadius: 12,
      border: 'none',
      ...styles.menuList,
    }),
    option: (baseStyles, { isSelected }) => ({
      ...baseStyles,
      padding: '8px 12px',
      borderRadius: 12,
      fontWeight: isSelected ? 400 : 300,
      fontSize: 16,
      lineHeight: '28px',
      color: '#00007A',
      backgroundColor: isSelected && '#F5F5FF',
      '&:hover': {
        backgroundColor: '#F5F5FF',
      },
      ...styles.option,
    }),
    placeholder: baseStyles => ({
      ...baseStyles,
      fontWeight: 300,
      fontSize: 16,
      lineHeight: '28px',
      color: styles.darkBg ? '#fff' : '#d8d8ff',
      ...styles.placeholder,
    }),
    singleValue: baseStyles => ({
      ...baseStyles,
      borderRadius: 12,
      fontWeight: 300,
      fontSize: 16,
      lineHeight: '28px',
      color: styles.darkBg ? '#fff' : '#00007A',
      ...styles.singleValue,
    }),
    valueContainer: baseStyles => ({ ...baseStyles, padding: '2px 0 2px 8px', ...styles.valueContainer }),
  }

  return (
    <div className={classNames(style['input-wrapper'], className)}>
      <div className="input-label">{label}</div>
      {async ? (
        <AsyncSelect
          className={style.select}
          components={{ DropdownIndicator: null }}
          styles={selectStyles}
          value={value ? { label: value, value: value } : null}
          {...props}
          noOptionsMessage={() =>
            state?.client?.address ? "Veuillez mettre à jour votre adresse" : "Quelle est votre adresse ?"
          }
        />
      ) : (
        <ReactSelect
          className={style.select}
          components={{ DropdownIndicator: null }}
          styles={selectStyles}
          value={value ? { label: value, value: value } : null}
          {...props}
        />
      )}
    </div>
  );
}

export default Select;
