const SignOutIcon = ({ styles }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4376 9.99967C13.4376 9.6545 13.1578 9.37467 12.8126 9.37467L3.66878 9.37467L5.30266 7.97421C5.56474 7.74957 5.59509 7.35501 5.37045 7.09293C5.14581 6.83085 4.75125 6.8005 4.48917 7.02514L1.5725 9.52514C1.43397 9.64388 1.35425 9.81722 1.35425 9.99967C1.35425 10.1821 1.43397 10.3555 1.5725 10.4742L4.48917 12.9742C4.75125 13.1988 5.14581 13.1685 5.37045 12.9064C5.59509 12.6443 5.56474 12.2498 5.30266 12.0251L3.66878 10.6247L12.8126 10.6247C13.1578 10.6247 13.4376 10.3449 13.4376 9.99967Z"
        fill="white"
      />
      <path
        d="M7.81258 6.66634C7.81258 7.2515 7.81258 7.54408 7.95301 7.75426C8.01381 7.84527 8.09195 7.92341 8.18296 7.98421C8.39314 8.12464 8.68572 8.12464 9.27088 8.12464L12.8126 8.12464C13.8481 8.12464 14.6876 8.96411 14.6876 9.99964C14.6876 11.0352 13.8481 11.8746 12.8126 11.8746L9.27095 11.8746C8.68572 11.8746 8.39311 11.8746 8.18292 12.0151C8.09194 12.0759 8.01383 12.154 7.95304 12.245C7.81258 12.4552 7.81258 12.7478 7.81258 13.333C7.81258 15.69 7.81258 16.8685 8.54481 17.6008C9.27705 18.333 10.4554 18.333 12.8124 18.333L13.6457 18.333C16.0028 18.333 17.1813 18.333 17.9135 17.6008C18.6457 16.8685 18.6457 15.69 18.6457 13.333L18.6457 6.66634C18.6457 4.30933 18.6457 3.1308 17.9135 2.39857C17.1813 1.66634 16.0028 1.66634 13.6457 1.66634L12.8124 1.66634C10.4554 1.66634 9.27704 1.66634 8.54481 2.39857C7.81258 3.13081 7.81258 4.30932 7.81258 6.66634Z"
        fill="white"
      />
    </svg>
  )
}

export default SignOutIcon
