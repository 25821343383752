const QuestionCircle = ({ className, styles }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3337 10.0003C18.3337 14.6027 14.6027 18.3337 10.0003 18.3337C5.39795 18.3337 1.66699 14.6027 1.66699 10.0003C1.66699 5.39795 5.39795 1.66699 10.0003 1.66699C14.6027 1.66699 18.3337 5.39795 18.3337 10.0003ZM10.0003 6.45866C9.48256 6.45866 9.06283 6.87839 9.06283 7.39616C9.06283 7.74134 8.783 8.02116 8.43783 8.02116C8.09265 8.02116 7.81283 7.74134 7.81283 7.39616C7.81283 6.18804 8.7922 5.20866 10.0003 5.20866C11.2084 5.20866 12.1878 6.18804 12.1878 7.39616C12.1878 7.98852 11.9515 8.52698 11.5695 8.92029C11.4926 8.99944 11.4192 9.07272 11.3493 9.14263C11.1694 9.32233 11.0118 9.47977 10.8736 9.6574C10.6911 9.89193 10.6253 10.0643 10.6253 10.2087V10.8337C10.6253 11.1788 10.3455 11.4587 10.0003 11.4587C9.65515 11.4587 9.37533 11.1788 9.37533 10.8337V10.2087C9.37533 9.66267 9.6295 9.22069 9.88713 8.88967C10.0778 8.64474 10.3174 8.40561 10.5118 8.21155C10.5705 8.153 10.625 8.09856 10.6729 8.0493C10.8375 7.87985 10.9378 7.65036 10.9378 7.39616C10.9378 6.87839 10.5181 6.45866 10.0003 6.45866ZM10.0003 14.167C10.4606 14.167 10.8337 13.7939 10.8337 13.3337C10.8337 12.8734 10.4606 12.5003 10.0003 12.5003C9.54009 12.5003 9.16699 12.8734 9.16699 13.3337C9.16699 13.7939 9.54009 14.167 10.0003 14.167Z"
        fill="#0000C9"
        {...styles}
      />
    </svg>
  )
}

export default QuestionCircle
