const PaymentCard = ({ styles }) => {
  return (
    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6666 13.6663H7.33329C4.1906 13.6663 2.61925 13.6663 1.64294 12.69C0.666626 11.7137 0.666626 10.1424 0.666626 6.99967C0.666626 6.6314 0.666626 6.2847 0.668197 5.95801H17.3317C17.3333 6.2847 17.3333 6.6314 17.3333 6.99967C17.3333 10.1424 17.3333 11.7137 16.357 12.69C15.3807 13.6663 13.8093 13.6663 10.6666 13.6663ZM3.37496 10.333C3.37496 9.98783 3.65478 9.70801 3.99996 9.70801H7.33329C7.67847 9.70801 7.95829 9.98783 7.95829 10.333C7.95829 10.6782 7.67847 10.958 7.33329 10.958H3.99996C3.65478 10.958 3.37496 10.6782 3.37496 10.333ZM8.79163 10.333C8.79163 9.98783 9.07145 9.70801 9.41663 9.70801H10.6666C11.0118 9.70801 11.2916 9.98783 11.2916 10.333C11.2916 10.6782 11.0118 10.958 10.6666 10.958H9.41663C9.07145 10.958 8.79163 10.6782 8.79163 10.333Z"
        fill="#1414FF"
      />
      <path
        d="M7.33329 0.333008H10.6666C13.8093 0.333008 15.3807 0.333008 16.357 1.30932C17.0599 2.01227 17.2568 3.02369 17.3119 4.70801H0.688056C0.743165 3.02369 0.939986 2.01227 1.64294 1.30932C2.61925 0.333008 4.1906 0.333008 7.33329 0.333008Z"
        fill="#1414FF"
      />
    </svg>
  )
}

export default PaymentCard
