import { useState } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import classNames from 'classnames'

import style from './ContactSummary.module.scss'
import PageTitle from 'components/common/PageTitle/PageTitle'
import PageSubtitle from 'components/common/PageSubtitle/PageSubtitle'
import InfoMessage from 'components/common/InfoMessage/InfoMessage'
import ShieldLogo from 'components/assets/icons/ShieldLogo'
import Button from 'components/common/Button/Button'
import Card from 'components/common/Card/Card'
import CardLine from 'components/common/CardLine/CardLine'
import PaymentCard from 'components/assets/icons/PaymentCard'
import LockKeyhole from 'components/assets/icons/LockKeyhole'
import Link from 'components/common/Link/Link'
import ExclamationTriangle from 'components/assets/icons/ExclamationTriangle'
import { requiredClientContactFields } from 'helpers/form'

const ContactSummary = () => {
  const client = useOutletContext()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [isError, setIsError] = useState(false)

  const getFieldLabel = value =>
    value || <Link label={t('main:clientContacts.summary.form.missingField')} to="edit" type="error" />

  if (!client) return null

  const { firstname, lastname, birthdate, placeOfBirth, address, additionalAddress, zipcode, city, email, phone } =
    client

  const emptyFields = []
  for (const field of requiredClientContactFields) {
    if (!client[field]) emptyFields.push(field)
  }
  if (!isError && emptyFields.length) {
    setIsError(true)
  } else if (isError && !emptyFields.length) {
    setIsError(false)
  }

  return (
    <div className={style['contact-summary']}>
      <PageTitle text={t('main:clientContacts.summary.title')} />
      <PageSubtitle text={t('main:clientContacts.summary.subtitle')} />
      <InfoMessage
        className={style['info-message']}
        label={
          isError
            ? t('main:clientContacts.summary.infoMessage.incompleteData.text')
            : t('main:clientContacts.summary.infoMessage.default.text')
        }
        prefix={isError ? <ExclamationTriangle /> : <ShieldLogo />}
        suffix={
          <Button
            className={style['edit-button']}
            label={t('main:clientContacts.summary.infoMessage.default.button')}
            onClick={() => navigate('edit')}
            type="primary"
          />
        }
        type={isError && 'error'}
      />

      <div className={style.cards}>
        <Card
          className={classNames(style.card, style['main-card'])}
          content={
            <>
              <CardLine
                title={t('common:form.birthdate.label')}
                content={getFieldLabel(dayjs(birthdate).format('DD/MM/YYYY'))}
              />
              <CardLine title={t('common:form.placeOfBirth.label')} content={getFieldLabel(placeOfBirth)} />
              <CardLine
                title={t('common:form.address.label')}
                //content={`${address}, ${additionalAddress}${additionalAddress && ','} ${zipcode} ${city}`}
                content={`${address}, ${zipcode} ${city}`}
              />
              <CardLine title={t('common:form.email.label')} content={getFieldLabel(email)} />
              <CardLine title={t('common:form.phone.label')} content={getFieldLabel(phone)} />
            </>
          }
          cornerContent={<Link label={t('common:edit')} state={{ client }} to="edit" />}
          title={
            <span className={style.name}>
              {firstname} {lastname}
            </span>
          }
          type={isError && 'error'}
        />

        <div className={style['secondary-cards']}>
          <Card
            className={style.card}
            content={<p>•••••••••••••</p>}
            cornerContent={<Link label={t('common:edit')} state={{ client }} to="edit/password" />}
            title={t('common:contact.password')}
            titleIcon={<LockKeyhole />}
          />
          <Card
            className={classNames(style.card)}
            content={<CardLine content={`${t('common:paymentMethod.card')} XXXX XXXX XXXX XXXX - Exp. XX/XX`} />}
            cornerContent={<Link label={t('common:edit')} state={{ client }} to="" />}
            disabled
            title={t('common:paymentMethod.paymentMethod')}
            titleIcon={<PaymentCard />}
          />
        </div>
      </div>
    </div>
  )
}

export default ContactSummary
