const Arrow = ({ className, styles }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7152 4.16686C10.982 3.92672 11.393 3.94835 11.6331 4.21518L16.1331 9.21518C16.3556 9.46238 16.3556 9.83763 16.1331 10.0848L11.6331 15.0848C11.393 15.3517 10.982 15.3733 10.7152 15.1331C10.4483 14.893 10.4267 14.482 10.6669 14.2152L14.1905 10.3H3.65C3.29101 10.3 3 10.009 3 9.65001C3 9.29102 3.29101 9.00001 3.65 9.00001H14.1905L10.6669 5.08483C10.4267 4.818 10.4483 4.40701 10.7152 4.16686Z"
        fill="#1414FF"
        {...styles}
      />
    </svg>
  )
}

export default Arrow
