import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import style from './ContractSelection.module.scss'
import DocumentText from 'components/assets/icons/DocumentText'
import Card from 'components/common/Card/Card'
import ShieldLogo from 'components/assets/icons/ShieldLogo'
import InfoMessage from 'components/common/InfoMessage/InfoMessage'
import PageTitle from 'components/common/PageTitle/PageTitle'
import PageSubtitle from 'components/common/PageSubtitle/PageSubtitle'
import { sortContractsByStatus } from 'helpers/contract'
import api from 'helpers/api'

const ContractSelection = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [contracts, setContracts] = useState([])

  useEffect(() => {
    const getContracts = async () => {
      try {
        const res = await api.get({ url: '/adhesions/client' })

        setContracts(sortContractsByStatus(res.adhesions.filter(item => !item.cancelation)))
      } catch (error) {
        console.log('Fetch contracts error')
      }
    }
    getContracts()
  }, [])

  const isContractListEmpty = !contracts || !contracts?.length

  return (
    <>
      <PageTitle text={t('claim:applySteps.contract.title')} />
      <PageSubtitle text={t('claim:applySteps.contract.subtitle')} />
      {isContractListEmpty && (
        <InfoMessage
          className={style['info-message']}
          label={t('claim:applySteps.contract.empty')}
          prefix={<ShieldLogo />}
        />
      )}

      <div className={classNames(style.items, isContractListEmpty && style.empty)}>
        {!isContractListEmpty &&
          contracts.map((item, index) => {
            return (
              <Card
                className={style.item}
                inactive={item.cancelation}
                content={item.rateType.dashboardPackageDescription || item.contractType.dashboardDescription}
                key={`contract-item-${index}`}
                title={
                  item.rateType?.dashboardPackageName 
                  ? `${item.rateType.dashboardPackageName} ${item.idTransaction}` 
                  : `${item.contractType.dashboardName} ${item.idTransaction}`
                }
                titleIcon={<DocumentText />}
                onClick={() => navigate('insured-person', { state: { contract: item, step: 0, fromClaim: true } })}
              />
            )
          })}
      </div>
    </>
  )
}

export default ContractSelection
