const Envelope = ({ styles }) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.2286 6.89608C2.6665 8.45818 2.6665 10.9723 2.6665 16.0007C2.6665 21.029 2.6665 23.5431 4.2286 25.1052C5.7907 26.6673 8.30485 26.6673 13.3332 26.6673H18.6665C23.6948 26.6673 26.209 26.6673 27.7711 25.1052C29.3332 23.5431 29.3332 21.029 29.3332 16.0007C29.3332 10.9723 29.3332 8.45818 27.7711 6.89608C26.209 5.33398 23.6948 5.33398 18.6665 5.33398H13.3332C8.30486 5.33398 5.7907 5.33398 4.2286 6.89608ZM24.7681 10.0271C25.1216 10.4514 25.0643 11.082 24.64 11.4355L21.7114 13.8761C20.5296 14.861 19.5717 15.6592 18.7263 16.2029C17.8456 16.7694 16.9879 17.1272 15.9998 17.1272C15.0117 17.1272 14.1541 16.7694 13.2734 16.2029C12.428 15.6592 11.4701 14.861 10.2883 13.8761L7.35965 11.4355C6.93538 11.082 6.87805 10.4514 7.23162 10.0271C7.58518 9.60286 8.21574 9.54553 8.64002 9.8991L11.5185 12.2979C12.7625 13.3345 13.6261 14.0519 14.3553 14.5208C15.0611 14.9748 15.5397 15.1272 15.9998 15.1272C16.4599 15.1272 16.9386 14.9748 17.6444 14.5208C18.3735 14.0519 19.2372 13.3345 20.4811 12.2979L23.3597 9.8991C23.7839 9.54553 24.4145 9.60286 24.7681 10.0271Z"
        fill="#8686FF"
        {...styles}
      />
    </svg>
  )
}

export default Envelope
