const DocumentEdit = ({ styles }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.7657 13.7508C13.9116 13.637 14.0438 13.5047 14.3084 13.2402L17.6062 9.94238C17.6859 9.86265 17.6495 9.72533 17.5429 9.68837C17.1537 9.55331 16.6473 9.29976 16.1736 8.82608C15.6999 8.3524 15.4464 7.84602 15.3113 7.45675C15.2744 7.35022 15.137 7.31373 15.0573 7.39346L11.7595 10.6913L11.7595 10.6913C11.4949 10.9558 11.3627 11.0881 11.2489 11.2339C11.1147 11.406 10.9997 11.5921 10.9058 11.7891C10.8263 11.956 10.7671 12.1335 10.6488 12.4884L10.4959 12.947L10.2529 13.6762L10.025 14.3599C9.96674 14.5346 10.0122 14.7271 10.1424 14.8573C10.2726 14.9875 10.4651 15.0329 10.6398 14.9747L11.3235 14.7468L12.0527 14.5037L12.5113 14.3509L12.5113 14.3509C12.8662 14.2326 13.0437 14.1734 13.2106 14.0939C13.4076 14 13.5937 13.8849 13.7657 13.7508Z"
        fill="#1414FF"
      />
      <path
        d="M18.6388 8.90983C19.3426 8.20596 19.3426 7.06477 18.6388 6.36091C17.9349 5.65704 16.7937 5.65704 16.0898 6.36091L15.9838 6.46693C15.8815 6.56924 15.8352 6.71214 15.8607 6.85456C15.8768 6.94414 15.9066 7.07512 15.9608 7.2314C16.0693 7.54396 16.274 7.95425 16.6597 8.33994C17.0454 8.72563 17.4557 8.93041 17.7683 9.03885C17.9246 9.09307 18.0555 9.12287 18.1451 9.13895C18.2875 9.16451 18.4304 9.11816 18.5327 9.01585L18.6388 8.90983Z"
        fill="#1414FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.47631 2.6433C2.5 3.61961 2.5 5.19096 2.5 8.33366V11.667C2.5 14.8097 2.5 16.381 3.47631 17.3573C4.45262 18.3337 6.02397 18.3337 9.16667 18.3337H10.8333C13.976 18.3337 15.5474 18.3337 16.5237 17.3573C17.4843 16.3967 17.4997 14.86 17.5 11.817L15.1514 14.1656C14.9266 14.3906 14.7425 14.5749 14.5346 14.7371C14.2909 14.9271 14.0273 15.09 13.7484 15.2229C13.5104 15.3364 13.2632 15.4186 12.9615 15.5191L11.0351 16.1612C10.4113 16.3692 9.7235 16.2068 9.25852 15.7418C8.79355 15.2769 8.6312 14.5891 8.83914 13.9653L9.06705 13.2815L9.46298 12.0938L9.48124 12.0389C9.58171 11.7371 9.66399 11.49 9.77745 11.2519C9.91037 10.973 10.0733 10.7094 10.2633 10.4658C10.4255 10.2579 10.6098 10.0737 10.8347 9.84891L14.1734 6.51023L15.1 5.5837L15.206 5.47767C15.8022 4.8815 16.5836 4.5835 17.365 4.58366C17.2389 3.72487 16.9951 3.11472 16.5237 2.6433C15.5474 1.66699 13.976 1.66699 10.8333 1.66699H9.16667C6.02397 1.66699 4.45262 1.66699 3.47631 2.6433ZM6.04167 7.50033C6.04167 7.15515 6.32149 6.87533 6.66667 6.87533H12.0833C12.4285 6.87533 12.7083 7.15515 12.7083 7.50033C12.7083 7.8455 12.4285 8.12533 12.0833 8.12533H6.66667C6.32149 8.12533 6.04167 7.8455 6.04167 7.50033ZM6.04167 10.8337C6.04167 10.4885 6.32149 10.2087 6.66667 10.2087H8.75C9.09518 10.2087 9.375 10.4885 9.375 10.8337C9.375 11.1788 9.09518 11.4587 8.75 11.4587H6.66667C6.32149 11.4587 6.04167 11.1788 6.04167 10.8337ZM6.04167 14.167C6.04167 13.8218 6.32149 13.542 6.66667 13.542H7.91667C8.26184 13.542 8.54167 13.8218 8.54167 14.167C8.54167 14.5122 8.26184 14.792 7.91667 14.792H6.66667C6.32149 14.792 6.04167 14.5122 6.04167 14.167Z"
        fill="#1414FF"
      />
    </svg>
  )
}

export default DocumentEdit
