import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Button from 'components/common/Button/Button'
import Input from 'components/common/Input/Input'
import Link from 'components/common/Link/Link'
import Eye from 'components/assets/icons/Eye'
import ResponseMessage from 'components/common/ResponseMessage/ResponseMessage'
import { passwordValidation } from 'constants/inputValidation'
import PrefixedText from 'components/common/PrefixedText/PrefixedText'
import ValidationIcon from 'components/common/ValidationIcon/ValidationIcon'
import api from 'helpers/api'
import Header from 'components/Header/Header'
import { parseToken } from 'helpers/auth'
import style from 'styles/auth.module.scss'

const SignUp = () => {
  const [isPasswordShown, setIsPasswordShown] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  const { t } = useTranslation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const token = searchParams.get('token')
  const data = parseToken(token)

  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields, touchedFields, isValid },
  } = useForm({
    criteriaMode: 'all',
    delayError: 500,
    defaultValues: {
      email: data?.email,
      password: '',
    },
    mode: 'onTouched',
  })

  const togglePasswordVisibility = () => {
    setIsPasswordShown(!isPasswordShown)
  }

  const onSubmit = handleSubmit(async data => {
    try {
      const res = await api.post({
        url: '/auth/clients/signup',
        data: { email: data.email, password: data.password },
        headers: { Authorization: `Bearer ${token}` },
      })

      if (res.success && res.response.accessToken) {
        localStorage.setItem('accessToken', res.response.accessToken)
        navigate('/contracts')
      } else {
        setErrorMessage(t('main:errorMessages.general'))
      }
    } catch (error) {
      if (error.response?.data?.status === 400 || error.response?.data?.status === 401) {
        setErrorMessage(t('main:auth.signUp.emailError'))
      } else {
        setErrorMessage(t('main:errorMessages.general'))
      }
    }
  })

  return (
    <div className={style['auth']}>
      <Header darkBg styles={{ position: 'absolute', top: 0 }} />
      <h2 className={style.title}>{t('main:auth.signUp.title')}</h2>
      {errorMessage && <ResponseMessage className={style['response-message']} label={errorMessage} />}

      <form onSubmit={onSubmit}>
        <div className={style.inputs}>
          <div className={style.input}>
            <Input
              register={register('email', { required: true })}
              darkBg
              label="Email"
              placeholder={`${t('common:your')} ${t('common:form.email.placeholder')}`}
              type="email"
            />
          </div>
          <div className={style.input}>
            <Input
              register={register('password', passwordValidation)}
              darkBg
              label={t('main:auth.password.password')}
              placeholder={t('main:auth.password.placeholder')}
              type={isPasswordShown ? 'text' : 'password'}
              suffix={<Eye className="clickable" />}
              onSuffixClick={togglePasswordVisibility}
            />
            {touchedFields.password && (
              <div className={style['input-error']}>
                <p>{t('main:auth.password.required')}</p>
                <PrefixedText
                  className={style['input-error-prefix']}
                  prefix={
                    <ValidationIcon
                      errors={errors}
                      dirtyFields={dirtyFields}
                      field="password"
                      validationType="minLength"
                    />
                  }
                  text={t('main:auth.password.length')}
                />
                <PrefixedText
                  className={style['input-error-prefix']}
                  prefix={
                    <ValidationIcon
                      errors={errors}
                      dirtyFields={dirtyFields}
                      field="password"
                      validationType="pattern"
                    />
                  }
                  text={t('main:auth.password.pattern')}
                />
              </div>
            )}
          </div>
        </div>

        <Button label={t('common:save')} onClick={handleSubmit} type="primary" disabled={!isValid} />
      </form>

      <Link className={style.link} label={t('main:auth.signUp.alreadyHaveAccount')} to={'/sign-in'} darkBg />
    </div>
  )
}

export default SignUp
