const DropArrow = ({ styles }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.6665 7.67056L10.3084 13.196C10.1308 13.3791 9.86871 13.3791 9.69109 13.196L4.33301 7.67056"
        stroke="#1414FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default DropArrow
