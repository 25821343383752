import { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import Button from 'components/common/Button/Button';
import PageSubtitle from 'components/common/PageSubtitle/PageSubtitle';
import PageTitle from 'components/common/PageTitle/PageTitle';
import Textarea from 'components/common/Textarea/Textarea';
import Select from 'components/common/Select/Select';
import Modal from 'components/common/Modal/Modal';
import style from './ContactUs.module.scss';
import api from 'helpers/api';
import InfoMessage from 'components/common/InfoMessage/InfoMessage';
import Message from 'components/assets/icons/Message';
import Close from 'components/assets/icons/Close';
import { isMobile } from 'helpers/styles';

const ContactUs = () => {
  const { t } = useTranslation();
  const constantsSubjects = [{label: t('main:contactUs.other'), value: t('main:contactUs.other')}]

  const [adhesions, setAdhesions] = useState([]);
  const [isSuccessMessageShown, setIsSuccessMessageShown] = useState(false);
  const [subjects, setSubjects] = useState(constantsSubjects);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [closestTerminationDate, setClosestTerminationDate] = useState('');
  const [formData, setFormData] = useState(null);
  const [selectedAdhesion, setSelectedAdhesion] = useState(null);


  const timerRef = useRef(null);

  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    reset,
    resetField,
    getValues,
    trigger
  } = useForm({
    criteriaMode: 'all',
    delayError: 500,
    defaultValues: {
      adhesionId: '',
      subject: '',
      text: '',
    },
    mode: 'onTouched',
  });


  useEffect(() => {
    const getAdhesions = async () => {
      try {
        const res = await api.get({ url: '/adhesions/client' });
        if (res?.adhesions){
          setAdhesions(res.adhesions)
        }
      } catch (error) {
        console.log('Fetch adhesions error');
      }
    };
    getAdhesions();

    const timerId = timerRef.current;
    return () => clearTimeout(timerId);
  }, []);

  useEffect(() => {
    const getAdhesions = async () => {
        try {
            const res = await api.get({ url: '/adhesions/client' });
            if (res?.adhesions) {
                setAdhesions(res.adhesions);
                console.log("Adhesions récupérées :", res.adhesions);
            }
        } catch (error) {
            console.log('Fetch adhesions error', error);
        }
    };
    getAdhesions();
}, [])

  const adhesionsOptions = [
    ...adhesions.map(item => ({
      label: item.contractType?.dashboardName,
      value: item.contractType?.dashboardName,
      adhesionId: item._id,
      date: item.closestTerminationDate,
      contractId: item.contractType._id
    })),
    {
      label: t('main:contactUs.other'),
      value: 'other'
    }
  ]


  // Submit form
  const onSubmit = handleSubmit(async data => {
    if (data.subject === t('main:contactUs.termination')) {
      console.log("FormData avant ouverture du modal :", data);
      setIsModalOpen(true)
      console.log(closestTerminationDate)
      setFormData({ ...data, closestTerminationDate })
    } else {
      await handleModalConfirm(data)
    }
  });

  const handleModalConfirm = async (data) => {
    try {
      
      const selectedOption = adhesionsOptions.find(option => option.value === data.adhesionId);
    
      if (!selectedOption) {
        throw new Error('No matching option found for adhesionId.');
      }
  
      const payload = {
        ...data,
        adhesionId: selectedOption.adhesionId,
      };
  
      delete payload.value;
    
      await api.post({
        url: '/clients/contact_us',
        data: payload,
      });
  
      reset();
      setClosestTerminationDate('');
      setIsSuccessMessageShown(true);
    } catch (error) {
      console.error('Submit message error:', error);
    }
    setIsModalOpen(false);
  };
  
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };


  // Select contract
  const selectContract = async ({option, onChange}) => {
    const { value, date, contractId, adhesionId } = option
    console.log("DATE AU DEBUT", date)
    setClosestTerminationDate(date)
    onChange(value)
    resetField('subject')

    // Trouver l'adhésion correspondante
    const selected = adhesions.find(a => a._id === adhesionId);
    console.log("le contrat select : ",selected._id, selected.firstname, selected.lastname)
    if (selected) {
        setSelectedAdhesion(selected);
    }

    // Get and set possibles subjects
    const res = contractId ? await api.get({ url: '/contract-type/'+contractId+'/contactSubjects' }) : []
    const subjects = [...res.map( subject => {
      return {
        label: subject.subject,
        value: subject.subject
      }
    }), ...constantsSubjects]
    console.log("DATE A LA FIN : ",date)
    if (date && !isNaN(new Date(date).getTime())){
      subjects.splice(subjects.length-1, 0, { label: t('main:contactUs.termination'), value: t('main:contactUs.termination') })
    }

    setSubjects([...subjects, ])
  }


  return (
    <div className={style['contact-us']}>
      <PageTitle text={t('main:contactUs.title')} />
      <PageSubtitle text={t('main:contactUs.subtitle')} />

      {isSuccessMessageShown &&
        <InfoMessage
          className={style['success-message']}
          type="success"
          label={t('main:contactUs.successMessage')}
          prefix={<Message />}
          suffix={
            <div className={style.close} onClick={() => setIsSuccessMessageShown(false)}>
              <Close styles={{ fill: '#126F0E' }} />
            </div>
          }
        />
      }

      <form className={style.form} onSubmit={onSubmit}>
        <div className={style.inputs}>
          <Controller
            control={control}
            name="adhesionId"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Select
                className={classNames(style.select, style.input)}
                error={errors.adhesionId}
                onChange={option => selectContract({option, onChange})}
                options={adhesionsOptions}
                placeholder={t('main:contactUs.contractSelector.placeholder')}
                value={value}
              />
            )}
          />
          <Controller
            control={control}
            name="subject"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Select
                className={classNames(style.select, style.input)}
                error={errors.subject}
                onChange={val => {
                  onChange(val.value)
                  trigger('subject')
                }}
                options={subjects}
                placeholder={t('main:contactUs.subjectSelector.placeholder')}
                value={value}
              />
            )}
          />
        </div>

        {getValues('subject') === t('main:contactUs.termination') && closestTerminationDate && (
          <div className={style.closestTerminationDate}>
            {t('main:contactUs.closestTerminationDate')}
            <span>{new Date(closestTerminationDate).toLocaleDateString()}</span>
          </div>
        )}

        <Textarea
          className={style.textarea}
          error={errors.text}
          label={t('main:contactUs.textInput.label')}
          placeholder={t('main:contactUs.textInput.placeholder')}
          register={register('text', { required: true })}
        />

        <Button
          className={style.button}
          label={t('main:contactUs.button')}
          onClick={handleSubmit}
          type="primary"
          disabled={!isValid}
        />
      </form>

      <Modal
        content={
          <div>
            <h2 className={style['modal-title']} style={{ textAlign: 'left' }}>
              {t('main:contactUs.termination')}
            </h2>

            <p className={style['modal-text']} style={{ textAlign: 'left' }}>
              {new Date(closestTerminationDate).toLocaleDateString() === 
              new Date(new Date().setDate(new Date().getDate() + 1)).toLocaleDateString()
                ? t('main:contactUs.modal.messageDynamic', {
                    firstname: selectedAdhesion?.firstname || '',
                    lastname: selectedAdhesion?.lastname || '',
                    adhesionNumber: selectedAdhesion?._id || '',
                    currentDate: new Date().toLocaleDateString()
                  })
                : <>
                    {t('main:contactUs.modal.message')} 
                    <span> {new Date(closestTerminationDate).toLocaleDateString()} </span>
                  </>}
            </p>


            <p className={style['modal-text']}>
              {t('main:contactUs.modal.areYouSure')}
            </p>

            <Button
              className={style['modal-button']}
              label={t('main:contactUs.modal.confirm')}
              onClick={() => handleModalConfirm(formData)}
              type="primary"
            />

            <Button
              className={style['modal-button']}

              label={t('main:contactUs.modal.cancel')}
              onClick={handleCloseModal}
              type="secondary"
            />
          </div>
        }
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        styles={{
          content: {
            width: isMobile() ? '90%' : 430,
            maxWidth: 430,
            height: new Date(closestTerminationDate).toLocaleDateString() === 
            new Date(new Date().setDate(new Date().getDate() + 1)).toLocaleDateString()
            ? (isMobile() ? 290 : 430)
            : (isMobile() ? 290 : 330)
          },
        }}
      />
    </div>
  );
};

export default ContactUs;

