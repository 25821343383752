const MainLogo = ({ styles }) => {
  return (
    <svg width="72" height="24" viewBox="0 0 72 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...styles}>
      <path
        d="M21.0955 9.36714C19.5022 5.00515 15.4789 1.8012 10.8458 1.20432C10.605 1.16813 10.369 1.36162 10.3522 1.60337L10.1341 4.05361C10.1133 4.2886 10.2812 4.49802 10.5172 4.52986C13.9079 4.99164 16.76 7.28844 17.9605 10.5237C18.0455 10.7549 18.3056 10.8707 18.5285 10.7867L20.8335 9.93073C21.0607 9.84629 21.1785 9.59345 21.0955 9.36714Z"
        fill="#FFA92C"
      />
      <path
        d="M39.5193 6.67878L34.3342 22.0586C34.2738 22.2381 34.1054 22.3588 33.9163 22.3588H32.1141C31.9389 22.3588 31.7802 22.255 31.7097 22.0943L27.9152 13.4133L24.3523 22.0852C24.2842 22.2512 24.1236 22.3588 23.9441 22.3588H22.1756C21.9865 22.3588 21.8186 22.2386 21.7578 22.0601L18.537 12.6143C18.4467 12.3687 18.6161 12.0801 18.8738 12.0381L21.2593 11.5933C21.4765 11.5527 21.6917 11.6806 21.7597 11.8905L23.3245 16.6945L26.8686 8.86701C26.94 8.70971 27.0978 8.6079 27.2706 8.6079H28.8214C28.9941 8.6079 29.1514 8.70923 29.2228 8.86653L32.7279 16.5777L36.0683 6.40037C36.1282 6.21846 36.2966 6.09686 36.4876 6.09686H39.1005C39.4117 6.09686 39.6105 6.38155 39.5188 6.67926L39.5193 6.67878Z"
        fill="white"
        {...styles}
      />
      <path
        d="M44.3976 6.36779C38.2517 8.74566 37.6307 18.5848 43.5599 21.5938C46.8806 23.4645 51.5094 22.7523 54.2294 20.3108C54.3886 20.1621 54.4127 19.9146 54.2844 19.7351L52.9966 17.9271C52.8566 17.7215 52.5439 17.6796 52.3562 17.8431C49.3101 20.7281 43.5382 19.655 42.9543 15.1579H55.0767C55.8704 8.81949 50.5405 3.87171 44.3976 6.36779ZM43.0947 12.299C43.9141 7.97218 51.0486 7.77579 51.5557 12.299H43.0947Z"
        fill="white"
        {...styles}
      />
      <path
        d="M70.5112 8.75003C68.9507 4.65487 62.8381 5.12581 60.482 8.16039V6.53719C60.482 6.294 60.2841 6.09616 60.041 6.09616H57.4291C57.1859 6.09616 56.988 6.294 56.988 6.53719V21.9171C56.988 22.1603 57.1859 22.3581 57.4291 22.3581H60.041C60.2841 22.3581 60.482 22.1603 60.482 21.9171V12.4201C60.4313 10.3723 62.2041 8.96475 64.2162 8.9855C66.3905 8.9855 67.6373 10.339 67.6373 12.6995V21.9185C67.6373 22.1612 67.8351 22.3586 68.0778 22.3586C69.7271 22.3586 71.0921 21.0167 71.1201 19.3674C71.1829 15.7755 71.3484 10.4278 70.5112 8.75051V8.75003Z"
        fill="white"
        {...styles}
      />
      <path
        d="M9.10542 5.7749C4.5181 5.7749 0.799316 9.09802 0.799316 14.2195C0.799316 19.341 4.369 22.6636 9.10542 22.6636C13.8418 22.6636 17.4115 19.0524 17.4115 14.2195C17.4115 9.38657 14.1497 5.7749 9.10542 5.7749ZM9.10542 19.4433C6.40958 19.4433 4.37817 17.3274 4.37817 14.2195C4.37817 11.1116 6.4945 8.99573 9.10542 8.99573C11.9759 8.99573 13.8327 11.3345 13.8327 14.2195C13.8327 17.1045 11.8674 19.4433 9.10542 19.4433Z"
        fill="white"
        {...styles}
      />
    </svg>
  )
}

export default MainLogo
