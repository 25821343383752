const Close = ({ className, onClick, styles }) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.500959 0.499512C0.745037 0.255434 1.14076 0.255434 1.38484 0.499512L4.00059 3.11554L6.61684 0.499464C6.86092 0.255387 7.25665 0.255387 7.50072 0.499464C7.7448 0.743542 7.7448 1.13927 7.50072 1.38335L4.88447 3.99942L7.50068 6.61563C7.74475 6.85971 7.74475 7.25543 7.50068 7.49951C7.2566 7.74359 6.86087 7.74359 6.61679 7.49951L4.00059 4.88331L1.3843 7.49951C1.14022 7.74359 0.744489 7.74359 0.500411 7.49951C0.256334 7.25543 0.256334 6.8597 0.500411 6.61563L3.1167 3.99942L0.500959 1.38339C0.256881 1.13932 0.256881 0.743589 0.500959 0.499512Z"
        fill="#00003A"
        {...styles}
      />
    </svg>
  )
}

export default Close
