import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PageTitle from 'components/common/PageTitle/PageTitle'
import PageSubtitle from 'components/common/PageSubtitle/PageSubtitle'
import InfoMessage from 'components/common/InfoMessage/InfoMessage'
import ShieldLogo from 'components/assets/icons/ShieldLogo'
import Arrow from 'components/assets/icons/Arrow'
import Card from 'components/common/Card/Card'
import api from 'helpers/api'
import Button from 'components/common/Button/Button'
import style from './ClaimList.module.scss'
import Thundercloud from 'components/assets/icons/Thundercloud'
import Status from 'components/common/Status/Status'
import { useNavigate } from 'react-router-dom'
import { CLAIM_STATUSES } from 'constants/contract'

const ClaimList = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [claims, setClaims] = useState([])

  const { APPROVED, INCOMPLETE, CLOSED } = CLAIM_STATUSES

  useEffect(() => {
    const getClaims = async () => {
      try {
        const res = await api.get({ url: '/claims' })
        setClaims(res)
      } catch (error) {
        console.log('Fetch claims error')
      }
    }
    getClaims()
  }, [])

  return (
    <>
      <PageTitle text={t('claim:list.title')} />
      <PageSubtitle text={t('claim:list.subtitle')} />
      <InfoMessage
        className={style['info-message']}
        label={t('claim:infoMessage')}
        prefix={<ShieldLogo />}
        suffix={
          <Button
            className={style['claim-button']}
            label={t('claim:ctaInfinitive')}
            onClick={() => navigate('/claims/new')}
            type="primary"
          />
        }
      />
      <div className={style.items}>
        {(!claims || !claims?.length) && <div className={style['empty-list-message']}>{t('claim:list.empty')}</div>}
        {!!claims?.length &&
          claims.map((item, index) => {
            return (
              <Card
                className={style.item}
                inactive={item.cancelation}
                content={
                  <div className={style['item-content']}>
                    <p>#{item.shortId}</p>
                    <Status
                      label={t(`claim:item.status.${item.status}`)}
                      type={
                        item.status === APPROVED
                          ? 'success'
                          : item.status === INCOMPLETE || item.status === CLOSED
                          ? 'error'
                          : 'warning'
                      }
                    />
                  </div>
                }
                key={`contract-item-${index}`}
                onClick={() => navigate(item._id, { state: { claim: item } })}
                title={item.name}
                titleIcon={<Thundercloud styles={{ fill: '#1414FF' }} />}
                cornerContent={<Arrow />}
              />
            )
          })}
      </div>
    </>
  )
}

export default ClaimList
