const ExclamationTriangleOrange = ({ styles }) => {
    return (
      <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.42651 6.96791C5.85847 2.65597 7.07446 0.5 9.00008 0.5C10.9257 0.5 12.1417 2.65597 14.5737 6.96791L14.8767 7.50522C16.8977 11.0884 17.9081 12.88 16.9949 14.19C16.0816 15.5 13.8221 15.5 9.30313 15.5H8.69703C4.17805 15.5 1.91856 15.5 1.0053 14.19C0.0920332 12.88 1.10251 11.0884 3.12346 7.50523L3.42651 6.96791ZM9.00008 4.04167C9.34526 4.04167 9.62508 4.32149 9.62508 4.66667V8.83333C9.62508 9.17851 9.34526 9.45833 9.00008 9.45833C8.6549 9.45833 8.37508 9.17851 8.37508 8.83333V4.66667C8.37508 4.32149 8.6549 4.04167 9.00008 4.04167ZM9.00008 12.1667C9.46032 12.1667 9.83341 11.7936 9.83341 11.3333C9.83341 10.8731 9.46032 10.5 9.00008 10.5C8.53984 10.5 8.16675 10.8731 8.16675 11.3333C8.16675 11.7936 8.53984 12.1667 9.00008 12.1667Z"
          fill="#FFB02F" /* Couleur jaune pour le triangle */
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.00008 4.04167C9.34526 4.04167 9.62508 4.32149 9.62508 4.66667V8.83333C9.62508 9.17851 9.34526 9.45833 9.00008 9.45833C8.6549 9.45833 8.37508 9.17851 8.37508 8.83333V4.66667C8.37508 4.32149 8.6549 4.04167 9.00008 4.04167ZM9.00008 12.1667C9.46032 12.1667 9.83341 11.7936 9.83341 11.3333C9.83341 10.8731 9.46032 10.5 9.00008 10.5C8.53984 10.5 8.16675 10.8731 8.16675 11.3333C8.16675 11.7936 8.53984 12.1667 9.00008 12.1667Z"
          fill="#FEF9F0" /* Couleur du point d'exclamation */
          {...styles}
        />
      </svg>
    );
  }
  
  export default ExclamationTriangleOrange;
  