import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
// import { useNavigate } from 'react-router'

import Button from 'components/common/Button/Button'
import Input from 'components/common/Input/Input'
// import api from 'helpers/api'
import style from './PaymentMethodForm.module.scss'
import PageTitle from 'components/common/PageTitle/PageTitle'
import PageSubtitle from 'components/common/PageSubtitle/PageSubtitle'

const PaymentMethodForm = () => {
  const { t } = useTranslation()
  // const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    criteriaMode: 'all',
    delayError: 500,
    defaultValues: {
      cardNumber: '',
      expirationDate: '',
      securityCode: '',
    },
    mode: 'onTouched',
  })

  const onSubmit = handleSubmit(async data => {
    // const res = await api.put({
    //   url: '/clients/payment_method',
    //   data,
    // })
    // if (res.success) {
    //   navigate('/contacts')
    // }
  })

  return (
    <>
      <PageTitle text={t('main:clientContacts.paymentMethod.form.title')} />
      <PageSubtitle text={t('main:clientContacts.paymentMethod.form.subtitle')} />
      <form className={style.form} onSubmit={onSubmit}>
        <div className={style.inputs}>
          <Input
            className={style.input}
            register={register('cardNumber', { required: true })}
            label={t('common:paymentMethod.cardNumber')}
            placeholder={t('common:paymentMethod.cardNumber')}
          />
          <div className={style['secondary-inputs']}>
            <Input
              className={style.input}
              register={register('expirationDate', { required: true })}
              label={t('common:expirationDate')}
              placeholder={t('common:expirationDate')}
            />
            <Input
              register={register('securityCode', { required: true })}
              label={t('common:paymentMethod.securityCode')}
              placeholder={t('common:paymentMethod.securityCode')}
            />
          </div>
        </div>
        <Button
          className={style.button}
          label={t('common:save')}
          onClick={handleSubmit}
          type="primary"
          disabled={!isValid}
        />
      </form>
    </>
  )
}

export default PaymentMethodForm
