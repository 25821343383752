import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import Button from 'components/common/Button/Button'
import Input from 'components/common/Input/Input'
import { isInputError } from 'helpers/form'
import style from './AddressForm.module.scss'
import Title from 'components/Subscription/common/Title/Title'
import api from 'helpers/api'
import Select from 'components/common/Select/Select'

const AddressForm = () => {
  
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { state } = useLocation()
  const [selectedAddress, setSelectedAddress] = useState('')
  let inputTimeout
  useEffect(() => {
    if (!state?.client || !state?.contract || !state?.step) navigate('/sign-in')

  }, [navigate, state])

  const getDefaultValue = field => {
    return state.client[field] || ''
  }
  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    trigger,
  } = useForm({
    criteriaMode: 'all',
    delayError: 500,
    mode: 'onTouched',
    values: state?.client && {
      address: getDefaultValue('address'),
      zipcode: getDefaultValue('zipcode'),
      city: getDefaultValue('city'),
      country: getDefaultValue('country'),
      additionalAddress: getDefaultValue('additionalAddress'),
    },
  })

  const errorFields = Object.keys(errors)
  const onSubmit = handleSubmit(async data => {
    navigate('../expenses', {
      state: {
        client: { ...state.client, ...data },
        contract: state.contract,
        step: 4,
        token: state.token,
        idCampaign: state.idCampaign
      },
    })
  })

  const onAddressChange = async e => {
    const res = await api.get({
      url: `/clients/address/${e.value}`,
    })

    const number = res.address_components.find(item => item.types.includes('street_number'))?.long_name || ''
    const street = res.address_components.find(item => item.types.includes('route'))?.long_name || ''
    const city = res.address_components.find(item => item.types.includes('locality') || item.types.includes('postal_town'))?.long_name || ''
    const zipcode = res.address_components.find(item => item.types.includes('postal_code'))?.long_name || ''
    const country = res.address_components.find(item => item.types.includes('country'))?.long_name || ''

    const address = `${number} ${street}`;

    if (address !== '') {
      setValue('address', address, true)
      setSelectedAddress(address)
    }
    if (zipcode !== '') setValue('zipcode', zipcode, true)
    if (city !== '') setValue('city', city, true)
    if (country !== '') setValue('country', country, true)

    trigger('zipcode')
  }

  const getAddressOptions = async val => {
      const res = await api.get({
        url: '/clients/address',
        params: { address: val },
      })

      const options = res.reduce((acc, option) => {
          acc.push({
            label: option.description,
            value: option.place_id,
          })

        return acc
      }, [])
      return options
    /*} else {
      return []
    }*/
  }

  const loadAddressOptions = val => {
    clearTimeout(inputTimeout)
    return new Promise(resolve => {
      inputTimeout = setTimeout(async () => {

        const data = await getAddressOptions(val)
        resolve(data)
      }, 500)
    })
  }

  const validateCountry = (value) => {
    if (value.toLowerCase() !== 'france') {
      setError('country');
      return false;
    }
    clearErrors('country');
    return true;
  };

  if (!state) return null

  return (
    <>
      <Title text={<>{t('subscription:address.title')} 🏠</>} />
      <form className={style.form} onSubmit={onSubmit}>
        <div className={style.inputs}>
          <Controller
            control={control}
            name="address"
            rules={{ required: true }}
            render={() => (
              <Select
                async
                cacheOptions
                label={t('common:form.streetAddress.label')}
                className={classNames(style.select, style.input)}
                error={isInputError(errorFields, 'address')}
                isSearchable
                loadOptions={loadAddressOptions}
                onChange={val => onAddressChange(val)}
                placeholder={t('common:form.streetAddress.placeholder')}
                styles={{ control: { background: '#fff' }, indicatorContainer: { paddingRight: 8 } }}
                value={selectedAddress || state?.client?.address}
              />
            )}
          />
          {errorFields.includes('address') && <p className={style['input-error']}>{t('common:form.incorrect')}</p>}
          <Input
            className={style.input}
            error={isInputError(errorFields, 'additionalAddress')}
            register={register('additionalAddress')}
            label={t('common:form.additionalAddress.label')}
            placeholder={t('common:form.additionalAddress.placeholder')}
          />
          <div className={style['half-width-inputs']}>
            <div className={classNames(style['custom-input'], style.input)}>
              <Input
                className={classNames(style.input, style['half-width'])}
                error={isInputError(errorFields, 'zipcode')}
                register={register('zipcode', { required: true })}
                label={t('common:form.zipCode.label')}
                placeholder={`${t('common:your')} ${t('common:form.zipCode.label').toLowerCase()}`}
              />
              {errorFields.includes('zipcode') && <p className={style['input-error']}>{t('common:form.incorrect')}</p>}
            </div>
            <div className={classNames(style['custom-input'], style.input)}>
              <Input
                className={classNames(style.input, style['half-width'])}
                error={isInputError(errorFields, 'city')}
                register={register('city', { required: true })}
                label={t('common:form.city.label')}
                placeholder={t('common:form.city.placeholder')}
              />
              {errorFields.includes('city') && <p className={style['input-error']}>{t('common:form.incorrect')}</p>}
            </div>
          </div>
          <div>
            <Input
              className={style.input}
              error={isInputError(errorFields, 'country')}
              register={register('country', {
                required: true,
                validate: validateCountry
              })}
              label={t('common:form.country.label')}
              placeholder={`${t('common:your')} ${t('common:form.country.label').toLowerCase()}`}
            />
            {errorFields.includes('country') && <p className={style['input-error']}>{t('common:form.country.error')}</p>}
          </div>
        </div>
        <Button
          className={style.button}
          label={t('common:next')}
          onClick={handleSubmit}
          type="primary"
          disabled={!isValid}
        />
      </form>
    </>
  )
}

export default AddressForm
