import { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

// import HomeSmile from 'components/assets/icons/HomeSmile'
import User from 'components/assets/icons/User'
import Notes from 'components/assets/icons/Notes'
import Thundercloud from 'components/assets/icons/Thundercloud'
import ChatLine from 'components/assets/icons/ChatLine'
import SignOutIcon from 'components/assets/icons/SignOutIcon'
import { getNavMenuIconColor, isMobile } from 'helpers/styles'
import Link from 'components/common/Link/Link'
import style from './NavMenu.module.scss'
import Header from 'components/Header/Header'

const NavMenu = ({ onNavItemClick }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { t } = useTranslation()


  const signOut = () => {
    localStorage.removeItem('accessToken')
    navigate('sign-in')
  }

  const handleNavMenuClick = path => {
    if (isMobile()) {
      onNavItemClick(path)
    } else {
      navigate(path)
    }
  }

  const navItems = useMemo(
    () => [
      // not for MVP1
      // {
      //   icon: <HomeSmile styles={{ fill: getNavMenuIconColor(pathname, '/') }} />,
      //   title: 'Accueil',
      //   path: '/',
      // },
      {
        icon: <User styles={{ fill: getNavMenuIconColor(pathname, '/contacts') }} />,
        title: t('common:menu.contactDetails'),
        path: '/contacts',
      },
      {
        icon: <Notes styles={{ fill: getNavMenuIconColor(pathname, '/contracts') }} />,
        title: t('common:menu.contract'),
        path: '/contracts',
      },
      {
        icon: <Thundercloud styles={{ fill: getNavMenuIconColor(pathname, '/claims') }} />,
        title: t('common:menu.claims'),
        path: '/claims',
      },
      {
        icon: <ChatLine styles={{ fill: getNavMenuIconColor(pathname, '/contact-us') }} />,
        title: t('common:menu.contactOwen'),
        path: '/contact-us',
      },
    ],
    [pathname],
  )

  return (
    <div className={style['nav-menu']}>
      {isMobile() && <Header className={style.header} darkBg />}

      <div className={style.items}>
        {navItems.map((item, index) => {
          return (
            <div key={`nav-item-${index}`} className={style.item}>
              {item.icon}
              <Link
                className={classNames(style['item-title'], pathname.startsWith(item.path) && style['is-active'])}
                label={item.title}
                onClick={() => handleNavMenuClick(item.path)}
                darkBg
              />
            </div>
          )
        })}
      </div>

      <div className={classNames(style['sign-out'], style.item)}>
        <SignOutIcon />
        <Link className={style['item-title']} label={t('common:menu.logout')} onClick={signOut} darkBg />
      </div>
    </div>
  )
}

export default NavMenu
