import classNames from 'classnames'
import Arrow from 'components/assets/icons/Arrow'
import Link from 'components/common/Link/Link'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import style from './Back.module.scss'

const Back = ({ className, from, to }) => {
  const { t } = useTranslation()
  const { pathname, state } = useLocation()
  const navigate = useNavigate()

  let backPath = null
  let label = ''

  const contactPathRegEx = /^\/contacts\/([a-z]|\d)+/
  const contractPathRegEx = /^\/contracts\/([a-z]|\d){24}$/
  const contractSubPathRegex = /^\/contracts\/([a-z]|\d){24}\/\S+/
  const claimPathRegEx = /^\/claims\/([a-z]|\d)+/

  if (state?.fromClaim) {
    const { step } = state
    label =
      step === 0
        ? t('claim:applySteps.contract.backLabel')
        : step === 1
        ? t('claim:applySteps.insuredPerson.backLabel')
        : step === 2
        ? t('claim:applySteps.contacts.backLabel')
        : step === 3
        ? t('claim:applySteps.circumstances.backLabel')
        : ''
  }

  if (contractPathRegEx.test(pathname)) {
    if (state?.fromClaim) {
      backPath = ''
    } else {
      backPath = '/contracts'
    }
  } else if (contactPathRegEx.test(pathname)) {
    backPath = '/contacts'
  } else if (contractSubPathRegex.test(pathname)) {
    const id = pathname.split('/')[2]
    backPath = `/contracts/${id}`
  } else if (claimPathRegEx.test(pathname)) {
    backPath = '/claims'
  }

  const goBack = () => {
    if (state?.fromClaim) {
      return navigate(-1)
    } else if (from) {
      return navigate(from, { state })
    } else if (state?.from) {
      const { from, ...newState } = state
      navigate(state.from, { state: newState })
    } else {
      navigate(backPath)
    }
  }

  return (
    (to || backPath !== null || from) && (
      <Link
        className={classNames(style.back, className)}
        label={
          <div className={style.link}>
            <Arrow className={style.arrow} styles={{ fill: '#0000C9' }} />
            <span className={style.label}>{label || t('common:backLabel')}</span>
          </div>
        }
        onClick={() => goBack()}
        to={to || backPath}
      />
    )
  )
}

export default Back
