const Valid = ({ styles }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9999 10.0001C15.9999 13.3138 13.3136 16.0001 9.99992 16.0001C6.68621 16.0001 3.99992 13.3138 3.99992 10.0001C3.99992 6.68641 6.68621 4.00012 9.99992 4.00012C13.3136 4.00012 15.9999 6.68641 15.9999 10.0001ZM12.4181 8.18192C12.5939 8.35766 12.5939 8.64258 12.4181 8.81832L9.41812 11.8183C9.24238 11.9941 8.95746 11.9941 8.78172 11.8183L7.58172 10.6183C7.40599 10.4426 7.40599 10.1577 7.58172 9.98192C7.75746 9.80619 8.04238 9.80619 8.21812 9.98192L9.09992 10.8637L10.4408 9.52283L11.7817 8.18192C11.9575 8.00619 12.2424 8.00619 12.4181 8.18192Z"
        fill="#58B441"
      />
      <path
        d="M12.4181 8.81832C12.5939 8.64258 12.5939 8.35766 12.4181 8.18192C12.2424 8.00619 11.9575 8.00619 11.7817 8.18192L10.4408 9.52283L9.09992 10.8637L8.21812 9.98192C8.04238 9.80619 7.75746 9.80619 7.58172 9.98192C7.40599 10.1577 7.40599 10.4426 7.58172 10.6183L8.78172 11.8183C8.95746 11.9941 9.24238 11.9941 9.41812 11.8183L12.4181 8.81832Z"
        fill="white"
      />
    </svg>
  )
}

export default Valid
