const config = {
  api: {
    url: process.env.REACT_APP_API_URL,
    xApiKey: process.env.REACT_APP_X_API_KEY,
  },
  locize: {
    projectId: process.env.REACT_APP_LOCIZE_PROJECT_ID,
    apiKey: process.env.REACT_APP_LOCIZE_API_KEY,
    referenceLng: 'fr',
    version: process.env.REACT_APP_LOCIZE_VERSION,
  },
  rollbar: {
    accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
    environment: process.env.REACT_APP_ROLLBAR_ENV,
  },
  yousign: {
    url: process.env.REACT_APP_YOUSIGN_URL,
    dataType: process.env.REACT_APP_YOUSIGN_DATA_TYPE,
  },
}

export default config
