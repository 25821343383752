const InfoCircle = ({ className, styles }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3332 10.0003C18.3332 14.6027 14.6022 18.3337 9.99984 18.3337C5.39746 18.3337 1.6665 14.6027 1.6665 10.0003C1.6665 5.39795 5.39746 1.66699 9.99984 1.66699C14.6022 1.66699 18.3332 5.39795 18.3332 10.0003ZM9.99984 14.792C10.345 14.792 10.6248 14.5122 10.6248 14.167V9.16699C10.6248 8.82181 10.345 8.54199 9.99984 8.54199C9.65466 8.54199 9.37484 8.82181 9.37484 9.16699V14.167C9.37484 14.5122 9.65466 14.792 9.99984 14.792ZM9.99984 5.83366C10.4601 5.83366 10.8332 6.20675 10.8332 6.66699C10.8332 7.12723 10.4601 7.50033 9.99984 7.50033C9.5396 7.50033 9.1665 7.12723 9.1665 6.66699C9.1665 6.20675 9.5396 5.83366 9.99984 5.83366Z"
        fill="#4343FF"
      />
    </svg>
  )
}

export default InfoCircle
