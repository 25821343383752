import ReactModal from 'react-modal'
import style from './Modal.module.scss'
import Close from 'components/assets/icons/Close'
import { isMobile } from 'helpers/styles'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: isMobile() ? '32px 38px' : '48px 38px',
    textAlign: 'center',
    borderRadius: 16,
    overflow: 'hidden',
  },
  overlay: { zIndex: 10, background: 'rgba(0, 0, 58, .6)' },
}

const Modal = ({ content, isOpen = true, onClose, styles }) => {
  return (
    <ReactModal
      isOpen={isOpen}
      preventScroll
      style={{
        content: { ...customStyles.content, ...styles.content },
        overlay: { ...customStyles.overlay, ...styles.overlay },
      }}
      contentLabel="Example Modal"
      onRequestClose={onClose}
    >
      <div className={style['modal-content']}>
        <div className={style['close-button']} onClick={onClose}>
          <Close />
        </div>
        {content}
      </div>
    </ReactModal>
  )
}

export default Modal
