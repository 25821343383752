import classNames from 'classnames'
import style from './RangeInput.module.scss'
import './RangeInput.scss'

const RangeInput = ({
  className,
  darkBg,
  disabled,
  error,
  id,
  label,
  value,
  readOnly,
  step = 100,
  min,
  max,
  minLabel,
  maxLabel,
  ...props
}) => {
  for (let e of document.querySelectorAll('input[type="range"].slider-progress')) {
    e.style.setProperty('--value', e.value)
    e.style.setProperty('--min', e.min === '' ? '0' : e.min)
    e.style.setProperty('--max', e.max === '' ? '100' : e.max)
    e.addEventListener('input', () => e.style.setProperty('--value', e.value))
  }

  return (
    <div className={classNames(style['input-wrapper'], className, darkBg && style['dark-bg'], error && style.error)}>
      <label className={classNames('input-label', darkBg && 'dark-bg')} htmlFor={id}>
        {label}
      </label>
      <div className={style.input}>
        <input
          className="styled-slider slider-progress"
          id={id}
          value={value}
          type="range"
          disabled={disabled}
          readOnly={readOnly}
          step={step}
          min={min}
          max={max}
          {...props}
        />
        <div className={style.points_container}>
          <p className={style.point}>{minLabel}</p>
          <p className={style.point}>{maxLabel}</p>
        </div>
      </div>
    </div>
  )
}

export default RangeInput
