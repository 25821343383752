import { Outlet, useLocation } from 'react-router-dom'

import { useContext, useEffect, useState } from 'react'
import ClientContext from 'contexts/ClientContext'
import api from 'helpers/api'

const Contacts = () => {
  const { state } = useLocation()
  const clientContext = useContext(ClientContext)

  const [client, setClient] = useState(state?.client || clientContext || null)

  useEffect(() => {
    const getClient = async () => {
      try {
        const clientRes = await api.get({ url: '/clients/me' })
        setClient(clientRes.client)
      } catch (error) {
        console.log('Fetch client contacts error')
      }
    }

    if (!client) getClient()
    if (state?.client) {
      setClient(state?.client)
    }
  }, [client, state?.client])

  return (
    <div>
      <Outlet context={client} />
    </div>
  )
}

export default Contacts
