import React from 'react'
import { Helmet } from 'react-helmet';
import ReactDOM from 'react-dom/client'
import { Provider } from '@rollbar/react'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

import reportWebVitals from './reportWebVitals'
import App from './components/App/App'
import './index.scss'
import './i18n'
import config from 'config'

dayjs.extend(customParseFormat)

const rollbarConfig = {
  accessToken: config.rollbar.accessToken,
  environment: config.rollbar.environment,
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <Provider config={rollbarConfig}>
    <Helmet>
        {/* Google Analytics gtag.js code */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-LKCWC02PV5"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-LKCWC02PV5');
          `}
        </script>
      </Helmet>
      <App />
    </Provider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
