const Message = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.5297 12.0584L15.9601 7.76732C17.2096 4.01867 17.8344 2.14434 16.845 1.15495C15.8556 0.165561 13.9813 0.790336 10.2326 2.03989L5.94152 3.47026C2.91599 4.47877 1.40323 4.98302 0.973342 5.72247C0.564388 6.42593 0.564387 7.29473 0.973341 7.99818C1.40322 8.73764 2.91599 9.24189 5.94151 10.2504C6.31647 10.3754 6.7383 10.2861 7.01907 10.008L11.6071 5.46245C11.8652 5.20673 12.2818 5.20866 12.5375 5.46678C12.7932 5.7249 12.7913 6.14145 12.5332 6.39718L8.01922 10.8693C7.70975 11.1759 7.61179 11.6452 7.74955 12.0584C8.75805 15.0839 9.26231 16.5967 10.0018 17.0266C10.7052 17.4356 11.574 17.4356 12.2775 17.0266C13.0169 16.5967 13.5212 15.084 14.5297 12.0584Z"
        fill="#126F0E"
      />
    </svg>
  )
}

export default Message
