// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ResponseMessage_response-message__QLjXW {\n  width: 100%;\n  max-width: 362px;\n  padding: 4px 8px;\n  border: 1px solid #f9d9cf;\n  border-radius: 4px;\n  background: #fdf1ed;\n  color: #7c1028;\n  font-weight: 300;\n  font-size: 14px;\n  line-height: 24px;\n}", "",{"version":3,"sources":["webpack://./src/components/common/ResponseMessage/ResponseMessage.module.scss","webpack://./src/styles/colors.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,gBAAA;EACA,gBAAA;EACA,yBAAA;EACA,kBAAA;EACA,mBCkBQ;EDjBR,cCsBQ;EDrBR,gBAAA;EACA,eAAA;EACA,iBAAA;AADF","sourcesContent":["@use 'styles/colors.scss';\n\n.response-message {\n  width: 100%;\n  max-width: 362px;\n  padding: 4px 8px;\n  border: 1px solid colors.$danger2;\n  border-radius: 4px;\n  background: colors.$danger1;\n  color: colors.$danger7;\n  font-weight: 300;\n  font-size: 14px;\n  line-height: 24px;\n}\n","$primary1: #f5f5ff;\n$primary2: #d8d8ff;\n$primary3: #8686ff;\n$primary4: #4343ff;\n$primary5: #1414ff;\n$primary6: #0000c9;\n$primary7: #000093;\n$primary8: #00007a;\n$primary9: #000054;\n$primary10: #00003a;\n$primary11: #000078;\n\n$neutral1: #fcfcfd;\n$neutral2: #f9f9fa;\n$neutral4: #e0e0e3;\n$neutral8: #838390;\n\n$success1: #f4fced;\n$success5: #58b441;\n$success6: #126f0e;\n\n$attention1: #fef9f0;\n$attention3: #fbd298;\n$attention5: #e78d3d;\n$attention7: #b94402;\n\n$danger1: #fdf1ed;\n$danger2: #f9d9cf;\n$danger3: #f4ada1;\n$danger5: #bd454a;\n$danger6: #911727;\n$danger7: #7c1028;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"response-message": "ResponseMessage_response-message__QLjXW"
};
export default ___CSS_LOADER_EXPORT___;
