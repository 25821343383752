import classNames from 'classnames'

import ExclamationTriangle from 'components/assets/icons/ExclamationTriangle'
import PrefixedText from 'components/common/PrefixedText/PrefixedText'
import style from './ResponseMessage.module.scss'

const ResponseMessage = ({ className, label, styles, type }) => {
  return (
    <div className={classNames(style['response-message'], className)} style={styles} type={type}>
      <PrefixedText prefix={<ExclamationTriangle />} text={label} />
    </div>
  )
}

export default ResponseMessage
