import { useState } from 'react'
import classNames from 'classnames'

import style from './LanguageSelector.module.scss'
import Select from 'components/common/Select/Select'

const LanguageSelector = ({ darkBg }) => {
  const [selectedOption, setSelectedOption] = useState('fr')

  const options = [
    {
      value: 'fr',
      label: '🇫🇷',
    },
  ]

  return (
    <div className={style['language-selector']}>
      <Select
        className={classNames(style.select, style.input)}
        defaultValue={selectedOption}
        isSearchable={false}
        onChange={setSelectedOption}
        options={options}
        placeholder={'🇫🇷'}
        styles={{
          darkBg,
          control: { height: 28, minHeight: 28, padding: '2px 5px 2px 6px' },
          dropdownIndicator: { width: 12, padding: 0 },
          indicatorContainer: { padding: 0 },
          menuList: { padding: '4px' },
          option: { borderRadius: 4, height: 28, marginTop: -5, padding: 0, textAlign: 'center' },
          placeholder: {
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            margin: '-2px 0 0',
            padding: '0',
          },
          singleValue: { display: 'flex', alignItems: 'center', margin: '-3px 0 0' },
          valueContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100%',
            maxWidth: 20,
            padding: '0',
          },
        }}
      />
    </div>
  )
}

export default LanguageSelector
