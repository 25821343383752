import style from './PrefixedText.module.scss'

const PrefixedText = ({ prefix, text }) => {
  return (
    <div className={style['prefixed-text']}>
      {prefix && <div className={style.prefix}>{prefix}</div>}
      <p>{text}</p>
    </div>
  )
}

export default PrefixedText
