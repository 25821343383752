const ChatLine = ({ styles }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3574 17.0602L10.9056 17.8234C10.503 18.5037 9.49714 18.5037 9.0945 17.8234L8.64275 17.0602C8.29235 16.4682 8.11716 16.1722 7.83574 16.0085C7.55433 15.8449 7.20002 15.8387 6.4914 15.8265C5.44526 15.8085 4.78915 15.7444 4.2389 15.5165C3.21795 15.0936 2.40681 14.2825 1.98392 13.2615C1.66675 12.4958 1.66675 11.5251 1.66675 9.58366V8.75033C1.66675 6.02247 1.66675 4.65854 2.28075 3.65658C2.62431 3.09593 3.09569 2.62456 3.65634 2.28099C4.65829 1.66699 6.02222 1.66699 8.75008 1.66699H11.2501C13.9779 1.66699 15.3419 1.66699 16.3438 2.28099C16.9045 2.62456 17.3758 3.09593 17.7194 3.65658C18.3334 4.65854 18.3334 6.02247 18.3334 8.75033V9.58366C18.3334 11.5251 18.3334 12.4958 18.0162 13.2615C17.5934 14.2825 16.7822 15.0936 15.7613 15.5165C15.211 15.7444 14.5549 15.8085 13.5087 15.8265C12.8001 15.8387 12.4458 15.8449 12.1644 16.0085C11.883 16.1722 11.7078 16.4682 11.3574 17.0602ZM6.66675 9.79199C6.32157 9.79199 6.04175 10.0718 6.04175 10.417C6.04175 10.7622 6.32157 11.042 6.66675 11.042H11.2501C11.5953 11.042 11.8751 10.7622 11.8751 10.417C11.8751 10.0718 11.5953 9.79199 11.2501 9.79199H6.66675ZM6.04175 7.50033C6.04175 7.15515 6.32157 6.87533 6.66675 6.87533H13.3334C13.6786 6.87533 13.9584 7.15515 13.9584 7.50033C13.9584 7.8455 13.6786 8.12533 13.3334 8.12533H6.66675C6.32157 8.12533 6.04175 7.8455 6.04175 7.50033Z"
        fill="white"
        {...styles}
      />
    </svg>
  )
}

export default ChatLine
