const Clock = ({ className, styles }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.37533 1.6665C9.37533 1.32133 9.65515 1.0415 10.0003 1.0415C14.9479 1.0415 18.9587 5.05229 18.9587 9.99984C18.9587 14.9474 14.9479 18.9582 10.0003 18.9582C5.05277 18.9582 1.04199 14.9474 1.04199 9.99984C1.04199 9.65466 1.32181 9.37484 1.66699 9.37484C2.01217 9.37484 2.29199 9.65466 2.29199 9.99984C2.29199 14.257 5.74313 17.7082 10.0003 17.7082C14.2575 17.7082 17.7087 14.257 17.7087 9.99984C17.7087 5.74264 14.2575 2.2915 10.0003 2.2915C9.65515 2.2915 9.37533 2.01168 9.37533 1.6665ZM10.0003 6.87484C10.3455 6.87484 10.6253 7.15466 10.6253 7.49984V10.2082H13.3337C13.6788 10.2082 13.9587 10.488 13.9587 10.8332C13.9587 11.1783 13.6788 11.4582 13.3337 11.4582H10.0003C9.65515 11.4582 9.37533 11.1783 9.37533 10.8332V7.49984C9.37533 7.15466 9.65515 6.87484 10.0003 6.87484Z"
        fill="#E78D3D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5833 1.99779C7.70761 2.31981 7.54733 2.68162 7.22531 2.80593C7.10745 2.85143 6.99098 2.89974 6.87599 2.95079C6.5605 3.09085 6.19121 2.94863 6.05116 2.63315C5.9111 2.31766 6.05331 1.94837 6.3688 1.80831C6.50255 1.74893 6.63804 1.69273 6.77516 1.6398C7.09718 1.51549 7.459 1.67577 7.5833 1.99779ZM4.70674 3.53339C4.9448 3.78335 4.93514 4.17896 4.68519 4.41701C4.59379 4.50405 4.50454 4.59331 4.4175 4.6847C4.17945 4.93466 3.78384 4.94431 3.53388 4.70626C3.28392 4.46821 3.27427 4.07259 3.51232 3.82264C3.61338 3.71652 3.71701 3.61289 3.82312 3.51183C4.07308 3.27378 4.46869 3.28343 4.70674 3.53339ZM2.63364 6.05067C2.94912 6.19073 3.09134 6.56002 2.95128 6.8755C2.90023 6.9905 2.85191 7.10697 2.80642 7.22483C2.68211 7.54685 2.32029 7.70712 1.99827 7.58282C1.67626 7.45851 1.51598 7.09669 1.64028 6.77468C1.69322 6.63755 1.74942 6.50207 1.8088 6.36831C1.94886 6.05282 2.31815 5.91061 2.63364 6.05067Z"
        fill="#E78D3D"
      />
    </svg>
  )
}

export default Clock
