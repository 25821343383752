const CircleSmile = ({ className, styles }) => {
  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9998 29.8332C23.3636 29.8332 29.3332 23.8636 29.3332 16.4998C29.3332 9.13604 23.3636 3.1665 15.9998 3.1665C8.63604 3.1665 2.6665 9.13604 2.6665 16.4998C2.6665 23.8636 8.63604 29.8332 15.9998 29.8332ZM11.1965 21.2377C11.5253 20.794 12.1516 20.7009 12.5953 21.0298C13.5664 21.7496 14.7386 22.1665 15.9998 22.1665C17.261 22.1665 18.4332 21.7496 19.4044 21.0298C19.848 20.7009 20.4743 20.794 20.8032 21.2377C21.1321 21.6814 21.039 22.3077 20.5953 22.6365C19.2988 23.5976 17.7131 24.1665 15.9998 24.1665C14.2865 24.1665 12.7009 23.5976 11.4044 22.6365C10.9607 22.3077 10.8676 21.6814 11.1965 21.2377ZM21.3332 14.4998C21.3332 15.6044 20.7362 16.4998 19.9998 16.4998C19.2635 16.4998 18.6665 15.6044 18.6665 14.4998C18.6665 13.3953 19.2635 12.4998 19.9998 12.4998C20.7362 12.4998 21.3332 13.3953 21.3332 14.4998ZM11.9998 16.4998C12.7362 16.4998 13.3332 15.6044 13.3332 14.4998C13.3332 13.3953 12.7362 12.4998 11.9998 12.4998C11.2635 12.4998 10.6665 13.3953 10.6665 14.4998C10.6665 15.6044 11.2635 16.4998 11.9998 16.4998Z"
        fill="#8686FF"
      />
    </svg>
  )
}

export default CircleSmile
