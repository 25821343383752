import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import dayjs from 'dayjs'

import PageTitle from 'components/common/PageTitle/PageTitle'
import PageSubtitle from 'components/common/PageSubtitle/PageSubtitle'
import Switch from 'components/common/Switch/Switch'
import style from './ContractItem.module.scss'
import ShieldStar from 'components/assets/icons/ShieldStar'
import Archive from 'components/assets/icons/Archive'
import Euro from 'components/assets/icons/Euro'
import DocumentEdit from 'components/assets/icons/DocumentEdit'
import Bill from 'components/assets/icons/Bill'
import Arrow from 'components/assets/icons/Arrow'
import InfoCircle from 'components/assets/icons/InfoCircle'
import Button from 'components/common/Button/Button'
import Card from 'components/common/Card/Card'
import CardLegalDocuments from 'components/common/CardLegalDocuments/CardLegalDocuments'
import Link from 'components/common/Link/Link'
import Modal from 'components/common/Modal/Modal'
import { isMobile } from 'helpers/styles'
import api from 'helpers/api'
import Download from 'components/assets/icons/Download'
import Eye from 'components/assets/icons/Eye'
import ShieldLogo from 'components/assets/icons/ShieldLogo'
import { isCancelationEnabled, getCancelMessage, getContractDocuments, getYearPrice } from 'helpers/contract'
import { downloadDocument } from 'helpers/files'
import InfoMessage from 'components/common/InfoMessage/InfoMessage'
import CardLine from 'components/common/CardLine/CardLine'
import ExclamationTriangle from 'components/assets/icons/ExclamationTriangle'
import ExclamationTriangleOrange from 'components/assets/icons/ExclamationTriangleOrange'


const ContractItem = () => {
  const { t } = useTranslation(['common', 'claim', 'contract'])
  const params = useParams()
  const navigate = useNavigate()
  const { state } = useLocation()

  const [contract, setContract] = useState(state?.contract ?? null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [documents, setDocuments] = useState([])

  useEffect(() => {
    const getContract = async () => {
      try {
        const adhesionRes = await api.get({ url: `/adhesions/${params.id}/client` })
        setContract(adhesionRes.adhesion)
        setDocuments(await getContractDocuments(adhesionRes.adhesion))
        console.log(adhesionRes)
      } catch (error) {
        console.log('Fetch contract error')
      }
    }

    getContract()
  }, [params.id, contract?.urlDocument])

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handleChangePaymentPlan = async () => {
    try {
      const paymentPlan = contract.paymentPlan.name === 'month' ? 'year' : 'month'

      const res = await api.patch({
        url: `/adhesions/${contract._id}/payment-plan`,
        data: { paymentPlan },
      })

      setContract({ ...contract, paymentPlan: res.adhesion.paymentPlan })
    } catch (error) {
      console.log('Change payment plan error')
    }

    handleCloseModal()
  }

  const cancelContract = () => {
    navigate('cancel', { state: { contract } })
  }

  if (!contract) return null

  const {
    contractPeriod,
    insuranceExpiryDate: insuranceExpiryDateRaw,
    rateType,
    renewableContract,
    transactionHorodate,
    cancelationHorodate,
  } = contract
  console.log(contract)
  const yearPrice = getYearPrice(rateType?.rate)
  const daysToCancel = getCancelMessage(transactionHorodate)

  const insuranceExpiryDate = dayjs(insuranceExpiryDateRaw)
  const daysUntilExpiry = insuranceExpiryDate.diff(dayjs(), 'day')

  const isRenewalMessageShown = renewableContract && daysUntilExpiry <= 75 && daysUntilExpiry >= 0
  const isCancelationButtonEnabled = isCancelationEnabled(contract)
  const cancelationDate = dayjs(cancelationHorodate);
  const isCancelationMessageShown = isRenewalMessageShown && cancelationHorodate && cancelationDate.isAfter(dayjs());
  
  return (
    <>
      <Modal
        content={
          <div>
            <p className={style['modal-title']}>{t('contract:payment.modalTitle')}</p>
            <p className={style['modal-description']}>{t('contract:payment.modalDescription', { total: yearPrice })}</p>
            <Button
              className={style['modal-button']}
              label={t('common:submit')}
              onClick={handleChangePaymentPlan}
              type="primary"
            />
          </div>
        }
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        styles={{
          content: {
            width: isMobile() ? '90%' : 430,
            maxWidth: 430,
            height: isMobile() ? 290 : 310,
          },
        }}
      />

      <PageTitle text={contract.rateType.dashboardPackageName || contract.contractType.dashboardName } />
      <PageSubtitle text={`${t('contract:item.partnerDescription')} ${contract.partner.commercialName}`} />
      {!contract.cancelation && (
        <InfoMessage
          className={style['info-message']}
          label={t('claim:infoMessage')}
          prefix={<ShieldLogo />}
          suffix={
            <Button
              className={style['claim-button']}
              label={t('claim:cta1stPerson')}
              onClick={() => navigate('/claims/new', { state: { contract, documents } })}
              type="primary"
            />
          }
        />

        

        
      )}
      {!contract.globalStatus && contract.daysRemaining > 0 && (
      <InfoMessage
        className={`${style['info-message']} ${style['custom-info-message']}`}
        label={`Il vous reste ${contract.daysRemaining} jours pour télécharger vos pièces justificatives pour le contrat ${contract.globalContractTypeName} !`}
        prefix={<ExclamationTriangleOrange />}
        suffix={
          <Button
            className={`${style['claim-button']} ${style['custom-claim-button']}`}
            label={"Télécharger mes documents"}
            onClick={() => navigate(`/contracts/${contract.globalAdhesionId}/documents`)}
            type="primary"
          />
        }
      />
    )}
      {isRenewalMessageShown && !cancelationHorodate && (
        <InfoMessage
          className={`${style['info-message']} ${style['warningrenew']}`}
          label={t('contract:item.renewalMessage', { endDate: insuranceExpiryDate.format('DD/MM/YYYY') })}
          prefix={<ExclamationTriangle styles={{ fill: '#58B441' }} />}
          type="warningrenew"
        />
      )}

    {isCancelationMessageShown && (
      <InfoMessage
        className={style['info-message']}
        label={t('contract:item.resiliationMessage', { 
          endDate: dayjs(cancelationDate).format('DD/MM/YYYY'),
          cancelDate: cancelationDate.format('DD/MM/YYYY')  // Ajout de la cancelationHorodate formatée
        })}
        prefix={<ExclamationTriangle styles={{ fill: '#E78D3D' }} />}
        type="warning"
      />
    )}

      {daysToCancel && (
        <InfoMessage
          label={t('contract:cancel.daysToCancelMessage', { daysToCancel })}
          prefix={<InfoCircle />}
        />
      )}

      <div className={style['contract-item']}>
        {contract.contractType.customizationCardVisibleStatus.contract && (
          <Card
            className={classNames(style.card, style['main-card'])}
            content={
              <>
                <h5 className={style['contract-name']}>{contract.rateType.dashboardPackageName || contract.contractType.dashboardName}</h5>
                <CardLine title={t('common:contract')} content={`N°${contract.contractType.idContractType}`} />
                <CardLine title={t('common:status.status')} content={t(`common:status.${contract.adhesionStatus}`)} />
                <CardLine
                  title={t('item.startDate', { ns: 'contract' })}
                  content={dayjs(contract.signatureHorodate).add(contract.contractType.timeComplianceChecksMax, 'day').format('DD/MM/YYYY')}
                />
              </>
            }
            title={t('common:contract')}
            titleIcon={<DocumentEdit />}
            cornerContent={
              !!documents.length && (
                <>
                  <div onClick={() => downloadDocument({ contract, type: 'ba' })}>
                    <Download />
                  </div>
                  <Link
                    className={style.download}
                    label={<Eye />}
                    to={documents.find(item => item.type === 'ba')?.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  />
                </>
              )
            }
          />
        )}

        <div className={style['secondary-cards']}>
          {contract.contractType.customizationCardVisibleStatus.payment && (
            <Card
              className={style.card}
              cornerContent={
                <Switch
                  checked={contract.paymentPlan.name === 'year'}
                  disabled={true}
                  name="contract-payment-type"
                  onChange={handleOpenModal}
                  option1={t('contract:payment.monthly')}
                  option2={t('contract:payment.annual')}
                />
              }
              title={t('common:payment')}
              titleIcon={<Bill styles={{ fill: '#1414FF' }} />}
            />
          )}
          {contract.contractType.customizationCardVisibleStatus.guaranteesOptions && (
            <Card
              className={style.card}
              content={[
                <p>{t('contract:guarantees.contractNumber')} {contract.rateType.name}</p>,
                <p>{t('contract:guarantees.package')} {contract.rateType.refundLimit} €</p>
              ]}
              title={t('contract:guarantee.guaranteesAndOptions')}
              titleIcon={<ShieldStar />}
            />
          )}
          {contract.contractType.customizationCardVisibleStatus.price && (
            <Card
              className={classNames(style.card)}
              cornerContent={
                <p className={style.price}>
                  <span>
                    {
                      contract.calculatedPrice && contract.calculatedPrice > 0 
                        ? parseFloat(contract.calculatedPrice).toFixed(2).replace(/\./g, ',') 
                        : parseFloat(contract.rateType?.rate).toFixed(2).replace(/\./g, ',')
                    }
                  </span>€/
                  {t(`contract:payment.${contract.paymentPlan.name || 'month'}`)}
                </p>
              }
              title={t('common:price')}
              titleIcon={<Euro />}
            />
          )}
          {contract.contractType.customizationCardVisibleStatus.documentsImportants && (
            contract.globalMissingCount > 0 ? (
              <CardLegalDocuments
                className={style.card}
                content={
                  <p style={{ color: '#B94402' }}>
                    {contract.globalMissingCount} {contract.globalMissingCount < 2 ? 'fichier manquant' : 'fichiers manquants'}
                  </p>
                }
                cornerContent={<Arrow />}
                onClick={() => navigate(`/contracts/${contract._id}/documents`, { state: { contract, documents } })}
                title={t('contract:item.importantDocuments')}
                titleIcon={<Archive />}
              />
            ) : (
              <Card
                className={style.card}
                content={
                  <p>
                    {documents.length + contract.missingDocumentsCount} {documents.length + contract.missingDocumentsCount === 1 ? t('common:file') : t('common:files')}
                  </p>
                }
                cornerContent={<Arrow />}
                onClick={() => navigate(`/contracts/${contract._id}/documents`, { state: { contract, documents } })}
                title={t('contract:item.importantDocuments')}
                titleIcon={<Archive />}
              />
            )
          )}
        </div>
      </div>
    </>
  )
}

export default ContractItem
