import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import dayjs from 'dayjs'

import PageTitle from 'components/common/PageTitle/PageTitle'
import PageSubtitle from 'components/common/PageSubtitle/PageSubtitle'
import style from './ClaimItem.module.scss'
import Arrow from 'components/assets/icons/Arrow'
import DocumentText from 'components/assets/icons/DocumentText'
import Card from 'components/common/Card/Card'
import Button from 'components/common/Button/Button'
import Clock from 'components/assets/icons/Clock'
import Status from 'components/common/Status/Status'
import CardLine from 'components/common/CardLine/CardLine'
import Thundercloud from 'components/assets/icons/Thundercloud'
import Collapsible from 'components/common/Collapsible/Collapsible'
import CircleOk from 'components/assets/icons/CircleOk'
import api from 'helpers/api'
import Download from 'components/assets/icons/Download'
import Eye from 'components/assets/icons/Eye'
import { downloadDocument, getFileUrl } from 'helpers/files'
import Link from 'components/common/Link/Link'
import ExclamationTriangle from 'components/assets/icons/ExclamationTriangle'
import ArchiveUp from 'components/assets/icons/ArchiveUp'
import Close from 'components/assets/icons/Close'
import { CLAIM_STATUSES } from 'constants/contract'

const ClaimItem = () => {
  const { t } = useTranslation()
  const params = useParams()
  const navigate = useNavigate()
  const { state } = useLocation()
  const inputRef = useRef(null)

  const [claim, setClaim] = useState(state?.claim ?? null)
  const [areCommentsOpened, setAreCommentsOpened] = useState(false)
  const [fileList, setFileList] = useState([])
  const [isVideoSelected, setIsVideoSelected] = useState(false)

  const { APPROVED, INCOMPLETE, CLOSED } = CLAIM_STATUSES

  useEffect(() => {
    const getClaim = async () => {
      try {
        const res = await api.get({ url: `/claims/${params.id}` })
        setClaim(res)
      } catch (error) {
        console.log('Fetch contract error')
      }
    }

    if (!state?.claim) getClaim()
  }, [params.id, state?.claim])

  const getDocumentUrl = useCallback(
    async document => {
      const url = await getFileUrl({ claimId: claim._id, contractId: claim.adhesionId, documentId: document._id })
      window.open(url, '_blank').focus()
    },
    [claim],
  )

  const handleFileChange = e => {
    if (!e.target.files) {
      return
    }

    const newFileList = [...fileList]
    let isVideoInSelection = false

    Array.from(e.target.files).forEach(item => {
      if (
        item.type.startsWith('image/') ||
        item.type === 'application/pdf' ||
        (item.type.startsWith('video/') && !isVideoInSelection && !isVideoSelected)
      ) {
        newFileList.push(item)
      }

      if (item.type.startsWith('video/')) {
        isVideoInSelection = true
        setIsVideoSelected(true)
      }
    })

    setFileList(newFileList)
  }

  const onFilesSubmit = async () => {
    try {
      if (!!fileList.length) {
        const formData = new FormData()
        Array.from(fileList).forEach(item => formData.append('files', item, item.name))

        const res = await api.post({
          url: `/claims/${claim._id}/documents`,
          contentType: 'multipart/form-data',
          data: formData,
        })

        setFileList([])
        setClaim({ ...claim, documents: res.response.claim.documents })
      }
    } catch (error) {
      console.log('Upload claim documents error')
    }
  }

  const handleUploadClick = e => {
    e.preventDefault()
    inputRef.current?.click()
  }

  const removeFile = index => {
    const newFileList = [...fileList]
    const [removedFile] = newFileList.splice(index, 1)
    if (removedFile.type.startsWith('video/')) {
      setIsVideoSelected(false)
    }

    setFileList(newFileList)
  }

  const sortedComments = useMemo(
    () => (claim?.commentsClaims || []).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)),
    [claim?.commentsClaims],
  )
  const [lastComment, ...comments] = sortedComments

  const ClaimCard = ({ content, icon }) => {
    const { status } = claim
    const type = status === APPROVED ? 'success' : status === INCOMPLETE || status === CLOSED ? 'error' : 'warning'
    const updatedAt = lastComment?.createdAt || claim.updatedAt
    function getBrowserLanguage() {
      const language = navigator.language || navigator.languages[0] || 'fr';
      return language.split('-')[0]; // Prendre seulement la partie de la langue sans la région (e.g., 'it' au lieu de 'it-IT')
    }
    
    const browserLanguage = getBrowserLanguage();
    const statusDate = dayjs(updatedAt).locale('it').format('DD.MM.YYYY');
    console.log(browserLanguage)
    return (
      <Card
        className={classNames(style.card, style.status)}
        content={content}
        title={<span className={classNames(style['status-date'], 'capitalized')}>{statusDate}</span>}
        titleIcon={icon}
        cornerContent={<Status label={t(`claim:item.status.${status}`)} type={type} />}
        type={type}
      />
    )
  }

  const Comment = ({ item }) => {
    return (
      <div className={style.comment}>
        <CardLine
          className="capitalized"
          title={dayjs(item.createdAt).locale('fr').format('DD.MM.YYYY')}
          styles={{ marginBottom: 0 }}
        />
        <CardLine content={item.commentsDashboard} styles={{ marginBottom: 0 }} />
      </div>
    )
  }

  const Comments = () => {
    return (
      !!comments.length && (
        <>
          <div className={style.comments}>
            {areCommentsOpened && comments.map((item, index) => <Comment item={item} key={`claim-comment-${index}`} />)}
          </div>
          <Link
            className={style['comments-link']}
            label={`${areCommentsOpened ? t('common:hide') : t('common:see')} ${t('common:history')}`}
            onClick={() => setAreCommentsOpened(!areCommentsOpened)}
          />
        </>
      )
    )
  }

  const StatusBlock = () => {
    if (claim.status === 'incomplete') {
      return (
        <ClaimCard
          icon={<ExclamationTriangle />}
          content={
            <>
              <div className={style.incomplete}>
                <p className={classNames(style['last-comment'], style.light)}>
                  {lastComment?.commentsDashboard || t('claim:item.defaultComment')}
                </p>
                <div className={style.upload}>
                  <input multiple onChange={handleFileChange} ref={inputRef} style={{ display: 'none' }} type="file" />
                  <Button
                    className={style['upload-button']}
                    label={
                      <>
                        <ArchiveUp className={style['upload-icon']} />
                        {t('common:uploadFile')}
                      </>
                    }
                    onClick={handleUploadClick}
                    type="dashed"
                  />
                  {!!fileList.length && (
                    <div className={style['uploaded-documents']}>
                      {Object.entries(fileList).map(([index, item]) => (
                        <span className={style['uploaded-document']} key={`uploaded-document-${index}`}>
                          <Close className={style.close} onClick={() => removeFile(index)} />
                          {item.name}
                        </span>
                      ))}
                    </div>
                  )}
                  <Button
                    className={style['submit-button']}
                    disabled={!fileList.length}
                    label={t('common:send')}
                    onClick={onFilesSubmit}
                    type="primary"
                  />
                </div>
              </div>
              <Comments />
            </>
          }
        />
      )
    } else {
      return (
        <ClaimCard
          icon={
            claim.status === APPROVED ? <CircleOk /> : claim.status === CLOSED ? <ExclamationTriangle /> : <Clock />
          }
          content={
            <>
              <p className={classNames(style['last-comment'], style.light)}>
                {lastComment?.commentsDashboard || t('claim:item.defaultComment')}
              </p>
              <Comments />
            </>
          }
        />
      )
    }
  }
  return (
    
    claim && (
      <>
        <PageTitle text={t('claim:item.title')} />
        <PageSubtitle text={t('claim:item.subtitle')} />

        <StatusBlock />
        <Card
          className={style.card}
          content={claim.description}
          onClick={() => navigate(`/contracts/${claim.adhesionId}`, { state: { fromClaim: true } })}
          title={claim.name}
          titleIcon={<DocumentText />}
          cornerContent={<Arrow />}
        />

        <Collapsible
          content={
            <>
              <div className={style['summary-first-line']}>
                <CardLine
                  className={style['first-line-element']}
                  title={t('claim:item.date')}
                  content={dayjs(claim.createdAt).format('DD/MM/YYYY')}
                />
                <CardLine
                  className={style['first-line-element']}
                  title={t('claim:item.location')}
                  content={claim.location}
                />
                <CardLine
                  className={style['first-line-element']}
                  title={t('claim:item.nature')}
                  content={claim.nature?.name || t('common:other')}
                />
              </div>
              <CardLine
                className={style.circumstances}
                title={t('claim:item.circumstances')}
                content={claim.circumstance}
              />
              {!!claim.documents?.length && (
                <div>
                  <CardLine className={style.document} title={t('common:documents.supportingDocuments')} />
                  {claim.documents.map((item, index) => (
                    <CardLine
                      className={style.document}
                      content={decodeURIComponent(item.name)}
                      key={`claim-document-${index}`}
                      suffix={
                        <>
                          <div
                            className={style.download}
                            onClick={() =>
                              downloadDocument({ claim, contract: { _id: claim.adhesionId }, documentItem: item })
                            }
                          >
                            <Download />
                          </div>
                          <Link
                            className={style['document-link']}
                            label={<Eye />}
                            onClick={() => getDocumentUrl(item)}
                            target="_blank"
                            rel="noopener noreferrer"
                          />
                        </>
                      }
                    />
                  ))}
                </div>
              )}
            </>
          }
          title={
            <>
              <Thundercloud styles={{ fill: '#1414FF' }} />
              <div className={style['collapsible-title']}>#{claim.shortId}</div>
            </>
          }
        />

        {state?.from === '/claims/new/circumstances' && (
          <Button
            className={style.button}
            label={t('claim:item.myClaimsButton')}
            onClick={() => navigate('/claims')}
            type="secondary"
          />
        )}
      </>
    )
  )
}

export default ClaimItem
