// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Logo_logo__F9E50 {\n  position: fixed;\n  z-index: 1;\n  top: 24px;\n  left: 32px;\n  height: 24px;\n  cursor: pointer;\n}\n\n@media screen and (max-width: 600px) {\n  .Logo_logo__F9E50 {\n    position: initial;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/common/Logo/Logo.module.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,UAAA;EACA,SAAA;EACA,UAAA;EACA,YAAA;EACA,eAAA;AADF;;AAIA;EACE;IACE,iBAAA;EADF;AACF","sourcesContent":["@use 'styles/sizes.scss';\n\n.logo {\n  position: fixed;\n  z-index: 1;\n  top: 24px;\n  left: 32px;\n  height: 24px;\n  cursor: pointer;\n}\n\n@media screen and (max-width: sizes.$breakpoint-sm) {\n  .logo {\n    position: initial;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": "Logo_logo__F9E50"
};
export default ___CSS_LOADER_EXPORT___;
