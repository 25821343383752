import axios from 'axios'
import router from 'router'

import config from 'config'
import { isAuthRoute } from './app'

const currentAccessToken = localStorage.getItem('accessToken')

export const apiClient = axios.create({
  baseURL: config.api.url,
  headers: {
    Authorization: `Bearer ${currentAccessToken}`,
    ...(config.api.xApiKey ? { 'x-api-key': config.api.xApiKey } : {}),
  },
})

apiClient.interceptors.request.use(
  async config => {
    const accessToken = localStorage.getItem('accessToken')

    if (accessToken !== currentAccessToken) {
      config.headers = {
        ...config.headers,
        authorization: `Bearer ${accessToken}`,
      }
    }

    return config
  },
  error => {
    console.log('Authorization error')
  },
)

apiClient.interceptors.response.use(
  async config => {
    return config
  },
  error => {
    const { pathname } = window.location
    if (!isAuthRoute(pathname) && error.response?.status === 401) {
      console.log('Authorization error')
      router.navigate('/sign-in')
    } else {
      throw new Error(error?.response?.data?.response || 'Something went wrong')
    }
  },
)

const api = {
  get: async ({ url, params, headers, responseType, isFile }) => {
    const res = await apiClient({ method: 'GET', url, params, headers, responseType })
    // TODO: handle other response props and errors
    if (isFile) {
      return {
        file: res?.data?.response || res?.data,
        contentType: res?.headers['content-type'],
      }
    }

    return res?.data?.response || res?.data
  },

  post: async ({ url, params, data, headers, contentType }) => {
    const res = await apiClient({
      method: 'POST',
      url,
      params,
      data,
      headers: {
        'Content-Type': contentType || 'application/json',
        ...headers,
      },
    })
    return res?.data || res
  },

  patch: async ({ url, params, data, headers }) => {
    const res = await apiClient({
      method: 'PATCH',
      url,
      params,
      data,
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
    })
    return res?.data?.response
  },

  put: async ({ url, params, data, headers }) => {
    const res = await apiClient({
      method: 'PUT',
      url,
      params,
      data,
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
    })
    return res?.data
  },

  delete: async ({ url, params, headers }) => {
    const res = await apiClient({
      method: 'DELETE',
      url,
      params,
      headers,
    })
    return res?.data?.response
  },
}

export default api
