import classNames from 'classnames'

import style from './Button.module.scss'

const Button = ({ className, disabled, label, onClick, styles, type }) => {
  return (
    <button
      className={classNames(style.button, style[type], className)}
      disabled={disabled}
      onClick={onClick}
      style={styles}
    >
      {label}
    </button>
  )
}

export default Button
