import classNames from 'classnames'
import style from './Textarea.module.scss'

const Textarea = ({ className, disabled, error, id, label, placeholder, value, register }) => {
  return (
    <div className={classNames(style['textarea'], className, error && style.error)}>
      <label className="input-label" htmlFor={id}>
        {label}
      </label>
      <textarea id={id} placeholder={placeholder} disabled={disabled} {...register}>
        {value}
      </textarea>
    </div>
  )
}

export default Textarea
