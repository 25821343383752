const LogoShort = ({ className, styles }) => {
  return (
    <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_378_16396)">
        <path
          d="M22.4605 9.15113C20.6972 4.30639 16.2447 0.747867 11.1172 0.0849314C10.8507 0.0447372 10.5896 0.259642 10.5709 0.528139L10.3296 3.24955C10.3066 3.51055 10.4924 3.74314 10.7536 3.77851C14.506 4.29138 17.6625 6.84237 18.9911 10.4357C19.085 10.6924 19.3729 10.8211 19.6196 10.7278L22.1705 9.77708C22.422 9.6833 22.5523 9.40247 22.4605 9.15113Z"
          fill="#FFA92C"
        />
        <path
          d="M9.19231 5.16211C4.11555 5.16211 0 8.853 0 14.5413C0 20.2296 3.95054 23.9199 9.19231 23.9199C14.4341 23.9199 18.3846 19.9091 18.3846 14.5413C18.3846 9.17348 14.7748 5.16211 9.19231 5.16211ZM9.19231 20.3432C6.20884 20.3432 3.96069 17.9932 3.96069 14.5413C3.96069 11.0894 6.30282 8.73939 9.19231 8.73939C12.3691 8.73939 14.4239 11.337 14.4239 14.5413C14.4239 17.7456 12.2489 20.3432 9.19231 20.3432Z"
          fill="#000093"
        />
      </g>
      <defs>
        <clipPath id="clip0_378_16396">
          <rect width="22.49" height="23.8394" fill="white" transform="translate(0 0.0800781)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default LogoShort
