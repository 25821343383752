// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InsuredPersonSelection_options__R8F4O {\n  display: flex;\n  gap: 12px;\n  width: 100%;\n  height: 190px;\n  margin-bottom: 28px;\n}\n\n.InsuredPersonSelection_option__Hz2DB {\n  width: 50%;\n  height: 100%;\n  cursor: pointer;\n}\n\n.InsuredPersonSelection_option-content__baWhs {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 16px;\n  width: 100%;\n  height: 100%;\n  text-align: center;\n  font-weight: 400;\n}\n\n@media screen and (max-width: 600px) {\n  .InsuredPersonSelection_options__R8F4O {\n    gap: 9px;\n    height: 100px;\n    margin-bottom: 20px;\n  }\n  .InsuredPersonSelection_option-content__baWhs {\n    gap: 10px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/Claims/ClaimApplication/InsuredPersonSelection/InsuredPersonSelection.module.scss"],"names":[],"mappings":"AAGA;EACE,aAAA;EACA,SAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;AAFF;;AAKA;EACE,UAAA;EACA,YAAA;EACA,eAAA;AAFF;;AAKA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,gBAAA;AAFF;;AAKA;EACE;IACE,QAAA;IACA,aAAA;IACA,mBAAA;EAFF;EAKA;IACE,SAAA;EAHF;AACF","sourcesContent":["@use 'styles/sizes.scss';\n@use 'styles/colors.scss';\n\n.options {\n  display: flex;\n  gap: 12px;\n  width: 100%;\n  height: 190px;\n  margin-bottom: 28px;\n}\n\n.option {\n  width: 50%;\n  height: 100%;\n  cursor: pointer;\n}\n\n.option-content {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 16px;\n  width: 100%;\n  height: 100%;\n  text-align: center;\n  font-weight: 400;\n}\n\n@media screen and (max-width: sizes.$breakpoint-sm) {\n  .options {\n    gap: 9px;\n    height: 100px;\n    margin-bottom: 20px;\n  }\n\n  .option-content {\n    gap: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"options": "InsuredPersonSelection_options__R8F4O",
	"option": "InsuredPersonSelection_option__Hz2DB",
	"option-content": "InsuredPersonSelection_option-content__baWhs"
};
export default ___CSS_LOADER_EXPORT___;
