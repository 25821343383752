// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Modal_modal-content__0c36D {
  position: relative;
}

.Modal_close-button__F\\+v69 {
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
  height: 20px;
  margin: -25px -25px;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .Modal_close-button__F\\+v69 {
    margin: -15px -25px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/common/Modal/Modal.module.scss"],"names":[],"mappings":"AAGA;EACE,kBAAA;AAFF;;AAKA;EACE,kBAAA;EACA,QAAA;EACA,MAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,eAAA;AAFF;;AAKA;EACE;IACE,mBAAA;EAFF;AACF","sourcesContent":["@use 'styles/colors.scss';\n@use 'styles/sizes.scss';\n\n.modal-content {\n  position: relative;\n}\n\n.close-button {\n  position: absolute;\n  right: 0;\n  top: 0;\n  width: 20px;\n  height: 20px;\n  margin: -25px -25px;\n  cursor: pointer;\n}\n\n@media screen and (max-width: sizes.$breakpoint-sm) {\n  .close-button {\n    margin: -15px -25px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal-content": `Modal_modal-content__0c36D`,
	"close-button": `Modal_close-button__F+v69`
};
export default ___CSS_LOADER_EXPORT___;
