import { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import Button from 'components/common/Button/Button'
import PageSubtitle from 'components/common/PageSubtitle/PageSubtitle'
import PageTitle from 'components/common/PageTitle/PageTitle'
import Card from 'components/common/Card/Card'
import DocumentText from 'components/assets/icons/DocumentText'
import Arrow from 'components/assets/icons/Arrow'
import style from './DocumentForm.module.scss'
import api from 'helpers/api'
import Close from 'components/assets/icons/Close'
import ArchiveUp from 'components/assets/icons/ArchiveUp'
import InfoCircle from 'components/assets/icons/InfoCircle'
import Collapsible from 'components/common/Collapsible/Collapsible'
import CardLine from 'components/common/CardLine/CardLine'
import dayjs from 'dayjs'
import Thundercloud from 'components/assets/icons/Thundercloud'
import Tooltip from 'components/common/Tooltip/Tooltip'

const DocumentForm = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { pathname, state } = useLocation()
  const inputRef = useRef(null)

  if (!state?.contract || !state?.claim) navigate('/claims/new')

  const { claim, contract } = state


  const [fileList, setFileList] = useState([])
  const [isVideoSelected, setIsVideoSelected] = useState(false)

  const {
    formState: { isValid },
    handleSubmit,
  } = useForm({
    criteriaMode: 'all',
    delayError: 500,
    mode: 'onTouched',
  })

  const onSubmit = handleSubmit(async data => {
    try {
      const formData = new FormData()
      Array.from(fileList).forEach(item => formData.append('files', item, item.name))

      const finalData = { ...claim, ...data, adhesionId: contract._id, nature: claim.nature.value }

      for (let key in finalData) {
        formData.append(key, finalData[key])
      }

      const res = await api.post({
        url: '/claims',
        contentType: 'multipart/form-data',
        data: formData,
      })

      navigate(`/claims/${res.response.id}`, { state: { from: pathname } })
    } catch (error) {
      console.log('Create claim error')
    }
  })

  const handleFileChange = e => {
    if (!e.target.files) {
      return
    }

    const newFileList = [...fileList]
    let isVideoInSelection = false

    Array.from(e.target.files).forEach(item => {
      if (
        item.type.startsWith('image/') ||
        item.type === 'application/pdf' ||
        (item.type.startsWith('video/') && !isVideoInSelection && !isVideoSelected)
      ) {
        newFileList.push(item)
      }

      if (item.type.startsWith('video/')) {
        isVideoInSelection = true
        setIsVideoSelected(true)
      }
    })

    setFileList(newFileList)
  }

  const handleUploadClick = e => {
    e.preventDefault()
    inputRef.current?.click()
  }

  const removeFile = index => {
    const newFileList = [...fileList]
    const [removedFile] = newFileList.splice(index, 1)
    if (removedFile.type.startsWith('video/')) {
      setIsVideoSelected(false)
    }

    setFileList(newFileList)
  }

  const requiredDocuments = contract.rateType.claimNatures?.find(
    item => item._id === claim.nature.value,
  )?.proofOfClaims
  return (
    <>
      <PageTitle text={t('claim:applySteps.documents.title')} />
      <PageSubtitle text={t('claim:applySteps.documents.subtitle')} />

      <Card
        className={style.contract}
        inactive={contract.cancelation}
        content={contract.rateType.dashboardPackageDescription || contract.contractType.dashboardDescription}
        onClick={() =>
          navigate(`/contracts/${contract._id}`, {
            state: { fromClaim: true, step: 4 },
          })
        }
        title={
          contract.rateType?.dashboardPackageName 
          ? `${contract.rateType.dashboardPackageName} ${contract.idTransaction}` 
          : `${contract.contractType.dashboardName} ${contract.idTransaction}`
        }
        titleIcon={<DocumentText />}
        cornerContent={<Arrow />}
      />

      <Collapsible
        content={
          <>
            <div className={style['summary-first-line']}>
              <CardLine
                className={style['first-line-element']}
                title={t('claim:item.date')}
                content={dayjs(claim.createdAt).format('DD/MM/YYYY')}
              />
              <CardLine
                className={style['first-line-element']}
                title={t('claim:item.location')}
                content={claim.location}
              />
              <CardLine
                className={style['first-line-element']}
                title={t('claim:item.nature')}
                content={claim.nature.label}
              />
            </div>
            <CardLine title={t('claim:item.circumstances')} content={claim.circumstance} />

            {!!requiredDocuments?.length && (
            <div>
              Afin de traiter votre demande, nous avons besoin des documents ci-dessous.
              <br />
              Vous pouvez transmettre les documents à l'adresse claim@getowen.eu
              <br /><br />
              <div></div>
              {requiredDocuments?.map(item => (
                  <p className={style['required-documents-item']}>• {item.name}</p>
              ))}
            </div>
          )}
          </>
        }
        title={
          <>
            <Thundercloud styles={{ fill: '#1414FF' }} />
            <div className={style['collapsible-title']}>{t('claim:claim')}</div>
          </>
        }
      />

      <form className={style.form} onSubmit={onSubmit}>
        <div className={style.documents}>
          

          <div className={style.upload}>
            <input multiple onChange={handleFileChange} ref={inputRef} style={{ display: 'none' }} type="file" />
            <Button
              className={style['upload-button']}
              label={
                <>
                  <ArchiveUp className={style['upload-icon']} />
                  {t('common:uploadFile')}
                </>
              }
              onClick={handleUploadClick}
              type="dashed"
            />
            <div className={style['uploaded-documents']}>
              {Object.entries(fileList).map(([index, item]) => (
                <span className={style.document} key={`uploaded-document-${index}`}>
                  <Close className={style.close} onClick={() => removeFile(index)} />
                  {item.name}
                </span>
              ))}
            </div>
          </div>
        </div>

        <Button
          className={style.button}
          label={t('claim:submit')}
          onClick={handleSubmit}
          type="primary"
          disabled={!isValid}
        />
      </form>
    </>
  )
}

export default DocumentForm
