import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import Button from 'components/common/Button/Button'
import Input from 'components/common/Input/Input'
import Link from 'components/common/Link/Link'
import Eye from 'components/assets/icons/Eye'
import ResponseMessage from 'components/common/ResponseMessage/ResponseMessage'
import api from 'helpers/api'
import Header from 'components/Header/Header'
import style from 'styles/auth.module.scss'
import EyeClosed from 'components/assets/icons/EyeClosed'

const SignIn = () => {
  const [isPasswordShown, setIsPasswordShown] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  const { t } = useTranslation()
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    criteriaMode: 'all',
    delayError: 500,
    defaultValues: {
      email: '',
      password: '',
    },
    mode: 'onTouched',
  })

  const togglePasswordVisibility = () => {
    setIsPasswordShown(!isPasswordShown)
  }

  const onSubmit = handleSubmit(async data => {
    try {
      const res = await api.post({ url: '/auth/clients/signin', data: { email: data.email, password: data.password } })

      if (res.success && res.response.accessToken) {
        localStorage.setItem('accessToken', res.response.accessToken)
        navigate('/contracts')
      } else {
        setErrorMessage(t('main:errorMessages.general'))
      }
    } catch (error) {
      if (error.response?.data?.status === 400 || error.response?.data?.status === 401) {
        setErrorMessage(t('main:auth.signIn.wrongInput'))
      } else {
        setErrorMessage(t('main:errorMessages.general'))
      }
    }
  })

  return (
    <div className={style['auth']}>
      <Header darkBg styles={{ position: 'absolute', top: 0 }} />
      <h2 className={style.title}>{t('main:auth.signIn.title')}</h2>
      {errorMessage && <ResponseMessage className={style['response-message']} label={errorMessage} />}

      <form onSubmit={onSubmit}>
        <div className={style.inputs}>
          <div className={style.input}>
            <Input
              register={register('email', { required: true })}
              darkBg
              label="Email"
              placeholder={`${t('common:your')} ${t('common:form.email.placeholder')}`}
              type="email"
            />
          </div>
          <div className={style.input}>
            <Input
              register={register('password', { required: true })}
              darkBg
              label={t('main:auth.password.password')}
              placeholder={t('main:auth.password.placeholder')}
              type={isPasswordShown ? 'text' : 'password'}
              suffix={
                isPasswordShown ? (
                  <EyeClosed className={classNames(style.eye, 'clickable')} />
                ) : (
                  <Eye className={classNames(style.eye, 'clickable')} />
                )
              }
              onSuffixClick={togglePasswordVisibility}
            />
          </div>
        </div>

        <Button label={t('main:auth.signIn.login')} onClick={handleSubmit} type="primary" disabled={!isValid} />
      </form>

      <Link className={style.link} label={t('main:auth.signIn.forgotPassword')} to={'/reset-password'} darkBg />
    </div>
  )
}

export default SignIn
