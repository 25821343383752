import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import IbanForm from './IbanForm'
import CardForm from './CardForm'

const CreditCardForm = () => {
  const navigate = useNavigate()
  const { state } = useLocation()

  useEffect(() => {
    if (!state?.client || !state?.contract || !state?.step) navigate('/sign-in')
  }, [navigate, state])

  if (state?.payByIban) {
    return <IbanForm state={state} navigate={navigate} />
  } else {
    return <CardForm state={state} navigate={navigate} />
  }
}

export default CreditCardForm
