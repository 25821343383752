import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PageTitle from 'components/common/PageTitle/PageTitle';
import PageSubtitle from 'components/common/PageSubtitle/PageSubtitle';
import InfoMessage from 'components/common/InfoMessage/InfoMessage';
import Button from 'components/common/Button/Button';
import Download from 'components/assets/icons/Download';
import Eye from 'components/assets/icons/Eye'; 
import ExclamationTriangleOrange from 'components/assets/icons/ExclamationTriangleOrange';
import Card from 'components/common/Card/Card';
import Link from 'components/common/Link/Link';
import style from './ContractDocuments.module.scss';
import { getContractDocuments } from 'helpers/contract';
import { downloadDocument } from 'helpers/files';
import { useLocation, useParams } from 'react-router-dom';
import api from 'helpers/api';
import CardLegalDocuments from 'components/common/CardLegalDocuments/CardLegalDocuments'; 
import CardLegalDocumentsRefused from 'components/common/CardLegalDocumentsRefused/CardLegalDocumentsRefused'; 
import ExclamationTriangleBordeaux from 'components/assets/icons/ExclamationTriangleBordeaux';

const ContractDocuments = () => {
  const { t } = useTranslation();
  const params = useParams();
  const { state } = useLocation();

  const [contract, setContract] = useState(state?.contract ?? null);
  const [documents, setDocuments] = useState(state?.documents ?? []);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [uploading, setUploading] = useState(false); 
  const [isStatusRefused, setIsStatusRefused] = useState(false); // Pour gérer le statut "refusé"

  const fileInputRefs = useRef([]);

  useEffect(() => {
    const getContract = async () => {
      try {
        const adhesionRes = await api.get({ url: `/adhesions/${params.id}/client` });
        setContract(adhesionRes.adhesion);
        setDocuments(await getContractDocuments(adhesionRes.adhesion));

        // Vérification si un document est refusé
        const refusedDocument = adhesionRes.adhesion.clientDocuments?.some(doc => doc.status === 'refusé');
        setIsStatusRefused(refusedDocument);

      } catch (error) {
        console.log('Erreur lors de la récupération du contrat', error);
      }
    };

    getContract();
  }, [params.id]);

  const handleUploadClick = (index) => {
    if (fileInputRefs.current[index]) {
      fileInputRefs.current[index].click(); // Cela ouvre l'input de fichier
    }
  };

  const handleFileChange = async (event, documentClient, index) => {
    const file = event.target.files[0];
    const allowedTypes = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'];

    if (file && !allowedTypes.includes(file.type)) {
      setErrorMessage('Format de fichier non valide. Veuillez télécharger un fichier .pdf, .jpeg, .jpg, ou .png.');
      return;
    }

    setErrorMessage('');
    setUploading(true);

    const formData = new FormData();
    formData.append('files', file);
    formData.append('legalDocumentClientId', documentClient._id);

    try {
      const response = await api.post({
        url: `/adhesions/${params.id}/legaldocuments`,
        contentType: 'multipart/form-data',
        data: formData,
      });

      setSuccessMessage('Document téléchargé avec succès.');

      // Recharger les données après l'upload pour synchroniser avec la base de données
      const adhesionRes = await api.get({ url: `/adhesions/${params.id}/client` });
      setContract(adhesionRes.adhesion);
      setDocuments(await getContractDocuments(adhesionRes.adhesion));

      setUploading(false);
    } catch (error) {
      setErrorMessage("Erreur lors de l'upload des documents.");
      setUploading(false);
      console.log('Erreur lors de l\'upload:', error);
    }
  };

  return (
    contract && (
      <>
        <PageTitle text={contract.contractType.dashboardName} />
        <PageSubtitle text={`${t('contract:item.partnerDescription')} ${contract.partner.commercialName}`} />

        {/* Afficher le message d'erreur en haut si le statut est "refusé" */}
        {isStatusRefused && (
          <InfoMessage
            className={`${style['info-message']} ${style['custom-document-refused']}`}
            label={"Des documents sont refusés. Veuillez réessayer."}
            prefix={<ExclamationTriangleBordeaux />}
            
            style={{
              backgroundColor: '#FDF1ED', // Couleur de fond personnalisée
              color: '#7B1028', // Couleur du texte personnalisée
            }}
          />
        )}

        {errorMessage && (
          <InfoMessage
            className={`${style['info-message']} ${style['custom-info-message']}`}
            label={errorMessage}
            prefix={<ExclamationTriangleOrange />}
            suffix={
              <Button
                className={`${style['claim-button']} ${style['custom-claim-button']}`}
                label={"Réessayer"}
                onClick={() => setErrorMessage('')}
                type="primary"
              />
            }
          />
        )}

        {successMessage && (
          <InfoMessage
            className={`${style['info-message']} ${style['custom-info-message']}`}
            label={successMessage}
            prefix={<ExclamationTriangleOrange />}
            suffix={
              <Button
                className={`${style['claim-button']} ${style['custom-claim-button']}`}
                label={"OK"}
                onClick={() => setSuccessMessage('')}
                type="primary"
              />
            }
          />
        )}

        <div className={style.list}>
          {contract.contractType?.legalDocuments?.legalDocumentClients?.map((documentClient, index) => {
            const existingDocument = contract.clientDocuments?.find(
              doc => doc.LegalDocumentClients && doc.LegalDocumentClients._id === documentClient._id
            );

            const isStatusAccepted = existingDocument && existingDocument.status === 'accepté';
            const isStatusRefused = existingDocument && existingDocument.status === 'refusé';

            let CardComponent;
            if (!existingDocument) {
              CardComponent = CardLegalDocuments;
            } else if (isStatusRefused) {
              CardComponent = CardLegalDocumentsRefused;
            } else {
              CardComponent = Card;
            }

            return (
              <CardComponent
                className={style['list-item']}
                key={`legal-document-client-${index}`}
                title={<span className={style.bold}>{documentClient.dashboardName || t('contract:document.legalDocumentClient')}</span>}
                content={
                  <div>
                    {!existingDocument && (
                      <input
                        ref={el => (fileInputRefs.current[index] = el)}
                        type="file"
                        style={{ display: 'none' }}
                        accept=".pdf,.jpeg,.jpg,.png"
                        onChange={e => handleFileChange(e, documentClient, index)}
                      />
                    )}
                  </div>
                }
                cornerContent={
                  <>
                    {existingDocument ? (
                      isStatusAccepted ? (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <div onClick={() => downloadDocument({ contract, type: ".pdf" })}>
                            <Download />
                          </div>
                          <Link
                            className={style.download}
                            label={<Eye />}
                            to={existingDocument.url || '#'}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ marginLeft: '8px' }}
                          />
                        </div>
                      ) : isStatusRefused ? (
                        <>
                          <div
                            onClick={() => handleUploadClick(index)}
                            style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                          >
                            <Download style={{ marginRight: '8px' }} />
                            <span style={{ color: '#2322FE' }}>{uploading ? t('common:uploading') : t('common:upload')}</span>
                          </div>
                          <input
                            ref={el => (fileInputRefs.current[index] = el)}
                            type="file"
                            style={{ display: 'none' }}
                            accept=".pdf,.jpeg,.jpg,.png"
                            onChange={(e) => handleFileChange(e, documentClient, index)}
                          />
                        </>
                      ) : (
                        <Button
                          className={`${style['claim-button']} ${style['custom-claim-button']}`}
                          label={existingDocument.status}
                          onClick={() => setErrorMessage('')}
                          style={{
                            backgroundColor: '#E68D3E',  // Couleur de fond
                            color: '#FFFFFF',             // Couleur du texte
                          }}
                        />
                      )
                    ) : (
                      <div
                        onClick={() => handleUploadClick(index)}
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <Download style={{ marginRight: '8px' }} />
                        <span style={{ color: '#2322FE' }}>{uploading ? t('common:uploading') : t('common:upload')}</span>
                      </div>
                    )}
                  </>
                }
              />
            );
          })}

          {documents.map((item, index) => (
            <Card
              className={style['list-item']}
              key={`contract-document-${index}`}
              title={<span className={style.bold}>{t(`contract:document.${item.type}`)}</span>}
              cornerContent={
                <>
                  <div onClick={() => downloadDocument({ contract, type: item.type })}>
                    <Download />
                  </div>
                  <Link
                    className={style.download}
                    label={<Eye />}
                    to={item.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  />
                </>
              }
            />
          ))}
        </div>
      </>
    )
  );
};

export default ContractDocuments;
