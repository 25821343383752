import classNames from 'classnames'
import DropArrow from 'components/assets/icons/DropArrow'
import { useState } from 'react'

import style from './Collapsible.module.scss'

const Collapsible = ({ className, content, isArrowReversed, isOpenedProp, onToggle, title }) => {
  const [isOpened, setIsOpened] = useState(isOpenedProp)

  const toggleContent = () => {
    onToggle ? onToggle() : setIsOpened(!isOpened)
  }

  return (
    <div className={classNames(style.collapsible, className)}>
      <div className={style.top} onClick={toggleContent}>
        <div
          className={classNames(
            style.arrow,
            ((isOpened && !isArrowReversed) || (!isOpened && isArrowReversed)) && style.up,
          )}
        >
          <DropArrow />
        </div>
        <h4 className={style.title}>{title}</h4>
      </div>
      <div className={classNames(style.content, isOpened && style.opened)}>{content}</div>
    </div>
  )
}

export default Collapsible
