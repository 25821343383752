const Euro = ({ styles }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0001 18.3337C14.6025 18.3337 18.3334 14.6027 18.3334 10.0003C18.3334 5.39795 14.6025 1.66699 10.0001 1.66699C5.39771 1.66699 1.66675 5.39795 1.66675 10.0003C1.66675 14.6027 5.39771 18.3337 10.0001 18.3337ZM5.62508 10.0003C5.62508 9.78813 5.64019 9.57945 5.66939 9.37533H8.33341C8.67859 9.37533 8.95841 9.0955 8.95841 8.75033C8.95841 8.40515 8.67859 8.12533 8.33341 8.12533H6.0461C6.74827 6.64723 8.25483 5.62533 10.0001 5.62533C10.798 5.62533 11.5443 5.83836 12.1871 6.21024C12.4859 6.38308 12.8682 6.28098 13.0411 5.98219C13.2139 5.6834 13.1118 5.30107 12.813 5.12823C11.985 4.64926 11.0236 4.37533 10.0001 4.37533C7.55092 4.37533 5.46735 5.94059 4.69515 8.12533H4.16675C3.82157 8.12533 3.54175 8.40515 3.54175 8.75033C3.54175 9.0955 3.82157 9.37533 4.16675 9.37533H4.40941C4.38673 9.58053 4.37508 9.78907 4.37508 10.0003C4.37508 10.2116 4.38673 10.4201 4.40941 10.6253H4.16675C3.82157 10.6253 3.54175 10.9051 3.54175 11.2503C3.54175 11.5955 3.82157 11.8753 4.16675 11.8753H4.69515C5.46735 14.0601 7.55092 15.6253 10.0001 15.6253C11.0236 15.6253 11.985 15.3514 12.813 14.8724C13.1118 14.6996 13.2139 14.3172 13.0411 14.0185C12.8682 13.7197 12.4859 13.6176 12.1871 13.7904C11.5443 14.1623 10.798 14.3753 10.0001 14.3753C8.25483 14.3753 6.74827 13.3534 6.0461 11.8753H8.33341C8.67859 11.8753 8.95841 11.5955 8.95841 11.2503C8.95841 10.9051 8.67859 10.6253 8.33341 10.6253H5.66939C5.64019 10.4212 5.62508 10.2125 5.62508 10.0003Z"
        fill="#1414FF"
      />
    </svg>
  )
}

export default Euro
