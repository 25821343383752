import { useContext, useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { ErrorBoundary, useRollbarPerson } from '@rollbar/react'

import NavMenu from 'components/NavMenu/NavMenu'
import Header from 'components/Header/Header'
import { isMobile } from 'helpers/styles'
import style from './PageWrapper.module.scss'
import Back from 'components/common/Back/Back'
import NavMenuFooter from 'components/NavMenuFooter/NavMenuFooter'
import NotFoundPage from 'components/NotFoundPage/NotFoundPage'
import ClientContext from 'contexts/ClientContext'

const PageWrapper = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const client = useContext(ClientContext)

  useRollbarPerson(client)

  const [isMenuRendered, setIsMenuRendered] = useState(!isMobile())

  useEffect(() => {
    if (pathname === '/') navigate('contracts')
  })

  window.onresize = () => {
    setIsMenuRendered(!isMobile())
  }

  const onNavItemClick = path => {
    if (isMenuRendered) setIsMenuRendered(false)
    navigate(path)
  }

  const onToggleMenu = () => {
    setIsMenuRendered(!isMenuRendered)
  }

  return (
    <ErrorBoundary fallbackUI={NotFoundPage}>
      <div className={style['page-wrapper']}>
        {isMenuRendered && <NavMenu onNavItemClick={onNavItemClick} />}
        <div className={style.content}>
          <Header />
          <Back className={style.back} />
          <div className={style.outlet}>
            <Outlet />
          </div>
          {isMobile() && <NavMenuFooter className onNavItemClick={onNavItemClick} onToggleMenu={onToggleMenu} />}
        </div>
      </div>
    </ErrorBoundary>
  )
}

export default PageWrapper
