const Notes = ({ styles }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.29556 12.2635L2.72693 13.8734C3.23047 15.7526 3.48224 16.6922 4.05301 17.3016C4.50368 17.7828 5.08692 18.1195 5.72897 18.2692C6.54211 18.4588 7.48173 18.2071 9.36096 17.7035L9.36096 17.7035C11.2402 17.2 12.1798 16.9482 12.7892 16.3775C12.8398 16.3301 12.8888 16.2812 12.9361 16.231C12.6576 16.2077 12.3767 16.1628 12.0913 16.1061C11.5112 15.9907 10.8219 15.806 10.0067 15.5876L9.91774 15.5638L9.89712 15.5582C9.01014 15.3206 8.26909 15.1216 7.67739 14.9079C7.05514 14.6831 6.48975 14.406 6.00965 13.9563C5.34803 13.3366 4.88502 12.5347 4.67918 11.6519C4.5298 11.0112 4.57251 10.383 4.68897 9.73176C4.80057 9.10766 5.00099 8.35972 5.24113 7.4636L5.24113 7.46359L5.68646 5.80161L5.70212 5.74316C4.10167 6.1743 3.25926 6.42937 2.69747 6.95554C2.21629 7.40622 1.87956 7.98945 1.72985 8.6315C1.54026 9.44465 1.79203 10.3843 2.29556 12.2635Z"
        fill="#FF9D0E"
        {...styles}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3576 8.92984L16.9263 10.5397C16.4227 12.419 16.171 13.3586 15.6002 13.968C15.1495 14.4492 14.5663 14.7859 13.9242 14.9356C13.844 14.9543 13.7625 14.9687 13.6791 14.979C12.9165 15.0731 11.9861 14.8237 10.2922 14.3699C8.413 13.8663 7.47338 13.6146 6.86397 13.0438C6.3828 12.5931 6.04606 12.0099 5.89636 11.3678C5.70676 10.5547 5.95853 9.61508 6.46207 7.73585L6.89343 6.12597C6.96583 5.85577 7.03307 5.60483 7.09676 5.37167C7.47599 3.9833 7.73083 3.21948 8.21951 2.69771C8.67019 2.21654 9.25342 1.8798 9.89547 1.7301C10.7086 1.5405 11.6482 1.79227 13.5275 2.29581C15.4067 2.79935 16.3463 3.05112 16.9557 3.62189C17.4369 4.07256 17.7736 4.6558 17.9233 5.29785C18.1129 6.11099 17.8612 7.05061 17.3576 8.92984ZM9.21026 8.1719C9.2996 7.83848 9.64231 7.64062 9.97573 7.72996L14.0004 8.80837C14.3338 8.89771 14.5317 9.24042 14.4424 9.57383C14.353 9.90725 14.0103 10.1051 13.6769 10.0158L9.6522 8.93736C9.31879 8.84802 9.12092 8.50531 9.21026 8.1719ZM8.56288 10.5864C8.65222 10.253 8.99493 10.0552 9.32835 10.1445L11.7432 10.7916C12.0766 10.8809 12.2744 11.2236 12.1851 11.557C12.0958 11.8904 11.7531 12.0883 11.4196 11.999L9.00482 11.3519C8.67141 11.2626 8.47354 10.9199 8.56288 10.5864Z"
        fill="#FF9D0E"
        {...styles}
      />
    </svg>
  )
}

export default Notes
