// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LanguageSelector_language-selector__dKqRH {\n  width: 44px;\n}\n\n@media screen and (max-width: 600px) {\n  .LanguageSelector_language-selector__dKqRH {\n    top: 12px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/LanguageSelector/LanguageSelector.module.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;AADF;;AAIA;EACE;IACE,SAAA;EADF;AACF","sourcesContent":["@use 'styles/sizes.scss';\n\n.language-selector {\n  width: 44px;\n}\n\n@media screen and (max-width: sizes.$breakpoint-sm) {\n  .language-selector {\n    top: 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"language-selector": "LanguageSelector_language-selector__dKqRH"
};
export default ___CSS_LOADER_EXPORT___;
