const Eye = ({ className }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.12508 9.99967C8.12508 8.96414 8.96455 8.12467 10.0001 8.12467C11.0356 8.12467 11.8751 8.96414 11.8751 9.99967C11.8751 11.0352 11.0356 11.8747 10.0001 11.8747C8.96455 11.8747 8.12508 11.0352 8.12508 9.99967Z"
        fill="#1414FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.66675 9.99967C1.66675 11.3658 2.02088 11.8259 2.72916 12.7461C4.14339 14.5834 6.51518 16.6663 10.0001 16.6663C13.485 16.6663 15.8568 14.5834 17.271 12.7461C17.9793 11.8259 18.3334 11.3658 18.3334 9.99967C18.3334 8.63353 17.9793 8.17345 17.271 7.25328C15.8568 5.41597 13.485 3.33301 10.0001 3.33301C6.51518 3.33301 4.14338 5.41597 2.72916 7.25328C2.02088 8.17345 1.66675 8.63353 1.66675 9.99967ZM10.0001 6.87467C8.27419 6.87467 6.87508 8.27378 6.87508 9.99967C6.87508 11.7256 8.27419 13.1247 10.0001 13.1247C11.726 13.1247 13.1251 11.7256 13.1251 9.99967C13.1251 8.27378 11.726 6.87467 10.0001 6.87467Z"
        fill="#1414FF"
      />
    </svg>
  )
}

export default Eye
